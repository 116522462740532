export let softs = [
 {
  "id": "1",
  "ini": "す",
  "name": "スーパーマリオランド",
  "maker": "任天堂",
  "releaseDate": "1989/4/21",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "あ",
  "name": "アレイウェイ",
  "maker": "任天堂",
  "releaseDate": "1989/4/21",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "へ",
  "name": "ベースボール",
  "maker": "任天堂",
  "releaseDate": "1989/4/21",
  "price": "2600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "や",
  "name": "役満",
  "maker": "任天堂",
  "releaseDate": "1989/4/21",
  "price": "2600",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "て",
  "name": "テニス",
  "maker": "任天堂",
  "releaseDate": "1989/5/29",
  "price": "2600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "て",
  "name": "テトリス",
  "maker": "任天堂",
  "releaseDate": "1989/6/14",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "し",
  "name": "上海",
  "maker": "HAL研究所",
  "releaseDate": "1989/7/28",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "そ",
  "name": "倉庫番",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/9/1",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "み",
  "name": "ミッキーマウス",
  "maker": "ケムコ",
  "releaseDate": "1989/9/5",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "も",
  "name": "モトクロスマニアックス",
  "maker": "コナミ",
  "releaseDate": "1989/9/20",
  "price": "2900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "は",
  "name": "ハイパーロードランナー",
  "maker": "バンダイ",
  "releaseDate": "1989/9/21",
  "price": "2800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "ひ",
  "name": "ピンボール 66匹のワニ大行進",
  "maker": "HAL研究所",
  "releaseDate": "1989/10/18",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "と",
  "name": "ドラキュラ伝説",
  "maker": "コナミ",
  "releaseDate": "1989/10/27",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "し",
  "name": "シーサイドバレー",
  "maker": "トンキンハウス",
  "releaseDate": "1989/10/31",
  "price": "2980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "は",
  "name": "パズルボーイ",
  "maker": "アトラス",
  "releaseDate": "1989/11/24",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "こ",
  "name": "ゴルフ",
  "maker": "任天堂",
  "releaseDate": "1989/11/28",
  "price": "2600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "は",
  "name": "パチンコタイム",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1989/12/8",
  "price": "3280",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "ま",
  "name": "魔界塔士Sa・Ga",
  "maker": "スクウェア",
  "releaseDate": "1989/12/15",
  "price": "3500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "し",
  "name": "将棋",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/12/19",
  "price": "3400",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "き",
  "name": "Qビリオン",
  "maker": "セタ",
  "releaseDate": "1989/12/22",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "か",
  "name": "海戦ゲーム NAVY BLUE",
  "maker": "ユース",
  "releaseDate": "1989/12/22",
  "price": "3400",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "ほ",
  "name": "北斗の拳 凄絶十番勝負",
  "maker": "東映動画",
  "releaseDate": "1989/12/22",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "23",
  "ini": "て",
  "name": "てけてけ！アスミッくんワールド",
  "maker": "アスミック",
  "releaseDate": "1989/12/27",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "せ",
  "name": "セレクション 選ばれし者",
  "maker": "ケムコ",
  "releaseDate": "1989/12/28",
  "price": "3090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "ま",
  "name": "マスターカラテカ",
  "maker": "新生工業",
  "releaseDate": "1989/12/28",
  "price": "2900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "ひ",
  "name": "ヒーロー大集合!! ピンボールパーティ",
  "maker": "ジャレコ",
  "releaseDate": "1990/1/12",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "わ",
  "name": "ワールドボウリング",
  "maker": "アテナ",
  "releaseDate": "1990/1/13",
  "price": "3090",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "へ",
  "name": "平安京エイリアン",
  "maker": "メルダック",
  "releaseDate": "1990/1/14",
  "price": "3000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "そ",
  "name": "ソーラーストライカー",
  "maker": "任天堂",
  "releaseDate": "1990/1/26",
  "price": "2600",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "お",
  "name": "オセロ",
  "maker": "河田",
  "releaseDate": "1990/2/9",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "ね",
  "name": "ネメシス",
  "maker": "コナミ",
  "releaseDate": "1990/2/23",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "た",
  "name": "対局連珠",
  "maker": "トーワチキ",
  "releaseDate": "1990/2/23",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "へ",
  "name": "ベースボールキッズ",
  "maker": "ジャレコ",
  "releaseDate": "1990/3/15",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "く",
  "name": "クォース",
  "maker": "コナミ",
  "releaseDate": "1990/3/16",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "ふ",
  "name": "フリップル",
  "maker": "タイトー",
  "releaseDate": "1990/3/16",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "へ",
  "name": "ペンギンランド",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/3/21",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "ふ",
  "name": "フラッピースペシャル",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/3/23",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "え",
  "name": "SD戦国伝 国盗り物語",
  "maker": "バンダイ",
  "releaseDate": "1990/3/24",
  "price": "3500",
  "genre": "シミュレーション・アクション",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "と",
  "name": "トランプボーイ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/3/29",
  "price": "3090",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "す",
  "name": "スペースインベーダーズ",
  "maker": "タイトー",
  "releaseDate": "1990/3/30",
  "price": "3090",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "へ",
  "name": "ペンギンくんwars vs.",
  "maker": "アスキー",
  "releaseDate": "1990/3/30",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "か",
  "name": "かこむん蛇",
  "maker": "ナグザット",
  "releaseDate": "1990/4/6",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "え",
  "name": "SDルパン3世 金庫破り大作戦",
  "maker": "バンプレスト",
  "releaseDate": "1990/4/13",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "く",
  "name": "クイックス",
  "maker": "任天堂",
  "releaseDate": "1990/4/13",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "は",
  "name": "バットマン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/4/13",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "は",
  "name": "爆裂戦士ウォーリア",
  "maker": "エポック社",
  "releaseDate": "1990/4/13",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "す",
  "name": "スーパーチャイニーズランド",
  "maker": "カルチャーブレーン",
  "releaseDate": "1990/4/20",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "て",
  "name": "デットヒートスクランブル",
  "maker": "コピアシステム",
  "releaseDate": "1990/4/20",
  "price": "3300",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "ふ",
  "name": "ブロディア",
  "maker": "トンキンハウス",
  "releaseDate": "1990/4/20",
  "price": "3000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "ほ",
  "name": "ポパイ",
  "maker": "シグマ商事",
  "releaseDate": "1990/4/20",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "ほ",
  "name": "冒険！パズルロード",
  "maker": "ビック東海",
  "releaseDate": "1990/4/20",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "さ",
  "name": "サッカーボーイ",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1990/4/27",
  "price": "3000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "て",
  "name": "天神怪戦",
  "maker": "メルダック",
  "releaseDate": "1990/4/27",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "す",
  "name": "スヌーピー マジックショー",
  "maker": "ケムコ",
  "releaseDate": "1990/4/28",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "55",
  "ini": "れ",
  "name": "レッドアリーマー 魔界村外伝",
  "maker": "カプコン",
  "releaseDate": "1990/5/2",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "ろ",
  "name": "ロックンチェイス",
  "maker": "データイースト",
  "releaseDate": "1990/5/11",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "ほ",
  "name": "ボクシング",
  "maker": "トンキンハウス",
  "releaseDate": "1990/5/18",
  "price": "3000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "58",
  "ini": "あ",
  "name": "あやかしの城",
  "maker": "セタ",
  "releaseDate": "1990/5/25",
  "price": "3500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "う",
  "name": "ウルトラマン倶楽部 敵怪獣ヲ発見セヨ",
  "maker": "バンダイ",
  "releaseDate": "1990/5/26",
  "price": "3300",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "ひ",
  "name": "ピットマン",
  "maker": "アスク講談社",
  "releaseDate": "1990/6/1",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "こ",
  "name": "コスモタンク",
  "maker": "アトラス",
  "releaseDate": "1990/6/8",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "62",
  "ini": "か",
  "name": "カードゲーム",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1990/6/15",
  "price": "3400",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "め",
  "name": "メカ生体ゾイド ゾイド伝説",
  "maker": "トミー",
  "releaseDate": "1990/6/15",
  "price": "3090",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "64",
  "ini": "ふ",
  "name": "ファニーフィールド",
  "maker": "SNK",
  "releaseDate": "1990/6/15",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "そ",
  "name": "倉庫番2",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/6/22",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "ふ",
  "name": "ブライファイター デラックス",
  "maker": "タイトー",
  "releaseDate": "1990/6/27",
  "price": "3400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "67",
  "ini": "は",
  "name": "バリーファイア",
  "maker": "東映動画",
  "releaseDate": "1990/6/29",
  "price": "3200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "68",
  "ini": "は",
  "name": "パイプドリーム",
  "maker": "BPS",
  "releaseDate": "1990/7/3",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "し",
  "name": "四川省",
  "maker": "アイレム",
  "releaseDate": "1990/7/13",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "た",
  "name": "ダブルドラゴン",
  "maker": "テクノスジャパン",
  "releaseDate": "1990/7/20",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "71",
  "ini": "た",
  "name": "タスマニア物語",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/7/27",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "と",
  "name": "ドクターマリオ",
  "maker": "任天堂",
  "releaseDate": "1990/7/27",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "73",
  "ini": "ら",
  "name": "らんま1/2",
  "maker": "バンプレスト",
  "releaseDate": "1990/7/28",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "は",
  "name": "パズニック",
  "maker": "タイトー",
  "releaseDate": "1990/7/31",
  "price": "3400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "い",
  "name": "石道",
  "maker": "アスキー",
  "releaseDate": "1990/8/2",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "て",
  "name": "ティーンエイジ ミュータント ニンジャ タートルズ",
  "maker": "コナミ",
  "releaseDate": "1990/8/3",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "さ",
  "name": "魁!! 男塾 冥凰島決戦",
  "maker": "ユタカ",
  "releaseDate": "1990/8/4",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "お",
  "name": "おさわがせ！ペンギンBOY",
  "maker": "ナツメ",
  "releaseDate": "1990/8/8",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "79",
  "ini": "ふ",
  "name": "VSバトラー",
  "maker": "ユース",
  "releaseDate": "1990/8/10",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "ほ",
  "name": "香港",
  "maker": "徳間書店",
  "releaseDate": "1990/8/11",
  "price": "2900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "81",
  "ini": "と",
  "name": "ドラゴンスレイヤーⅠ",
  "maker": "エポック社",
  "releaseDate": "1990/8/12",
  "price": "3090",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "は",
  "name": "パワーミッション",
  "maker": "バップ",
  "releaseDate": "1990/8/24",
  "price": "3500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "き",
  "name": "機動警察パトレイバー 狙われた街1990",
  "maker": "ユタカ",
  "releaseDate": "1990/8/25",
  "price": "3500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "は",
  "name": "バトルピンポン",
  "maker": "クエスト",
  "releaseDate": "1990/8/31",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "ほ",
  "name": "ボンバーボーイ",
  "maker": "ハドソン",
  "releaseDate": "1990/8/31",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "ふ",
  "name": "ファミスタ",
  "maker": "ナムコ",
  "releaseDate": "1990/9/14",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "ふ",
  "name": "プロレス",
  "maker": "ヒューマン",
  "releaseDate": "1990/9/14",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "さ",
  "name": "サイドポケット",
  "maker": "データイースト",
  "releaseDate": "1990/9/21",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "た",
  "name": "ダックテールス",
  "maker": "カプコン",
  "releaseDate": "1990/9/21",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "は",
  "name": "バルダーダッシュ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/9/21",
  "price": "3400",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "91",
  "ini": "る",
  "name": "ルナランダー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/9/21",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "え",
  "name": "F1ボーイ",
  "maker": "アスク講談社",
  "releaseDate": "1990/9/28",
  "price": "3300",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "93",
  "ini": "お",
  "name": "おいらじゃじゃ丸！世界大冒険",
  "maker": "ジャレコ",
  "releaseDate": "1990/9/28",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "94",
  "ini": "う",
  "name": "うおーズ",
  "maker": "トーワチキ",
  "releaseDate": "1990/10/5",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "え",
  "name": "SDガンダム外伝 ラクロアンヒーローズ",
  "maker": "バンダイ",
  "releaseDate": "1990/10/6",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "96",
  "ini": "の",
  "name": "信長の野望 ゲームボーイ版",
  "maker": "光栄",
  "releaseDate": "1990/10/10",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "あ",
  "name": "アストロラビー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1990/10/12",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "98",
  "ini": "つ",
  "name": "ツインビーだ!!",
  "maker": "コナミ",
  "releaseDate": "1990/10/12",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "99",
  "ini": "は",
  "name": "パラメデス",
  "maker": "ホット・ビィ",
  "releaseDate": "1990/10/12",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "こ",
  "name": "ゴーストバスターズ2",
  "maker": "HAL研究所",
  "releaseDate": "1990/10/16",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "も",
  "name": "モンスタートラック",
  "maker": "バリエ",
  "releaseDate": "1990/10/19",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "ろ",
  "name": "ロードスター",
  "maker": "トンキンハウス",
  "releaseDate": "1990/10/19",
  "price": "3300",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "あ",
  "name": "アミーダくん 阿弥陀",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1990/10/23",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "か",
  "name": "海戦ゲーム　レーダーミッション",
  "maker": "任天堂",
  "releaseDate": "1990/10/23",
  "price": "2600",
  "genre": "シミュレーション・3Dシューティング",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "あ",
  "name": "アフターバースト",
  "maker": "NCS",
  "releaseDate": "1990/10/26",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "106",
  "ini": "へ",
  "name": "ベリウス ローランの魔獣",
  "maker": "サミー工業",
  "releaseDate": "1990/10/26",
  "price": "3300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "え",
  "name": "F1レース",
  "maker": "任天堂",
  "releaseDate": "1990/11/9",
  "price": "2600",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "と",
  "name": "トランプボーイⅡ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/11/9",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "ふ",
  "name": "ふしぎなブロビー プリンセス・ブロブを救え！",
  "maker": "ジャレコ",
  "releaseDate": "1990/11/9",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "あ",
  "name": "アレサ",
  "maker": "やのまん",
  "releaseDate": "1990/11/16",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "は",
  "name": "パックマン",
  "maker": "ナムコ",
  "releaseDate": "1990/11/16",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "は",
  "name": "パラソルヘンべえ 虹の大冒険",
  "maker": "エポック社",
  "releaseDate": "1990/11/16",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "こ",
  "name": "GO！GO！TANK",
  "maker": "コピアシステム",
  "releaseDate": "1990/11/30",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "き",
  "name": "キャデラックⅡ",
  "maker": "ヘクト",
  "releaseDate": "1990/11/30",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "は",
  "name": "バトルブル",
  "maker": "セタ",
  "releaseDate": "1990/11/30",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "か",
  "name": "海戦ゲーム NAVY BLUE 90",
  "maker": "ユース",
  "releaseDate": "1990/12/7",
  "price": "3500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "こ",
  "name": "ころダイス",
  "maker": "キングレコード",
  "releaseDate": "1990/12/7",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "118",
  "ini": "す",
  "name": "スコットランドヤード",
  "maker": "東映動画",
  "releaseDate": "1990/12/7",
  "price": "3300",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "ち",
  "name": "ちびまる子ちゃん おこづかい大作戦！",
  "maker": "タカラ",
  "releaseDate": "1990/12/7",
  "price": "3400",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "120",
  "ini": "は",
  "name": "バブルボブル",
  "maker": "タイトー",
  "releaseDate": "1990/12/7",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "へ",
  "name": "ペインター モモピー",
  "maker": "シグマ商事",
  "releaseDate": "1990/12/7",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "へ",
  "name": "ヘッド-オン",
  "maker": "テクモ",
  "releaseDate": "1990/12/7",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "ね",
  "name": "熱血硬派くにおくん 番外乱闘編",
  "maker": "テクノスジャパン",
  "releaseDate": "1990/12/7",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "124",
  "ini": "き",
  "name": "鬼忍降魔禄ONI",
  "maker": "バンプレスト",
  "releaseDate": "1990/12/8",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "す",
  "name": "スパルタンX",
  "maker": "アイレム",
  "releaseDate": "1990/12/11",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "ふ",
  "name": "プリプリ PRIMITIVE PRINCESS！",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/12/12",
  "price": "3200",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "と",
  "name": "ドラゴンテール",
  "maker": "アイマックス",
  "releaseDate": "1990/12/13",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "128",
  "ini": "さ",
  "name": "Sa・Ga2 秘宝伝説",
  "maker": "スクウェア",
  "releaseDate": "1990/12/14",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "129",
  "ini": "く",
  "name": "クラックス",
  "maker": "ハドソン",
  "releaseDate": "1990/12/14",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "ね",
  "name": "ネコジャラ物語",
  "maker": "ケムコ",
  "releaseDate": "1990/12/14",
  "price": "3300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "ほ",
  "name": "ポケットスタジアム",
  "maker": "アトラス",
  "releaseDate": "1990/12/14",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "か",
  "name": "カード&パズル コレクション 銀河",
  "maker": "ホット・ビィ",
  "releaseDate": "1990/12/14",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "133",
  "ini": "こ",
  "name": "ゴジラくん 怪獣大行進",
  "maker": "東宝",
  "releaseDate": "1990/12/18",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "ほ",
  "name": "ポン太とヒナ子の珍道中 友情編",
  "maker": "ナグザット",
  "releaseDate": "1990/12/20",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "135",
  "ini": "か",
  "name": "カプコンクイズ ハテナ？の大冒険",
  "maker": "カプコン",
  "releaseDate": "1990/12/21",
  "price": "3500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "136",
  "ini": "く",
  "name": "グレムリン2 新・種・誕・生",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/12/21",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "137",
  "ini": "は",
  "name": "バトルユニット ZEOTH",
  "maker": "ジャレコ",
  "releaseDate": "1990/12/21",
  "price": "3400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "138",
  "ini": "は",
  "name": "バブルゴースト",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/12/21",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "139",
  "ini": "い",
  "name": "石田芳夫の詰碁パラダイス",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/12/21",
  "price": "3400",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "140",
  "ini": "も",
  "name": "モンスターメーカー",
  "maker": "ソフエル",
  "releaseDate": "1990/12/22",
  "price": "3500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "141",
  "ini": "ひ",
  "name": "飛龍の拳外伝",
  "maker": "カルチャーブレーン",
  "releaseDate": "1990/12/22",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "142",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズ",
  "maker": "トミー",
  "releaseDate": "1990/12/23",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "143",
  "ini": "と",
  "name": "ドルアーガの塔",
  "maker": "エンジェル",
  "releaseDate": "1990/12/31",
  "price": "3500",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "144",
  "ini": "こ",
  "name": "コントラ",
  "maker": "コナミ",
  "releaseDate": "1991/1/8",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "と",
  "name": "突撃！ポンコツタンク",
  "maker": "HAL研究所",
  "releaseDate": "1991/1/8",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "146",
  "ini": "た",
  "name": "タイトーチェイスHQ",
  "maker": "タイトー",
  "releaseDate": "1991/1/11",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "147",
  "ini": "と",
  "name": "突撃ばれいしょんず",
  "maker": "アトラス",
  "releaseDate": "1991/1/25",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "148",
  "ini": "ら",
  "name": "ライサンダー",
  "maker": "日本物産",
  "releaseDate": "1991/2/8",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "149",
  "ini": "お",
  "name": "鬼ヶ島パチンコ店",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1991/2/8",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "み",
  "name": "三毛猫ホームズの騎士道",
  "maker": "アスク講談社",
  "releaseDate": "1991/2/15",
  "price": "3500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "151",
  "ini": "は",
  "name": "バーガータイムデラックス",
  "maker": "データイースト",
  "releaseDate": "1991/2/15",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "152",
  "ini": "い",
  "name": "怒りの要塞",
  "maker": "ジャレコ",
  "releaseDate": "1991/2/26",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "153",
  "ini": "え",
  "name": "F-1スピリット",
  "maker": "コナミ",
  "releaseDate": "1991/2/28",
  "price": "3500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "154",
  "ini": "れ",
  "name": "レーシング魂",
  "maker": "アイレム",
  "releaseDate": "1991/2/28",
  "price": "3500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "と",
  "name": "ドラえもん 対決ひみつ道具!!",
  "maker": "エポック社",
  "releaseDate": "1991/3/1",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "156",
  "ini": "ろ",
  "name": "ロボコップ",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1991/3/1",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "157",
  "ini": "す",
  "name": "スーパー桃太郎電鉄",
  "maker": "ハドソン",
  "releaseDate": "1991/3/8",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "158",
  "ini": "せ",
  "name": "戦国忍者くん",
  "maker": "UPL",
  "releaseDate": "1991/3/8",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "159",
  "ini": "る",
  "name": "ループス",
  "maker": "マインドスケープ",
  "releaseDate": "1991/3/15",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "160",
  "ini": "あ",
  "name": "R-TYPE",
  "maker": "アイレム",
  "releaseDate": "1991/3/19",
  "price": "3600",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "161",
  "ini": "ふ",
  "name": "ファーステスト・ラップ",
  "maker": "バップ",
  "releaseDate": "1991/3/20",
  "price": "3800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "162",
  "ini": "し",
  "name": "ジェムジェム",
  "maker": "ビック東海",
  "releaseDate": "1991/3/29",
  "price": "3800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "163",
  "ini": "ふ",
  "name": "ファミリージョッキー",
  "maker": "ナムコ",
  "releaseDate": "1991/3/29",
  "price": "3500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "164",
  "ini": "そ",
  "name": "ソロモンズ倶楽部",
  "maker": "テクモ",
  "releaseDate": "1991/4/5",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "165",
  "ini": "は",
  "name": "パロディウスだ！",
  "maker": "コナミ",
  "releaseDate": "1991/4/5",
  "price": "3800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "166",
  "ini": "ふ",
  "name": "ファイナルリバース",
  "maker": "東映動画",
  "releaseDate": "1991/4/12",
  "price": "3400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "167",
  "ini": "わ",
  "name": "ワールドアイスホッケー",
  "maker": "アテナ",
  "releaseDate": "1991/4/12",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "168",
  "ini": "か",
  "name": "カーブノア",
  "maker": "コナミ",
  "releaseDate": "1991/4/19",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "169",
  "ini": "り",
  "name": "リトルマスター ライクバーンの伝説",
  "maker": "徳間書店",
  "releaseDate": "1991/4/19",
  "price": "4200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "ち",
  "name": "茶々丸パニック",
  "maker": "ヒューマン",
  "releaseDate": "1991/4/19",
  "price": "2900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "す",
  "name": "スーパーロボット大戦",
  "maker": "バンプレスト",
  "releaseDate": "1991/4/20",
  "price": "3980",
  "genre": "シミュレーションRPG",
  "icNo": "1"
 },
 {
  "id": "172",
  "ini": "み",
  "name": "ミッキーマウスⅡ",
  "maker": "ケムコ",
  "releaseDate": "1991/4/26",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "173",
  "ini": "み",
  "name": "ミニバット",
  "maker": "A-WAVE",
  "releaseDate": "1991/4/26",
  "price": "3400",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "174",
  "ini": "ら",
  "name": "ラッキーモンキー",
  "maker": "ナツメ",
  "releaseDate": "1991/4/26",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "す",
  "name": "相撲ファイター 東海道場所",
  "maker": "アイマックス",
  "releaseDate": "1991/4/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "176",
  "ini": "ね",
  "name": "熱血高校サッカー部 ワールドカップ編",
  "maker": "テクノスジャパン",
  "releaseDate": "1991/4/26",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "か",
  "name": "からくり剣豪伝ムサシロード",
  "maker": "ユタカ",
  "releaseDate": "1991/4/27",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "178",
  "ini": "と",
  "name": "トーピドー・レンジ",
  "maker": "セタ",
  "releaseDate": "1991/4/27",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "179",
  "ini": "き",
  "name": "キッチンパニック",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1991/5/10",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "180",
  "ini": "さ",
  "name": "サンリオカーニバル",
  "maker": "キャラクターソフト",
  "releaseDate": "1991/5/11",
  "price": "3296",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "181",
  "ini": "ら",
  "name": "ラブルセイバー",
  "maker": "キングレコード",
  "releaseDate": "1991/5/17",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "182",
  "ini": "て",
  "name": "DX馬券王",
  "maker": "アスミック",
  "releaseDate": "1991/5/17",
  "price": "5900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "183",
  "ini": "え",
  "name": "SDコマンドガンダム G-ARMSオペレーションガンダム",
  "maker": "バンダイ",
  "releaseDate": "1991/5/18",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "184",
  "ini": "け",
  "name": "ゲームボーイウォーズ",
  "maker": "任天堂",
  "releaseDate": "1991/5/21",
  "price": "3500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "185",
  "ini": "あ",
  "name": "アスミッくんワールド2",
  "maker": "アスミック",
  "releaseDate": "1991/5/24",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "す",
  "name": "スノーブラザーズジュニア",
  "maker": "ナグザット",
  "releaseDate": "1991/5/24",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "187",
  "ini": "れ",
  "name": "レジェンド 明日への翼",
  "maker": "クエスト",
  "releaseDate": "1991/5/31",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "188",
  "ini": "さ",
  "name": "サッカー",
  "maker": "トンキンハウス",
  "releaseDate": "1991/6/7",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "た",
  "name": "大戦略",
  "maker": "ヒロ",
  "releaseDate": "1991/6/12",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "190",
  "ini": "ま",
  "name": "マジカル☆タルるートくん",
  "maker": "バンダイ",
  "releaseDate": "1991/6/15",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "191",
  "ini": "し",
  "name": "ジャングルウォーズ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/6/21",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "192",
  "ini": "ち",
  "name": "チョップリフターⅡ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/6/21",
  "price": "3400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "193",
  "ini": "え",
  "name": "エアロスター",
  "maker": "ビック東海",
  "releaseDate": "1991/6/28",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "194",
  "ini": "し",
  "name": "人生ゲーム伝説",
  "maker": "タカラ",
  "releaseDate": "1991/6/28",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "195",
  "ini": "せ",
  "name": "聖剣伝説 ファイナルファンタジー外伝",
  "maker": "スクウェア",
  "releaseDate": "1991/6/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "か",
  "name": "元祖!! ヤンチャ丸",
  "maker": "アイレム",
  "releaseDate": "1991/7/11",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "197",
  "ini": "う",
  "name": "ヴァトルギウス",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/7/12",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "198",
  "ini": "と",
  "name": "ドラキュラ伝説Ⅱ",
  "maker": "コナミ",
  "releaseDate": "1991/7/12",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "199",
  "ini": "し",
  "name": "紫禁城",
  "maker": "東映動画",
  "releaseDate": "1991/7/16",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "200",
  "ini": "は",
  "name": "ハットリス",
  "maker": "BPS",
  "releaseDate": "1991/7/19",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "201",
  "ini": "こ",
  "name": "恋は駆け引き",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/7/21",
  "price": "3090",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "202",
  "ini": "は",
  "name": "バニシングレーサー",
  "maker": "ジャレコ",
  "releaseDate": "1991/7/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "203",
  "ini": "ろ",
  "name": "ロックマンワールド",
  "maker": "カプコン",
  "releaseDate": "1991/7/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "204",
  "ini": "は",
  "name": "パズルボーイⅡ",
  "maker": "アトラス",
  "releaseDate": "1991/8/2",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "205",
  "ini": "ふ",
  "name": "フリートコマンダーVS.",
  "maker": "アスキー",
  "releaseDate": "1991/8/2",
  "price": "4635",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "206",
  "ini": "ち",
  "name": "茶々丸冒険記3 アビスの塔",
  "maker": "ヒューマン",
  "releaseDate": "1991/8/2",
  "price": "3900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "207",
  "ini": "へ",
  "name": "ペケとポコのダルマンバスターズ",
  "maker": "バンプレスト",
  "releaseDate": "1991/8/3",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "208",
  "ini": "え",
  "name": "エレベーターアクション",
  "maker": "タイトー",
  "releaseDate": "1991/8/9",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "ね",
  "name": "ネメシスⅡ",
  "maker": "コナミ",
  "releaseDate": "1991/8/9",
  "price": "3800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "210",
  "ini": "は",
  "name": "バトルシティ",
  "maker": "ノバ",
  "releaseDate": "1991/8/9",
  "price": "3090",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "211",
  "ini": "め",
  "name": "メガリット",
  "maker": "アスミック",
  "releaseDate": "1991/8/9",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "212",
  "ini": "ひ",
  "name": "東尾修監修 プロ野球スタジアム'91",
  "maker": "徳間書店",
  "releaseDate": "1991/8/9",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "213",
  "ini": "ほ",
  "name": "ボンバーキング scenario2",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/8/23",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "214",
  "ini": "ち",
  "name": "ちびまる子ちゃん2 デラックスまる子ワールド",
  "maker": "タカラ",
  "releaseDate": "1991/9/13",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "215",
  "ini": "な",
  "name": "ナイトクエスト",
  "maker": "タイトー",
  "releaseDate": "1991/9/13",
  "price": "3600",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "216",
  "ini": "し",
  "name": "時空戦記ムー",
  "maker": "ハドソン",
  "releaseDate": "1991/9/13",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "217",
  "ini": "う",
  "name": "ウイナーズホース",
  "maker": "NCS",
  "releaseDate": "1991/9/20",
  "price": "4000",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "218",
  "ini": "あ",
  "name": "アレサⅡ",
  "maker": "やのまん",
  "releaseDate": "1991/9/27",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "219",
  "ini": "て",
  "name": "テクモボウルGB",
  "maker": "テクモ",
  "releaseDate": "1991/9/27",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "220",
  "ini": "し",
  "name": "少年アシベ ゆうえんちパニック",
  "maker": "タカラ",
  "releaseDate": "1991/9/27",
  "price": "3800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "221",
  "ini": "う",
  "name": "ウィザードリィ外伝Ⅰ 女王の受難",
  "maker": "アスキー",
  "releaseDate": "1991/10/1",
  "price": "4635",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "222",
  "ini": "さ",
  "name": "ザードの伝説",
  "maker": "ビック東海",
  "releaseDate": "1991/10/18",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "223",
  "ini": "と",
  "name": "ドラゴンズ・レア",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1991/10/25",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "224",
  "ini": "こ",
  "name": "コナミックゴルフ",
  "maker": "コナミ",
  "releaseDate": "1991/11/1",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "225",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 強敵！闘球戦士の巻",
  "maker": "テクノスジャパン",
  "releaseDate": "1991/11/8",
  "price": "3600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "226",
  "ini": "て",
  "name": "ティーンエイジ ミュータント ニンジャ タートルズ2",
  "maker": "コナミ",
  "releaseDate": "1991/11/15",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "227",
  "ini": "ほ",
  "name": "ポパイ2",
  "maker": "シグマ商事",
  "releaseDate": "1991/11/22",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "228",
  "ini": "ひ",
  "name": "秘伝陰陽気功法図式謎遊戯 華佗",
  "maker": "ヨネザワ",
  "releaseDate": "1991/11/22",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "229",
  "ini": "あ",
  "name": "アルタードスペース",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1991/11/29",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "230",
  "ini": "す",
  "name": "スーパーチャイニーズランド2 宇宙大冒険",
  "maker": "カルチャーブレーン",
  "releaseDate": "1991/11/29",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "な",
  "name": "ナムコクラシック",
  "maker": "ナムコ",
  "releaseDate": "1991/12/3",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "232",
  "ini": "と",
  "name": "ドッジボーイ",
  "maker": "トンキンハウス",
  "releaseDate": "1991/12/6",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "233",
  "ini": "ま",
  "name": "マインスウィーパー 掃海艇",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/12/13",
  "price": "3500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "234",
  "ini": "さ",
  "name": "SaGa3 時空の覇者 [完結編]",
  "maker": "スクウェア",
  "releaseDate": "1991/12/13",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "235",
  "ini": "さ",
  "name": "サーガイア",
  "maker": "タイトー",
  "releaseDate": "1991/12/13",
  "price": "3600",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "236",
  "ini": "は",
  "name": "パチンコ西遊記",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1991/12/13",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "237",
  "ini": "は",
  "name": "バトルオブキングダム",
  "maker": "メルダック",
  "releaseDate": "1991/12/13",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "238",
  "ini": "わ",
  "name": "ワールドビーチバレー 1991GBカップ",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/12/13",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "239",
  "ini": "に",
  "name": "忍者龍剣伝GB 摩天楼決戦",
  "maker": "テクモ",
  "releaseDate": "1991/12/13",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "240",
  "ini": "う",
  "name": "ウルティマ 失われたルーン",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/12/14",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "241",
  "ini": "き",
  "name": "きんぎょ注意報！わぴ子のわくわくスタンプラリー！",
  "maker": "ユタカ",
  "releaseDate": "1991/12/14",
  "price": "3500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "242",
  "ini": "よ",
  "name": "ヨッシーのたまご",
  "maker": "任天堂",
  "releaseDate": "1991/12/14",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "243",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズ PART2",
  "maker": "トミー",
  "releaseDate": "1991/12/20",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "244",
  "ini": "も",
  "name": "モノポリー",
  "maker": "トミー",
  "releaseDate": "1991/12/20",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "245",
  "ini": "ろ",
  "name": "ロックマンワールド2",
  "maker": "カプコン",
  "releaseDate": "1991/12/20",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "246",
  "ini": "た",
  "name": "太陽の勇者ファイバード GB",
  "maker": "アイレム",
  "releaseDate": "1991/12/20",
  "price": "3800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "247",
  "ini": "か",
  "name": "がんばれゴエモン さらわれたエビス丸",
  "maker": "コナミ",
  "releaseDate": "1991/12/25",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "248",
  "ini": "も",
  "name": "桃太郎伝説外伝",
  "maker": "ハドソン",
  "releaseDate": "1991/12/26",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "249",
  "ini": "し",
  "name": "ジャンケンマン",
  "maker": "NCS",
  "releaseDate": "1991/12/27",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "250",
  "ini": "ひ",
  "name": "ピータン",
  "maker": "KANEKO",
  "releaseDate": "1991/12/27",
  "price": "3600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "251",
  "ini": "な",
  "name": "中嶋 悟 監修 F-1 HERO GB WORLD CHAMPIONSHIP '91",
  "maker": "バリエ",
  "releaseDate": "1991/12/27",
  "price": "3500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "252",
  "ini": "せ",
  "name": "絶対無敵ライジンオー",
  "maker": "トミー",
  "releaseDate": "1991/12/28",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "253",
  "ini": "う",
  "name": "ウルトラマン",
  "maker": "ベック",
  "releaseDate": "1991/12/29",
  "price": "3500",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "254",
  "ini": "き",
  "name": "機甲警察メタルジャック",
  "maker": "タカラ",
  "releaseDate": "1992/1/8",
  "price": "3800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "255",
  "ini": "と",
  "name": "トキオ戦記 英雄列伝",
  "maker": "ヒューマン",
  "releaseDate": "1992/1/10",
  "price": "4500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "256",
  "ini": "き",
  "name": "Q-bert",
  "maker": "ジャレコ",
  "releaseDate": "1992/1/14",
  "price": "4200",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "257",
  "ini": "え",
  "name": "SD戦国伝2 天下統一編",
  "maker": "バンダイ",
  "releaseDate": "1992/1/18",
  "price": "4800",
  "genre": "シミュレーション・アクション",
  "icNo": "1"
 },
 {
  "id": "258",
  "ini": "め",
  "name": "メトロイドⅡ RETURN OF SAMUS",
  "maker": "任天堂",
  "releaseDate": "1992/1/21",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "259",
  "ini": "し",
  "name": "しっぽでブン！",
  "maker": "バップ",
  "releaseDate": "1992/1/24",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "260",
  "ini": "し",
  "name": "雀卓ボーイ",
  "maker": "ナムコ",
  "releaseDate": "1992/1/24",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "261",
  "ini": "つ",
  "name": "TWIN",
  "maker": "アテナ",
  "releaseDate": "1992/1/31",
  "price": "3900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "262",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ",
  "maker": "コナミ",
  "releaseDate": "1992/2/1",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "263",
  "ini": "た",
  "name": "WWFスーパースターズ",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/2/14",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "264",
  "ini": "と",
  "name": "トラックミート 目指せ！バルセロナ",
  "maker": "ヒロ",
  "releaseDate": "1992/2/14",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "265",
  "ini": "へ",
  "name": "ベリウスⅡ 復讐の邪神",
  "maker": "サミー工業",
  "releaseDate": "1992/2/21",
  "price": "4200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "266",
  "ini": "い",
  "name": "怒りの要塞2",
  "maker": "ジャレコ",
  "releaseDate": "1992/2/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "267",
  "ini": "お",
  "name": "ONIⅡ 隠忍伝説",
  "maker": "バンプレスト",
  "releaseDate": "1992/2/28",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "268",
  "ini": "し",
  "name": "新世紀GPXサイバーフォーミュラ",
  "maker": "バリエ",
  "releaseDate": "1992/2/28",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "269",
  "ini": "へ",
  "name": "平成天才バカボン",
  "maker": "ナムコ",
  "releaseDate": "1992/2/28",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "270",
  "ini": "と",
  "name": "ドラゴンスレイヤー外伝 眠りの王冠",
  "maker": "エポック社",
  "releaseDate": "1992/3/6",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "271",
  "ini": "ら",
  "name": "ラブルセイバーⅡ",
  "maker": "キングレコード",
  "releaseDate": "1992/3/6",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "272",
  "ini": "た",
  "name": "高橋名人の冒険島Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1992/3/6",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "273",
  "ini": "は",
  "name": "ハドソン・ホーク",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1992/3/13",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "274",
  "ini": "よ",
  "name": "読本夢五誉身 天神怪戦2",
  "maker": "メルダック",
  "releaseDate": "1992/3/13",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "275",
  "ini": "す",
  "name": "スーパーストリート バスケットボール",
  "maker": "バップ",
  "releaseDate": "1992/3/19",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "276",
  "ini": "ろ",
  "name": "ロボコップ2",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1992/3/19",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "277",
  "ini": "き",
  "name": "キャプテン翼VS",
  "maker": "テクモ",
  "releaseDate": "1992/3/27",
  "price": "4200",
  "genre": "サッカー・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "278",
  "ini": "ち",
  "name": "チキチキマシーン猛レース",
  "maker": "アトラス",
  "releaseDate": "1992/3/27",
  "price": "3900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "279",
  "ini": "ち",
  "name": "ちびまる子ちゃん3 めざせ！ゲーム大賞の巻",
  "maker": "タカラ",
  "releaseDate": "1992/3/27",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "280",
  "ini": "ふ",
  "name": "プロサッカー",
  "maker": "イマジニア",
  "releaseDate": "1992/3/27",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "281",
  "ini": "り",
  "name": "リトルマスター2 雷光の騎士",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1992/3/27",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "282",
  "ini": "し",
  "name": "雀四郎",
  "maker": "サミー工業",
  "releaseDate": "1992/3/27",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "283",
  "ini": "は",
  "name": "バットマン リターンオブザジョーカー",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/3/28",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "284",
  "ini": "ふ",
  "name": "HOOK",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1992/4/3",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "285",
  "ini": "て",
  "name": "DX馬券王Z",
  "maker": "アスミック",
  "releaseDate": "1992/4/17",
  "price": "5900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "286",
  "ini": "ほ",
  "name": "炎の闘球児 ドッジ弾平",
  "maker": "ハドソン",
  "releaseDate": "1992/4/24",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "287",
  "ini": "み",
  "name": "ミグレイン",
  "maker": "アクレイムジャパン",
  "releaseDate": "1992/4/24",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "288",
  "ini": "ほ",
  "name": "星のカービィ",
  "maker": "任天堂",
  "releaseDate": "1992/4/27",
  "price": "2900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "289",
  "ini": "ほ",
  "name": "ポケットバトル",
  "maker": "シグマ商事",
  "releaseDate": "1992/4/28",
  "price": "3500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "290",
  "ini": "れ",
  "name": "レッドオクトーバーを追え！",
  "maker": "アルトロン",
  "releaseDate": "1992/4/28",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "291",
  "ini": "す",
  "name": "スパイVSスパイ とらっぱーず天国",
  "maker": "ケムコ",
  "releaseDate": "1992/5/2",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "292",
  "ini": "は",
  "name": "パネルの忍者ケサマル サラ姫お助け申す！の巻",
  "maker": "エポック社",
  "releaseDate": "1992/5/2",
  "price": "3500",
  "genre": "パズル・アクション",
  "icNo": "1"
 },
 {
  "id": "293",
  "ini": "え",
  "name": "X エックス",
  "maker": "任天堂",
  "releaseDate": "1992/5/29",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "294",
  "ini": "す",
  "name": "スーパーハンチバック",
  "maker": "イマジニア",
  "releaseDate": "1992/6/26",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "295",
  "ini": "そ",
  "name": "ソリテア",
  "maker": "ヘクト",
  "releaseDate": "1992/6/26",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "296",
  "ini": "ほ",
  "name": "ホーム・アローン",
  "maker": "アルトロン",
  "releaseDate": "1992/6/26",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "297",
  "ini": "う",
  "name": "うる星やつら ミス友引を捜せ！",
  "maker": "やのまん",
  "releaseDate": "1992/7/3",
  "price": "5200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "298",
  "ini": "ま",
  "name": "マジカル☆タルるートくん2 ライバーゾーンパニック!!",
  "maker": "バンダイ",
  "releaseDate": "1992/7/10",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "299",
  "ini": "こ",
  "name": "コナミックスポーツ イン バルセロナ",
  "maker": "コナミ",
  "releaseDate": "1992/7/17",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "300",
  "ini": "ら",
  "name": "らんま1/2 熱烈格闘編",
  "maker": "バンプレスト",
  "releaseDate": "1992/7/17",
  "price": "3980",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "301",
  "ini": "う",
  "name": "宇宙戦艦ヤマト",
  "maker": "ベック",
  "releaseDate": "1992/7/17",
  "price": "3900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "302",
  "ini": "ひ",
  "name": "東尾修監修 プロ野球スタジアム'92",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1992/7/17",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "303",
  "ini": "た",
  "name": "ダウンタウン熱血行進曲 どこでも運動会",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/7/24",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "304",
  "ini": "は",
  "name": "バイオニックコマンドー",
  "maker": "カプコン",
  "releaseDate": "1992/7/24",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "305",
  "ini": "ふ",
  "name": "プリンス・オブ・ペルシャ",
  "maker": "NCS",
  "releaseDate": "1992/7/24",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "306",
  "ini": "ふ",
  "name": "ファミスタ2",
  "maker": "ナムコ",
  "releaseDate": "1992/7/30",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "307",
  "ini": "へ",
  "name": "ペンタドラゴン",
  "maker": "やのまん",
  "releaseDate": "1992/7/31",
  "price": "4800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "308",
  "ini": "た",
  "name": "大工の源さん ゴーストビルディングカンパニー",
  "maker": "アイレム",
  "releaseDate": "1992/7/31",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "309",
  "ini": "く",
  "name": "クイズ世界はSHOW BY ショーバイ!!",
  "maker": "タカラ",
  "releaseDate": "1992/8/7",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "310",
  "ini": "し",
  "name": "シーザースパレス",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/8/7",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "311",
  "ini": "ち",
  "name": "ちびまる子ちゃん4 これが日本だよ！王子さま",
  "maker": "タカラ",
  "releaseDate": "1992/8/7",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "312",
  "ini": "け",
  "name": "ゲームボーイ電子手帳 ナノノート",
  "maker": "コナミ",
  "releaseDate": "1992/8/7",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "313",
  "ini": "は",
  "name": "バーサスヒーロー 格闘王への道",
  "maker": "バンプレスト",
  "releaseDate": "1992/8/7",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "314",
  "ini": "ま",
  "name": "マサカリ伝説 金太郎アクション編",
  "maker": "トンキンハウス",
  "releaseDate": "1992/8/7",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "315",
  "ini": "な",
  "name": "中嶋 悟 監修 F-1 HERO GB '92 THE GRADED DRIVER",
  "maker": "バリエ",
  "releaseDate": "1992/8/11",
  "price": "3980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "316",
  "ini": "は",
  "name": "ハイパーブラックバス",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/8/28",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "317",
  "ini": "え",
  "name": "SDガンダム SD戦国伝3 新SD戦国伝 地上最強編",
  "maker": "バンダイ",
  "releaseDate": "1992/9/4",
  "price": "4800",
  "genre": "シミュレーション・アクション",
  "icNo": "1"
 },
 {
  "id": "318",
  "ini": "せ",
  "name": "セレクションⅡ 暗黒の封印",
  "maker": "ケムコ",
  "releaseDate": "1992/9/4",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "319",
  "ini": "き",
  "name": "キン肉マン ザ☆ドリームマッチ",
  "maker": "ユタカ",
  "releaseDate": "1992/9/12",
  "price": "3500",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "320",
  "ini": "か",
  "name": "カエルの為に鐘は鳴る",
  "maker": "任天堂",
  "releaseDate": "1992/9/14",
  "price": "3900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "ひ",
  "name": "ビタミーナ王国物語",
  "maker": "ナムコ",
  "releaseDate": "1992/9/17",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "322",
  "ini": "こ",
  "name": "コナミックバスケット",
  "maker": "コナミ",
  "releaseDate": "1992/9/25",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "323",
  "ini": "め",
  "name": "メルヘン倶楽部",
  "maker": "ナグザット",
  "releaseDate": "1992/9/25",
  "price": "3800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "324",
  "ini": "さ",
  "name": "三国志 ゲームボーイ版",
  "maker": "光栄",
  "releaseDate": "1992/9/30",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "325",
  "ini": "は",
  "name": "パチンコかぐや姫",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/10/9",
  "price": "3864",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "326",
  "ini": "さ",
  "name": "3分予想 馬番倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1992/10/16",
  "price": "5800",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "327",
  "ini": "す",
  "name": "SPOT",
  "maker": "BPS",
  "releaseDate": "1992/10/16",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "328",
  "ini": "あ",
  "name": "アレサⅢ",
  "maker": "やのまん",
  "releaseDate": "1992/10/16",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "329",
  "ini": "は",
  "name": "バトルドッジボール",
  "maker": "バンプレスト",
  "releaseDate": "1992/10/16",
  "price": "4980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "330",
  "ini": "す",
  "name": "スーパーマリオランド2 6つの金貨",
  "maker": "任天堂",
  "releaseDate": "1992/10/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "331",
  "ini": "あ",
  "name": "アダムスファミリー",
  "maker": "ミサワエンターテイメント",
  "releaseDate": "1992/10/23",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "332",
  "ini": "き",
  "name": "キョロちゃんランド",
  "maker": "ヒロ",
  "releaseDate": "1992/10/30",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "333",
  "ini": "ら",
  "name": "ランバート",
  "maker": "ジャレコ",
  "releaseDate": "1992/10/30",
  "price": "4000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "334",
  "ini": "ふ",
  "name": "ファンタズム",
  "maker": "ジャレコ",
  "releaseDate": "1992/11/6",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "335",
  "ini": "せ",
  "name": "セイントパラダイス 最強の戦士たち",
  "maker": "バンダイ",
  "releaseDate": "1992/11/13",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "ふ",
  "name": "フェラーリ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/11/13",
  "price": "4300",
  "genre": "3D・レース",
  "icNo": "1"
 },
 {
  "id": "337",
  "ini": "た",
  "name": "タンブルポップ",
  "maker": "データイースト",
  "releaseDate": "1992/11/20",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "338",
  "ini": "よ",
  "name": "ヨッシーのクッキー",
  "maker": "任天堂",
  "releaseDate": "1992/11/21",
  "price": "2900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "339",
  "ini": "し",
  "name": "GB原人",
  "maker": "ハドソン",
  "releaseDate": "1992/11/27",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "340",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズPART3 チャンピオン大会",
  "maker": "トミー",
  "releaseDate": "1992/11/27",
  "price": "3500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "341",
  "ini": "き",
  "name": "きんぎょ注意報！2 ぎょぴちゃんを探せ！",
  "maker": "ビーアイ",
  "releaseDate": "1992/11/27",
  "price": "4980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "342",
  "ini": "こ",
  "name": "コナミックアイスホッケー",
  "maker": "コナミ",
  "releaseDate": "1992/11/27",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "343",
  "ini": "と",
  "name": "トリップワールド",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/11/27",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "344",
  "ini": "け",
  "name": "元気爆発 ガンバルンガー",
  "maker": "トミー",
  "releaseDate": "1992/11/27",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "345",
  "ini": "あ",
  "name": "R-TYPEⅡ",
  "maker": "アイレム",
  "releaseDate": "1992/12/11",
  "price": "3800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "346",
  "ini": "す",
  "name": "スーパービックリマン 伝説の石版",
  "maker": "ユタカ",
  "releaseDate": "1992/12/11",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "347",
  "ini": "ぬ",
  "name": "ぬ～ぼ～",
  "maker": "アイレム",
  "releaseDate": "1992/12/11",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "348",
  "ini": "み",
  "name": "ミラクルアドベンチャー エスパークス 失われた聖石ペリヴァロン",
  "maker": "トミー",
  "releaseDate": "1992/12/11",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "349",
  "ini": "ろ",
  "name": "ロックマンワールド3",
  "maker": "カプコン",
  "releaseDate": "1992/12/11",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "350",
  "ini": "た",
  "name": "ダンジョンランド",
  "maker": "エニックス",
  "releaseDate": "1992/12/15",
  "price": "4600",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "351",
  "ini": "く",
  "name": "クイズ日本昔話 アテナのハテナ？",
  "maker": "アテナ",
  "releaseDate": "1992/12/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "352",
  "ini": "と",
  "name": "ドクターフランケン",
  "maker": "ケムコ",
  "releaseDate": "1992/12/18",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "353",
  "ini": "と",
  "name": "トムとジェリー",
  "maker": "アルトロン",
  "releaseDate": "1992/12/18",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "354",
  "ini": "ひ",
  "name": "ひとりでできるもん！クッキング伝説",
  "maker": "バップ",
  "releaseDate": "1992/12/18",
  "price": "3900",
  "genre": "クイズ・RPG",
  "icNo": "1"
 },
 {
  "id": "355",
  "ini": "み",
  "name": "ミッキーズチェイス",
  "maker": "ケムコ",
  "releaseDate": "1992/12/18",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "356",
  "ini": "う",
  "name": "宇宙の騎士テッカマンブレード",
  "maker": "ユタカ",
  "releaseDate": "1992/12/18",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "357",
  "ini": "ち",
  "name": "地球解放軍ZAS",
  "maker": "T&Eソフト",
  "releaseDate": "1992/12/18",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "358",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン",
  "maker": "エンジェル",
  "releaseDate": "1992/12/18",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "359",
  "ini": "き",
  "name": "木づちだ クイズだ 源さんだ！",
  "maker": "アイレム",
  "releaseDate": "1992/12/18",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "360",
  "ini": "と",
  "name": "ドラえもん2 アニマル惑星伝説",
  "maker": "エポック社",
  "releaseDate": "1992/12/19",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "361",
  "ini": "ふ",
  "name": "4IN1 FUN PACK",
  "maker": "イマジニア",
  "releaseDate": "1992/12/22",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "362",
  "ini": "る",
  "name": "LOONEY TUNES バックスバニーとゆかいな仲間たち",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/12/22",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "363",
  "ini": "せ",
  "name": "ゼノン2 メガブラスト",
  "maker": "PCMコンプリート",
  "releaseDate": "1992/12/25",
  "price": "3864",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "364",
  "ini": "は",
  "name": "バトルスペース（バーコードボーイ専用）",
  "maker": "ナムコ",
  "releaseDate": "1992/12/25",
  "price": "8300",
  "genre": "バーコードボーイ",
  "icNo": "1"
 },
 {
  "id": "365",
  "ini": "め",
  "name": "女神転生外伝 ラストバイブル",
  "maker": "アトラス",
  "releaseDate": "1992/12/25",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "366",
  "ini": "う",
  "name": "ウィザードリィ外伝Ⅱ 古代皇帝の呪い",
  "maker": "アスキー",
  "releaseDate": "1992/12/26",
  "price": "4635",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "367",
  "ini": "し",
  "name": "Jリーグ ファイティングサッカー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1992/12/27",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "368",
  "ini": "へ",
  "name": "ヘラクレスの栄光 動き出した神々",
  "maker": "データイースト",
  "releaseDate": "1992/12/27",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "369",
  "ini": "あ",
  "name": "悪魔城スペシャル ぼくドラキュラくん",
  "maker": "コナミ",
  "releaseDate": "1993/1/3",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "370",
  "ini": "た",
  "name": "ダーティ・レーシング",
  "maker": "ジャレコ",
  "releaseDate": "1993/1/8",
  "price": "3900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "371",
  "ini": "し",
  "name": "新日本プロレスリング 闘魂三銃士",
  "maker": "バリエ",
  "releaseDate": "1993/1/29",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "372",
  "ini": "り",
  "name": "リングレイジ",
  "maker": "タイトー",
  "releaseDate": "1993/1/29",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "373",
  "ini": "さ",
  "name": "サンダーバード",
  "maker": "ビーアイ",
  "releaseDate": "1993/2/12",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "374",
  "ini": "さ",
  "name": "ザードの伝説2 偽神の領域",
  "maker": "ビック東海",
  "releaseDate": "1993/2/19",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "375",
  "ini": "お",
  "name": "ONIⅢ 黒の破壊神",
  "maker": "バンプレスト",
  "releaseDate": "1993/2/26",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "376",
  "ini": "は",
  "name": "バートのサバイバルキャンプ",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/2/26",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "377",
  "ini": "は",
  "name": "バーニングペーパー",
  "maker": "ロジーク",
  "releaseDate": "1993/2/26",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "378",
  "ini": "た",
  "name": "高橋名人の冒険島Ⅲ",
  "maker": "ハドソン",
  "releaseDate": "1993/2/26",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "379",
  "ini": "か",
  "name": "CLUT MASTER ウルトラマンに魅せられて",
  "maker": "バンダイ",
  "releaseDate": "1993/3/12",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "380",
  "ini": "し",
  "name": "GB バスケットボール",
  "maker": "イマジニア",
  "releaseDate": "1993/3/19",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "381",
  "ini": "き",
  "name": "キラートマト",
  "maker": "アルトロン",
  "releaseDate": "1993/3/19",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "382",
  "ini": "さ",
  "name": "ザ・キックボクシング",
  "maker": "マイクロワールド",
  "releaseDate": "1993/3/19",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "383",
  "ini": "さ",
  "name": "サンリオカーニバル2",
  "maker": "キャラクターソフト",
  "releaseDate": "1993/3/19",
  "price": "3296",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "384",
  "ini": "た",
  "name": "ダブル役満",
  "maker": "バップ",
  "releaseDate": "1993/3/19",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "385",
  "ini": "も",
  "name": "モンスターメーカー2 ウルの秘剣",
  "maker": "ソフエル",
  "releaseDate": "1993/3/19",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "386",
  "ini": "し",
  "name": "G1キング！三匹の予想屋",
  "maker": "ビック東海",
  "releaseDate": "1993/3/26",
  "price": "5900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "387",
  "ini": "あ",
  "name": "アウトバースト",
  "maker": "コナミ",
  "releaseDate": "1993/3/26",
  "price": "4000",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "388",
  "ini": "み",
  "name": "ミロンの迷宮組曲",
  "maker": "ハドソン",
  "releaseDate": "1993/3/26",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "389",
  "ini": "く",
  "name": "クレヨンしんちゃん オラとシロはお友達だよ",
  "maker": "バンダイ",
  "releaseDate": "1993/4/9",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "390",
  "ini": "え",
  "name": "NBAオールスターチャレンジ2",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/4/16",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "391",
  "ini": "ま",
  "name": "魔界村外伝　THE DEMON DARKNESS",
  "maker": "カプコン",
  "releaseDate": "1993/4/16",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "392",
  "ini": "み",
  "name": "ミッキーマウスⅣ 魔法のラビリンス",
  "maker": "ケムコ",
  "releaseDate": "1993/4/23",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "393",
  "ini": "た",
  "name": "WWFスーパースターズ2",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/5/21",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "394",
  "ini": "よ",
  "name": "妖精物語 ロッド・ランド",
  "maker": "ジャレコ",
  "releaseDate": "1993/5/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "395",
  "ini": "は",
  "name": "バブルボブルJr",
  "maker": "タイトー",
  "releaseDate": "1993/5/28",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "396",
  "ini": "ほ",
  "name": "ポピュラス外伝",
  "maker": "イマジニア",
  "releaseDate": "1993/5/28",
  "price": "4900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "397",
  "ini": "す",
  "name": "鈴木亜久里のF-1スーパードライビング",
  "maker": "ロジーク",
  "releaseDate": "1993/5/28",
  "price": "3900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "398",
  "ini": "せ",
  "name": "ゼルダの伝説 夢をみる島",
  "maker": "任天堂",
  "releaseDate": "1993/6/6",
  "price": "3900",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "399",
  "ini": "は",
  "name": "パチスロキッズ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/6/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "400",
  "ini": "す",
  "name": "スプリッツ 似顔絵15ゲーム",
  "maker": "イマジニア",
  "releaseDate": "1993/6/25",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "401",
  "ini": "ふ",
  "name": "ブービーボーイズ",
  "maker": "日本物産",
  "releaseDate": "1993/6/25",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "402",
  "ini": "え",
  "name": "エイリアン3",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/7/9",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "403",
  "ini": "ひ",
  "name": "びっくり熱血新記録！どこでも金メダル",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/7/16",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "404",
  "ini": "ま",
  "name": "マイケルジョーダン ONE ON ONE",
  "maker": "EAビクター",
  "releaseDate": "1993/7/16",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "405",
  "ini": "こ",
  "name": "ゴッドメディスン　ファンタジー世界の誕生",
  "maker": "コナミ",
  "releaseDate": "1993/7/20",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "406",
  "ini": "し",
  "name": "ジャレコ Jカップサッカー",
  "maker": "ジャレコ",
  "releaseDate": "1993/7/23",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "407",
  "ini": "み",
  "name": "ミステリアム",
  "maker": "バップ",
  "releaseDate": "1993/7/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "408",
  "ini": "あ",
  "name": "ああ播磨灘",
  "maker": "アスク講談社",
  "releaseDate": "1993/7/23",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "409",
  "ini": "し",
  "name": "ジミーコナーズのプロテニスツアー",
  "maker": "ミサワエンターテイメント",
  "releaseDate": "1993/7/23",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "410",
  "ini": "ゆ",
  "name": "幽☆遊☆白書",
  "maker": "トミー",
  "releaseDate": "1993/7/23",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "411",
  "ini": "し",
  "name": "主役戦隊アイレムファイター",
  "maker": "アイレム",
  "releaseDate": "1993/7/30",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "412",
  "ini": "て",
  "name": "的中ラッシュ",
  "maker": "日本クラリービジネス",
  "releaseDate": "1993/7/30",
  "price": "6900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "413",
  "ini": "て",
  "name": "鉄球ファイト！ザ・グレイトバトル外伝",
  "maker": "バンプレスト",
  "releaseDate": "1993/7/30",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "414",
  "ini": "そ",
  "name": "ソルダム",
  "maker": "ジャレコ",
  "releaseDate": "1993/8/6",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "415",
  "ini": "ら",
  "name": "らんま1/2 格劇問答!!",
  "maker": "バンプレスト",
  "releaseDate": "1993/8/6",
  "price": "3980",
  "genre": "クイズ・RPG",
  "icNo": "1"
 },
 {
  "id": "416",
  "ini": "も",
  "name": "桃太郎電劇",
  "maker": "ハドソン",
  "releaseDate": "1993/8/8",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "417",
  "ini": "も",
  "name": "モンスターメーカー バーコードサーガ（バーコードボーイ専用）",
  "maker": "ナムコ",
  "releaseDate": "1993/8/10",
  "price": "3800",
  "genre": "バーコードボーイ",
  "icNo": "1"
 },
 {
  "id": "418",
  "ini": "た",
  "name": "ダブル役満Jr.",
  "maker": "バップ",
  "releaseDate": "1993/8/19",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "419",
  "ini": "か",
  "name": "仮面ライダーSD 走れ！マイティライダーズ",
  "maker": "ユタカ",
  "releaseDate": "1993/8/20",
  "price": "3599",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "420",
  "ini": "え",
  "name": "MVPベースボール",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/8/27",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "421",
  "ini": "ふ",
  "name": "ファミリージョッキー2 名馬の血統",
  "maker": "ナムコ",
  "releaseDate": "1993/8/27",
  "price": "4500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "422",
  "ini": "か",
  "name": "勝馬予想 競馬貴族",
  "maker": "キングレコード",
  "releaseDate": "1993/8/27",
  "price": "5900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "423",
  "ini": "か",
  "name": "カルトジャンプ",
  "maker": "バンダイ",
  "releaseDate": "1993/9/10",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "424",
  "ini": "た",
  "name": "ダブル役満Ⅱ",
  "maker": "バップ",
  "releaseDate": "1993/9/17",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "425",
  "ini": "れ",
  "name": "レミングス",
  "maker": "イマジニア",
  "releaseDate": "1993/9/23",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "426",
  "ini": "え",
  "name": "エイリアンVSプレデター",
  "maker": "アスク講談社",
  "releaseDate": "1993/9/24",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "427",
  "ini": "う",
  "name": "ウィザードリィ外伝Ⅲ 闇の聖典",
  "maker": "アスキー",
  "releaseDate": "1993/9/25",
  "price": "5150",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "428",
  "ini": "か",
  "name": "カットビロード",
  "maker": "ナムコ",
  "releaseDate": "1993/10/8",
  "price": "4800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "429",
  "ini": "く",
  "name": "クレヨンしんちゃん2 オラとわんぱくごっこだゾ",
  "maker": "バナレックス",
  "releaseDate": "1993/10/22",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "430",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズPART4",
  "maker": "トミー",
  "releaseDate": "1993/10/29",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "431",
  "ini": "ふ",
  "name": "ファミスタ3",
  "maker": "ナムコ",
  "releaseDate": "1993/10/29",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "432",
  "ini": "ろ",
  "name": "ロックマンワールド4",
  "maker": "カプコン",
  "releaseDate": "1993/10/29",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "433",
  "ini": "う",
  "name": "ウルティマ 失われたルーン2",
  "maker": "ポニーキャニオン",
  "releaseDate": "1993/11/19",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "434",
  "ini": "は",
  "name": "GB パチ夫くん",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/11/19",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "435",
  "ini": "め",
  "name": "女神転生外伝 ラストバイブルⅡ",
  "maker": "アトラス",
  "releaseDate": "1993/11/19",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "436",
  "ini": "て",
  "name": "ティーンエイジ ミュータント ニンジャ タートルズ3 タートルズ危機一発",
  "maker": "コナミ",
  "releaseDate": "1993/11/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "437",
  "ini": "う",
  "name": "UNO スモールワールド",
  "maker": "トミー",
  "releaseDate": "1993/11/26",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "438",
  "ini": "か",
  "name": "カービィのピンボール",
  "maker": "任天堂",
  "releaseDate": "1993/11/27",
  "price": "2900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "439",
  "ini": "た",
  "name": "ダックテイルズ2",
  "maker": "カプコン",
  "releaseDate": "1993/12/3",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "440",
  "ini": "あ",
  "name": "アンダーカバーコップス 破壊神ガルマァ",
  "maker": "アイレム",
  "releaseDate": "1993/12/10",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "441",
  "ini": "ひ",
  "name": "ピングー 世界で1番元気なペンギン",
  "maker": "ビーアイ",
  "releaseDate": "1993/12/10",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "442",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 第2弾 暗黒武術会編",
  "maker": "トミー",
  "releaseDate": "1993/12/10",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "443",
  "ini": "か",
  "name": "怪獣王ゴジラ",
  "maker": "バンダイ",
  "releaseDate": "1993/12/17",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "444",
  "ini": "は",
  "name": "馬券王V3",
  "maker": "アスミック",
  "releaseDate": "1993/12/17",
  "price": "6500",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "445",
  "ini": "さ",
  "name": "最後の忍道",
  "maker": "アイレム",
  "releaseDate": "1993/12/18",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "446",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ2 バスターバニーのかっとびだいぼうけん",
  "maker": "コナミ",
  "releaseDate": "1993/12/22",
  "price": "3600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "447",
  "ini": "た",
  "name": "ダウンタウンスペシャル くにおくんの時代劇だよ全員集合！",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/12/22",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "448",
  "ini": "み",
  "name": "ミッキーマウスⅤ 魔法のステッキ",
  "maker": "ケムコ",
  "releaseDate": "1993/12/22",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "449",
  "ini": "も",
  "name": "モータルコンバット 神拳降臨伝説",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/12/24",
  "price": "4300",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "450",
  "ini": "は",
  "name": "バトルトード",
  "maker": "メサイヤ",
  "releaseDate": "1994/1/7",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "451",
  "ini": "と",
  "name": "トムとジェリー PART2",
  "maker": "アルトロン",
  "releaseDate": "1994/1/14",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "452",
  "ini": "す",
  "name": "スーパーマリオランド3 ワリオランド",
  "maker": "任天堂",
  "releaseDate": "1994/1/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "453",
  "ini": "す",
  "name": "スポットクール アドベンチャー",
  "maker": "ヴァージン",
  "releaseDate": "1994/2/11",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "454",
  "ini": "は",
  "name": "バーチャルウォーズ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/2/11",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "455",
  "ini": "す",
  "name": "スーパー桃太郎電鉄Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1994/2/18",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "456",
  "ini": "ほ",
  "name": "ホイホイ ゲームボーイ版",
  "maker": "光栄",
  "releaseDate": "1994/2/18",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "457",
  "ini": "た",
  "name": "武田修宏のエースストライカー",
  "maker": "ジャレコ",
  "releaseDate": "1994/2/18",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "458",
  "ini": "て",
  "name": "T2 ザ・アーケードゲーム",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/2/25",
  "price": "3890",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "459",
  "ini": "う",
  "name": "ウェルカム なかよしパーク",
  "maker": "バンダイ",
  "releaseDate": "1994/3/3",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "460",
  "ini": "お",
  "name": "ONIⅣ 鬼神の血族",
  "maker": "バンプレスト",
  "releaseDate": "1994/3/11",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "461",
  "ini": "し",
  "name": "疾風！アイアンリーガー",
  "maker": "バンダイ",
  "releaseDate": "1994/3/11",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "462",
  "ini": "し",
  "name": "雀四郎2",
  "maker": "サミー工業",
  "releaseDate": "1994/3/18",
  "price": "4200",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "463",
  "ini": "た",
  "name": "WWF キング オブ ザ リング",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/3/25",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "464",
  "ini": "る",
  "name": "ルクル",
  "maker": "ビック東海",
  "releaseDate": "1994/3/25",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "465",
  "ini": "ろ",
  "name": "ロロの大冒険",
  "maker": "イマジニア",
  "releaseDate": "1994/3/25",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "466",
  "ini": "け",
  "name": "剣勇伝説ヤイバ",
  "maker": "バンプレスト",
  "releaseDate": "1994/3/25",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "467",
  "ini": "た",
  "name": "大工の源さん ロボット帝国の野望",
  "maker": "アイレム",
  "releaseDate": "1994/3/25",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "468",
  "ini": "な",
  "name": "南国少年パプワくん ガンマ団の野望",
  "maker": "エニックス",
  "releaseDate": "1994/3/25",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "469",
  "ini": "く",
  "name": "クレヨンしんちゃん3 オラのごきげんアスレチック",
  "maker": "バンダイ",
  "releaseDate": "1994/3/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "470",
  "ini": "み",
  "name": "Mr.GOの馬券的中術",
  "maker": "タイトー",
  "releaseDate": "1994/4/1",
  "price": "5900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "471",
  "ini": "し",
  "name": "Jリーグ ウィニングゴール",
  "maker": "EAV",
  "releaseDate": "1994/4/2",
  "price": "5980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "472",
  "ini": "つ",
  "name": "詰め将棋 百番勝負",
  "maker": "イマジニア",
  "releaseDate": "1994/4/8",
  "price": "4700",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "473",
  "ini": "も",
  "name": "モグラでポン！",
  "maker": "アテナ",
  "releaseDate": "1994/4/15",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "474",
  "ini": "つ",
  "name": "詰将棋 問題提供「将棋世界」",
  "maker": "アイマックス",
  "releaseDate": "1994/4/15",
  "price": "4700",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "475",
  "ini": "し",
  "name": "GB原人ランド ビバ！ちっくん王国",
  "maker": "ハドソン",
  "releaseDate": "1994/4/22",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "476",
  "ini": "は",
  "name": "パチ夫くん キャッスル",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/4/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "477",
  "ini": "ふ",
  "name": "フィジェッツ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/4/22",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "478",
  "ini": "て",
  "name": "天地を喰らう",
  "maker": "カプコン",
  "releaseDate": "1994/4/22",
  "price": "4600",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "479",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンR",
  "maker": "エンジェル",
  "releaseDate": "1994/4/22",
  "price": "3800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "480",
  "ini": "の",
  "name": "ノンタンといっしょ くるくるぱずる",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/4/28",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "481",
  "ini": "る",
  "name": "ルーニー・テューンズ それゆけ！スピーディー・ゴンザレス",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/4/29",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "482",
  "ini": "た",
  "name": "太陽の天使マーロー お花畑は大パニック",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/5/27",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "483",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 第3弾 魔界の扉編",
  "maker": "トミー",
  "releaseDate": "1994/6/3",
  "price": "3980",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "484",
  "ini": "さ",
  "name": "ザ・心理ゲーム",
  "maker": "ヴィジット",
  "releaseDate": "1994/6/10",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "485",
  "ini": "は",
  "name": "パチスロキッズ2",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/6/10",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "486",
  "ini": "て",
  "name": "テトリスフラッシュ",
  "maker": "任天堂",
  "releaseDate": "1994/6/14",
  "price": "2900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "487",
  "ini": "と",
  "name": "ドンキーコング",
  "maker": "任天堂",
  "releaseDate": "1994/6/14",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "488",
  "ini": "わ",
  "name": "ワールドカップストライカー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/6/17",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "489",
  "ini": "ね",
  "name": "熱闘 サムライスピリッツ",
  "maker": "タカラ",
  "releaseDate": "1994/6/30",
  "price": "4660",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "490",
  "ini": "ろ",
  "name": "ろっくん！もんすたぁ!!",
  "maker": "ホリ電気",
  "releaseDate": "1994/7/1",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "491",
  "ini": "き",
  "name": "キテレツ大百科 冒険大江戸ジュラ紀",
  "maker": "ビデオシステム",
  "releaseDate": "1994/7/15",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "492",
  "ini": "せ",
  "name": "全日本プロレスジェット",
  "maker": "メサイヤ",
  "releaseDate": "1994/7/15",
  "price": "4880",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "493",
  "ini": "ろ",
  "name": "ロックマンワールド5",
  "maker": "カプコン",
  "releaseDate": "1994/7/22",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "494",
  "ini": "し",
  "name": "GBパチスロ必勝法！Jr.",
  "maker": "サミー工業",
  "releaseDate": "1994/7/29",
  "price": "4777",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "495",
  "ini": "く",
  "name": "クリスティワールド",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/7/29",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "496",
  "ini": "し",
  "name": "ジャングルの王者ターちゃん",
  "maker": "バンダイ",
  "releaseDate": "1994/7/29",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "497",
  "ini": "は",
  "name": "パチスロワールドカップ'94",
  "maker": "アイマックス",
  "releaseDate": "1994/7/29",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "498",
  "ini": "わ",
  "name": "ワールドカップUSA'94",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/7/29",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "499",
  "ini": "か",
  "name": "勝馬予想 競馬貴族EX'94",
  "maker": "キングレコード",
  "releaseDate": "1994/7/29",
  "price": "6900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "500",
  "ini": "ね",
  "name": "熱血！ビーチバレーだよ? くにおくん",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/7/29",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "501",
  "ini": "ね",
  "name": "熱闘 餓狼伝説2 あらたなるたたかい",
  "maker": "タカラ",
  "releaseDate": "1994/7/29",
  "price": "4660",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "502",
  "ini": "ふ",
  "name": "ぷよぷよ",
  "maker": "バンプレスト",
  "releaseDate": "1994/7/31",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "503",
  "ini": "さ",
  "name": "3丁目のタマ タマ&フレンズ 3丁目お化けパニック!!",
  "maker": "ビーアイ",
  "releaseDate": "1994/8/5",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "504",
  "ini": "ほ",
  "name": "ポコニャン！夢の大冒険",
  "maker": "東宝・小学館プロダクション",
  "releaseDate": "1994/8/5",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "505",
  "ini": "ほ",
  "name": "ボンバーマンGB",
  "maker": "ハドソン",
  "releaseDate": "1994/8/10",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "506",
  "ini": "て",
  "name": "From TV animation スラムダンク がけっぷちの決勝リーグ",
  "maker": "バンダイ",
  "releaseDate": "1994/8/11",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "507",
  "ini": "う",
  "name": "ウルトラマン 超闘士激伝",
  "maker": "エンジェル",
  "releaseDate": "1994/8/26",
  "price": "3800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "508",
  "ini": "く",
  "name": "クレヨンしんちゃん4 オラのいたずら大変身",
  "maker": "バンダイ",
  "releaseDate": "1994/8/26",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "509",
  "ini": "し",
  "name": "新SDガンダム外伝 ナイトガンダム物語",
  "maker": "バンダイ",
  "releaseDate": "1994/9/9",
  "price": "3980",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "510",
  "ini": "す",
  "name": "スーパーストリートバスケットボール2",
  "maker": "バップ",
  "releaseDate": "1994/9/16",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "511",
  "ini": "れ",
  "name": "連対王",
  "maker": "ヴィジット",
  "releaseDate": "1994/9/16",
  "price": "5500",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "512",
  "ini": "と",
  "name": "とっても！ラッキーマン ラッキークッキーみんな大好きー!!",
  "maker": "バンダイ",
  "releaseDate": "1994/9/22",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "513",
  "ini": "こ",
  "name": "魂斗羅スピリッツ",
  "maker": "コナミ",
  "releaseDate": "1994/9/23",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "514",
  "ini": "お",
  "name": "オセロワールド",
  "maker": "ツクダオリジナル",
  "releaseDate": "1994/9/30",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "515",
  "ini": "は",
  "name": "バートのジャックと豆の木",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/9/30",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "516",
  "ini": "る",
  "name": "ルーニー・テューンズ ダフィー・ダック",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/9/30",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "517",
  "ini": "ほ",
  "name": "本命 ボーイ",
  "maker": "日本物産",
  "releaseDate": "1994/10/7",
  "price": "6700",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "518",
  "ini": "さ",
  "name": "ザ・心理ゲーム2 大阪編",
  "maker": "ヴィジット",
  "releaseDate": "1994/10/14",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "519",
  "ini": "つ",
  "name": "詰将棋 神吉五段",
  "maker": "魔法",
  "releaseDate": "1994/10/19",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "520",
  "ini": "い",
  "name": "詰碁 シリーズ1 藤沢秀行名誉棋聖",
  "maker": "魔法",
  "releaseDate": "1994/10/19",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "521",
  "ini": "し",
  "name": "GB原人2",
  "maker": "ハドソン",
  "releaseDate": "1994/10/21",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "522",
  "ini": "て",
  "name": "TVチャンピオン",
  "maker": "ユタカ",
  "releaseDate": "1994/10/28",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "523",
  "ini": "ち",
  "name": "チェスマスター",
  "maker": "アルトロン",
  "releaseDate": "1994/10/28",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "524",
  "ini": "ま",
  "name": "マサカリ伝説 金太郎RPG編",
  "maker": "トンキンハウス",
  "releaseDate": "1994/10/28",
  "price": "4660",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "525",
  "ini": "は",
  "name": "馬券王TV'94",
  "maker": "アスミック",
  "releaseDate": "1994/10/28",
  "price": "6500",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "も",
  "name": "モータルコンバットⅡ 究極神拳",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/11/11",
  "price": "4200",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "527",
  "ini": "に",
  "name": "ニチブチマージャン 吉本劇場",
  "maker": "日本物産",
  "releaseDate": "1994/11/18",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "528",
  "ini": "け",
  "name": "携帯競馬エイトスペシャル",
  "maker": "イマジニア",
  "releaseDate": "1994/11/18",
  "price": "6900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "529",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ3  ドキドキスポーツフェスティバル",
  "maker": "コナミ",
  "releaseDate": "1994/11/25",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "530",
  "ini": "と",
  "name": "ドラゴンボールZ 悟空飛翔伝",
  "maker": "バンダイ",
  "releaseDate": "1994/11/25",
  "price": "3800",
  "genre": "アドベンチャー・アクション",
  "icNo": "1"
 },
 {
  "id": "531",
  "ini": "は",
  "name": "GBパチ夫くん2",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/11/25",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "532",
  "ini": "ほ",
  "name": "本将棋",
  "maker": "イマジニア",
  "releaseDate": "1994/11/25",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "533",
  "ini": "は",
  "name": "パックパニック",
  "maker": "ナムコ",
  "releaseDate": "1994/12/9",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "534",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 第4弾 魔界統一編",
  "maker": "トミー",
  "releaseDate": "1994/12/9",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "535",
  "ini": "も",
  "name": "桃太郎電劇2",
  "maker": "ハドソン",
  "releaseDate": "1994/12/16",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "536",
  "ini": "す",
  "name": "スーパースネーキー",
  "maker": "四次元",
  "releaseDate": "1994/12/20",
  "price": "3800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "537",
  "ini": "う",
  "name": "ウルトラマンボール",
  "maker": "ベック",
  "releaseDate": "1994/12/22",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "538",
  "ini": "と",
  "name": "トコロ'SまあじゃんJr.",
  "maker": "ビック東海",
  "releaseDate": "1994/12/22",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "539",
  "ini": "い",
  "name": "インディージョーンズ 最後の聖戦",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/12/23",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "540",
  "ini": "ふ",
  "name": "プロ麻雀 極 GB",
  "maker": "アテナ",
  "releaseDate": "1994/12/23",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "541",
  "ini": "し",
  "name": "将棋最強",
  "maker": "魔法",
  "releaseDate": "1994/12/23",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "542",
  "ini": "な",
  "name": "灘麻太朗のパワフル麻雀 次の一手100題",
  "maker": "四次元",
  "releaseDate": "1994/12/23",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "543",
  "ini": "は",
  "name": "パックインタイム",
  "maker": "ナムコ",
  "releaseDate": "1995/1/3",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "544",
  "ini": "す",
  "name": "スーパーチャイニーズランド3",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/1/13",
  "price": "4200",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "545",
  "ini": "は",
  "name": "パチスロ必勝ガイド GB",
  "maker": "マジファクト",
  "releaseDate": "1995/1/27",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "546",
  "ini": "は",
  "name": "バトルクラッシャー",
  "maker": "バンプレスト",
  "releaseDate": "1995/1/27",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "547",
  "ini": "ま",
  "name": "ママレード・ボーイ",
  "maker": "バンダイ",
  "releaseDate": "1995/1/27",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "548",
  "ini": "ね",
  "name": "熱闘 ワールドヒーローズ2JET",
  "maker": "タカラ",
  "releaseDate": "1995/2/24",
  "price": "4660",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "549",
  "ini": "う",
  "name": "UNO2 スモールワールド",
  "maker": "トミー",
  "releaseDate": "1995/3/3",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "550",
  "ini": "あ",
  "name": "アナザ・バイブル",
  "maker": "アトラス",
  "releaseDate": "1995/3/3",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "551",
  "ini": "か",
  "name": "ガメラ 大怪獣空中決戦",
  "maker": "エンジェル",
  "releaseDate": "1995/3/3",
  "price": "3980",
  "genre": "コマンドバトル",
  "icNo": "1"
 },
 {
  "id": "552",
  "ini": "ま",
  "name": "マリオのピクロス",
  "maker": "任天堂",
  "releaseDate": "1995/3/14",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "553",
  "ini": "え",
  "name": "NFLクォーターバッククラブ'95",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/3/17",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "554",
  "ini": "て",
  "name": "From TV animation スラムダンク2 全国へのTIP OFF",
  "maker": "バンダイ",
  "releaseDate": "1995/3/17",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "555",
  "ini": "す",
  "name": "スーパーボンブリス",
  "maker": "BPS",
  "releaseDate": "1995/3/17",
  "price": "4500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "556",
  "ini": "ほ",
  "name": "星のカービィ2",
  "maker": "任天堂",
  "releaseDate": "1995/3/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "557",
  "ini": "お",
  "name": "ONIⅤ 隠忍を継ぐ者",
  "maker": "バンプレスト",
  "releaseDate": "1995/3/24",
  "price": "4980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "558",
  "ini": "は",
  "name": "パチスロキッズ3",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1995/3/24",
  "price": "4500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "559",
  "ini": "け",
  "name": "原人コッツ",
  "maker": "ビーアイ",
  "releaseDate": "1995/3/24",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "560",
  "ini": "あ",
  "name": "蒼き伝説シュート！",
  "maker": "バンプレスト",
  "releaseDate": "1995/4/7",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "561",
  "ini": "え",
  "name": "SD飛龍の拳外伝",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/4/14",
  "price": "4700",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "562",
  "ini": "か",
  "name": "勝馬予想 競馬貴族EX'95",
  "maker": "キングレコード",
  "releaseDate": "1995/4/14",
  "price": "6900",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "563",
  "ini": "し",
  "name": "JリーグLIVE'95",
  "maker": "EAV",
  "releaseDate": "1995/4/21",
  "price": "5980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "564",
  "ini": "ち",
  "name": "チキチキ天国",
  "maker": "J・ウイング",
  "releaseDate": "1995/4/28",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "565",
  "ini": "は",
  "name": "GBパチ夫くん3",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1995/4/28",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "566",
  "ini": "く",
  "name": "空想科学世界ガリバーボーイ 空想科学パズル プリッとポン",
  "maker": "バンダイ",
  "releaseDate": "1995/4/28",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "567",
  "ini": "あ",
  "name": "赤ずきんチャチャ",
  "maker": "トミー",
  "releaseDate": "1995/4/28",
  "price": "3900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "568",
  "ini": "ま",
  "name": "魔法陣グルグル 勇者とククリの大冒険",
  "maker": "タカラ",
  "releaseDate": "1995/4/28",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "569",
  "ini": "ち",
  "name": "ちびまる子ちゃん まる子デラックス劇場",
  "maker": "タカラ",
  "releaseDate": "1995/5/26",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "570",
  "ini": "ま",
  "name": "魔法騎士レイアース",
  "maker": "トミー",
  "releaseDate": "1995/6/2",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "571",
  "ini": "も",
  "name": "モグモグGOMBO 遥かなる超料理伝説",
  "maker": "バンダイ",
  "releaseDate": "1995/6/16",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "572",
  "ini": "は",
  "name": "パチンコ物語外伝",
  "maker": "ケイエスエス",
  "releaseDate": "1995/6/23",
  "price": "5980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "573",
  "ini": "し",
  "name": "人生ゲーム",
  "maker": "タカラ",
  "releaseDate": "1995/6/23",
  "price": "4660",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "574",
  "ini": "す",
  "name": "スーパーパチンコ大戦",
  "maker": "バンプレスト",
  "releaseDate": "1995/6/30",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "575",
  "ini": "た",
  "name": "第2次スーパーロボット大戦G",
  "maker": "バンプレスト",
  "releaseDate": "1995/6/30",
  "price": "5980",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "576",
  "ini": "に",
  "name": "NINKU -忍空-",
  "maker": "トミー",
  "releaseDate": "1995/7/14",
  "price": "4200",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "577",
  "ini": "き",
  "name": "ギャラガ&ギャラクシアン",
  "maker": "ナムコ",
  "releaseDate": "1995/7/14",
  "price": "3800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "578",
  "ini": "ふ",
  "name": "フリスキートム",
  "maker": "日本物産",
  "releaseDate": "1995/7/14",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "579",
  "ini": "す",
  "name": "スーパードンキーコングGB",
  "maker": "任天堂",
  "releaseDate": "1995/7/27",
  "price": "3900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "580",
  "ini": "あ",
  "name": "アルフレッドチキン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/7/28",
  "price": "4300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "581",
  "ini": "お",
  "name": "おとぎ話大戦",
  "maker": "四次元",
  "releaseDate": "1995/8/4",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "582",
  "ini": "ほ",
  "name": "ボンバーマンGB2",
  "maker": "ハドソン",
  "releaseDate": "1995/8/10",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "583",
  "ini": "す",
  "name": "ストリートファイターⅡ",
  "maker": "カプコン",
  "releaseDate": "1995/8/11",
  "price": "4800",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "584",
  "ini": "こ",
  "name": "GO GO アックマン",
  "maker": "バンプレスト",
  "releaseDate": "1995/8/25",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "585",
  "ini": "と",
  "name": "ドラゴンボールZ 悟空激闘伝",
  "maker": "バンダイ",
  "releaseDate": "1995/8/25",
  "price": "5800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "586",
  "ini": "き",
  "name": "キャプテン翼J 完全制覇への挑戦",
  "maker": "バンダイ",
  "releaseDate": "1995/9/14",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "587",
  "ini": "は",
  "name": "ハイパーブラックバス'95",
  "maker": "BLACKLABEL",
  "releaseDate": "1995/10/20",
  "price": "4500",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "588",
  "ini": "え",
  "name": "NBA JAM トーナメントエディション",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "589",
  "ini": "し",
  "name": "ジャッジ・ドレッド",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "590",
  "ini": "は",
  "name": "バットマン フォーエバー",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "591",
  "ini": "ふ",
  "name": "フォアマン フォー リアル",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "4200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "592",
  "ini": "ま",
  "name": "魔法騎士レイアース 2nd ミッシングカラーズ",
  "maker": "トミー",
  "releaseDate": "1995/10/27",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "593",
  "ini": "し",
  "name": "Jリーグ ビックウェイブサッカー",
  "maker": "トミー",
  "releaseDate": "1995/11/24",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "594",
  "ini": "に",
  "name": "NINKU -忍空- 忍空戦争編",
  "maker": "トミー",
  "releaseDate": "1995/11/24",
  "price": "4800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "595",
  "ini": "う",
  "name": "ウェディングピーチ じゃ魔ピーパニック",
  "maker": "ケイエスエス",
  "releaseDate": "1995/12/8",
  "price": "4980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "596",
  "ini": "か",
  "name": "カービィのブロックボール",
  "maker": "任天堂",
  "releaseDate": "1995/12/14",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "597",
  "ini": "ひ",
  "name": "P-マン GB",
  "maker": "ケムコ",
  "releaseDate": "1995/12/22",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "598",
  "ini": "と",
  "name": "東京ディズニーランド ミッキーのシンデレラ城ミステリーツアー",
  "maker": "トミー",
  "releaseDate": "1995/12/22",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "599",
  "ini": "に",
  "name": "忍たま乱太郎 GB",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/12/27",
  "price": "4800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "600",
  "ini": "ふ",
  "name": "ブロックくずし GB",
  "maker": "POW",
  "releaseDate": "1995/12/29",
  "price": "3980",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "601",
  "ini": "ほ",
  "name": "ポケットモンスター　赤",
  "maker": "任天堂",
  "releaseDate": "1996/2/27",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "602",
  "ini": "ほ",
  "name": "ポケットモンスター　緑",
  "maker": "任天堂",
  "releaseDate": "1996/2/27",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "603",
  "ini": "く",
  "name": "くまのプー太郎 宝さがしだ大入りゲームバトル",
  "maker": "タカラ",
  "releaseDate": "1996/2/29",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "604",
  "ini": "ね",
  "name": "熱闘 闘神伝",
  "maker": "タカラ",
  "releaseDate": "1996/3/22",
  "price": "4660",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "605",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ1 小一こぐご かん字",
  "maker": "小学館",
  "releaseDate": "1996/4/25",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "606",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ2 小一さんすう けいさん",
  "maker": "小学館",
  "releaseDate": "1996/4/25",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "607",
  "ini": "ね",
  "name": "熱闘 ザ・キング・オブ・ファイターズ'95",
  "maker": "タカラ",
  "releaseDate": "1996/4/26",
  "price": "4500",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "608",
  "ini": "れ",
  "name": "連珠倶楽部 五目ならべ",
  "maker": "ヘクト",
  "releaseDate": "1996/5/17",
  "price": "2480",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "609",
  "ini": "は",
  "name": "バスフィッシング達人手帳",
  "maker": "スターフィッシュ",
  "releaseDate": "1996/6/21",
  "price": "9800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "610",
  "ini": "な",
  "name": "ナムコギャラリー vol.1",
  "maker": "ナムコ",
  "releaseDate": "1996/7/21",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "611",
  "ini": "ほ",
  "name": "ボンバーマンコレクション",
  "maker": "ハドソン",
  "releaseDate": "1996/7/21",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "612",
  "ini": "も",
  "name": "モグラーニャ",
  "maker": "任天堂",
  "releaseDate": "1996/7/21",
  "price": "3900",
  "genre": "パズル・アクション",
  "icNo": "1"
 },
 {
  "id": "613",
  "ini": "も",
  "name": "桃太郎コレクション",
  "maker": "ハドソン",
  "releaseDate": "1996/8/9",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "614",
  "ini": "ね",
  "name": "熱闘 サムライスピリッツ 斬九郎無双剣",
  "maker": "タカラ",
  "releaseDate": "1996/8/23",
  "price": "4500",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "615",
  "ini": "す",
  "name": "スーパーチャイニーズランド1・2・3",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/9/13",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "616",
  "ini": "え",
  "name": "SD飛龍の拳外伝2",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/9/27",
  "price": "4200",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "617",
  "ini": "す",
  "name": "ストリートレーサー",
  "maker": "ユービーアイソフト",
  "releaseDate": "1996/9/27",
  "price": "4980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "618",
  "ini": "す",
  "name": "スポーツコレクション",
  "maker": "トンキンハウス",
  "releaseDate": "1996/9/27",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "619",
  "ini": "ほ",
  "name": "ポケットモンスター　青",
  "maker": "任天堂",
  "releaseDate": "1996/10/15",
  "price": "3000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "620",
  "ini": "ひ",
  "name": "ピクロス2",
  "maker": "任天堂",
  "releaseDate": "1996/10/19",
  "price": "3000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "621",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ3 九九マスター",
  "maker": "小学館",
  "releaseDate": "1996/10/25",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "622",
  "ini": "よ",
  "name": "ヨッシーのパネポン",
  "maker": "任天堂",
  "releaseDate": "1996/10/26",
  "price": "3000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "623",
  "ini": "は",
  "name": "パズル忍たま乱太郎",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/11/1",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "624",
  "ini": "け",
  "name": "原人コレクション",
  "maker": "ハドソン",
  "releaseDate": "1996/11/22",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "625",
  "ini": "ふ",
  "name": "不思議のダンジョン 風来のシレンGB 月影村の怪物",
  "maker": "チュンソフト",
  "releaseDate": "1996/11/22",
  "price": "3900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "626",
  "ini": "と",
  "name": "ドンキーコングランド",
  "maker": "任天堂",
  "releaseDate": "1996/11/23",
  "price": "3000",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "627",
  "ini": "な",
  "name": "ナムコギャラリー vol.2",
  "maker": "ナムコ",
  "releaseDate": "1996/11/29",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "628",
  "ini": "は",
  "name": "パチ夫くん ゲームギャラリー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1996/11/29",
  "price": "4300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "629",
  "ini": "く",
  "name": "クレヨンしんちゃん オラのごきげんコレクション",
  "maker": "バンダイ",
  "releaseDate": "1996/12/2",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "630",
  "ini": "ほ",
  "name": "ボンバーマンGB3",
  "maker": "ハドソン",
  "releaseDate": "1996/12/2",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "631",
  "ini": "も",
  "name": "桃太郎コレクション2",
  "maker": "ハドソン",
  "releaseDate": "1996/12/6",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "632",
  "ini": "け",
  "name": "ゲゲゲの鬼太郎 妖怪創造主現る！",
  "maker": "バンダイ",
  "releaseDate": "1996/12/13",
  "price": "4500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "633",
  "ini": "ほ",
  "name": "ぽけっとぷよぷよ通",
  "maker": "コンパイル",
  "releaseDate": "1996/12/13",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "634",
  "ini": "こ",
  "name": "合格ボーイシリーズ 英単語ターゲット1900",
  "maker": "イマジニア",
  "releaseDate": "1996/12/13",
  "price": "2050",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "635",
  "ini": "す",
  "name": "スヌーピーのはじめてのおつかい",
  "maker": "ケムコ",
  "releaseDate": "1996/12/21",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "636",
  "ini": "み",
  "name": "みどりのマキバオー",
  "maker": "トミー",
  "releaseDate": "1996/12/21",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "637",
  "ini": "て",
  "name": "テトリスプラス",
  "maker": "ジャレコ",
  "releaseDate": "1996/12/27",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "638",
  "ini": "み",
  "name": "ミニ4ボーイ",
  "maker": "J・ウイング",
  "releaseDate": "1996/12/27",
  "price": "4980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "639",
  "ini": "め",
  "name": "名探偵コナン 地下遊園地殺人事件",
  "maker": "バンダイ",
  "releaseDate": "1996/12/27",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "640",
  "ini": "す",
  "name": "スーパーチャイニーズファイターGB",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/12/28",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "641",
  "ini": "す",
  "name": "スーパーブラックバス ポケット",
  "maker": "スターフィッシュ",
  "releaseDate": "1996/12/28",
  "price": "4980",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "642",
  "ini": "こ",
  "name": "合格ボーイシリーズ セットで覚える 日本史ターゲット201",
  "maker": "イマジニア",
  "releaseDate": "1997/1/24",
  "price": "2050",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "643",
  "ini": "か",
  "name": "カービィのきらきらきっず",
  "maker": "任天堂",
  "releaseDate": "1997/1/25",
  "price": "3000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "644",
  "ini": "す",
  "name": "ズープ",
  "maker": "やのまんゲームズ",
  "releaseDate": "1997/1/31",
  "price": "2800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "645",
  "ini": "け",
  "name": "ゲームボーイギャラリー",
  "maker": "任天堂",
  "releaseDate": "1997/2/1",
  "price": "3000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "646",
  "ini": "ち",
  "name": "チャルボ55 SUPER PUZZLE ACTION",
  "maker": "日本システムサプライ",
  "releaseDate": "1997/2/21",
  "price": "3800",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "647",
  "ini": "た",
  "name": "タイトーバラエティパック",
  "maker": "タイトー",
  "releaseDate": "1997/2/28",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "648",
  "ini": "こ",
  "name": "合格ボーイシリーズ 大学入試出る順 英熟語ターゲット1000",
  "maker": "イマジニア",
  "releaseDate": "1997/3/28",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "649",
  "ini": "せ",
  "name": "セームゲーム",
  "maker": "ハドソン",
  "releaseDate": "1997/4/25",
  "price": "3480",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "650",
  "ini": "み",
  "name": "ミニ四駆GB Let's&Go!!",
  "maker": "アスキー",
  "releaseDate": "1997/5/23",
  "price": "4900",
  "genre": "レース・シュミレーション",
  "icNo": "1"
 },
 {
  "id": "651",
  "ini": "す",
  "name": "スーパーブラックバスポケット2",
  "maker": "スターフィッシュ",
  "releaseDate": "1997/6/20",
  "price": "3980",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "652",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ4 小二国語 かん字",
  "maker": "小学館",
  "releaseDate": "1997/6/25",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "653",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ5 小二算数 計算",
  "maker": "小学館",
  "releaseDate": "1997/6/25",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "654",
  "ini": "け",
  "name": "ゲームで発見!!たまごっち",
  "maker": "バンダイ",
  "releaseDate": "1997/6/27",
  "price": "4500",
  "genre": "育成・シミュレーション",
  "icNo": "13"
 },
 {
  "id": "655",
  "ini": "け",
  "name": "ゲームボーイウォーズTURBO",
  "maker": "ハドソン",
  "releaseDate": "1997/6/27",
  "price": "4200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "656",
  "ini": "こ",
  "name": "合格ボーイシリーズ 高校入試でる順 中学英単語1700",
  "maker": "イマジニア",
  "releaseDate": "1997/6/27",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "657",
  "ini": "せ",
  "name": "Z会合格ボーイシリーズ Z会究極の英単語1500",
  "maker": "イマジニア",
  "releaseDate": "1997/7/11",
  "price": "2000",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "658",
  "ini": "け",
  "name": "ゲームで発見!!たまごっち ピンクなTAMAGOTCHセット",
  "maker": "バンダイ",
  "releaseDate": "1997/7/11",
  "price": "11000",
  "genre": "育成・シミュレーション",
  "icNo": "15"
 },
 {
  "id": "659",
  "ini": "す",
  "name": "スーパービーダマン ファイティングフェニックス",
  "maker": "ハドソン",
  "releaseDate": "1997/7/11",
  "price": "3980",
  "genre": "パズル・アクション",
  "icNo": "3"
 },
 {
  "id": "660",
  "ini": "そ",
  "name": "それゆけ!!キッド",
  "maker": "ケムコ",
  "releaseDate": "1997/7/18",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "661",
  "ini": "ほ",
  "name": "ポケットラブ",
  "maker": "キッド",
  "releaseDate": "1997/7/18",
  "price": "4800",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "662",
  "ini": "な",
  "name": "ナムコギャラリー vol.3",
  "maker": "ナムコ",
  "releaseDate": "1997/7/25",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "663",
  "ini": "ほ",
  "name": "ポケット麻雀",
  "maker": "ボトムアップ",
  "releaseDate": "1997/7/25",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "664",
  "ini": "こ",
  "name": "合格ボーイシリーズ 高校入試出る順 中学英熟語350",
  "maker": "イマジニア",
  "releaseDate": "1997/7/25",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "665",
  "ini": "ね",
  "name": "熱闘 ザ・キング・オブ・ファイターズ'96",
  "maker": "タカラ",
  "releaseDate": "1997/8/8",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "666",
  "ini": "ま",
  "name": "マッハGoGoGo",
  "maker": "トミー",
  "releaseDate": "1997/8/12",
  "price": "4800",
  "genre": "レース・シュミレーション",
  "icNo": "1"
 },
 {
  "id": "667",
  "ini": "て",
  "name": "ディノブリーダー",
  "maker": "J・ウイング",
  "releaseDate": "1997/8/22",
  "price": "4800",
  "genre": "育成・RPG",
  "icNo": "1"
 },
 {
  "id": "668",
  "ini": "ま",
  "name": "マネーアイドル エクスチェンジャー",
  "maker": "アテナ",
  "releaseDate": "1997/8/29",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "669",
  "ini": "こ",
  "name": "合格ボーイシリーズ 高校入試“でる順” 漢字問題の征服",
  "maker": "イマジニア",
  "releaseDate": "1997/8/29",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "670",
  "ini": "か",
  "name": "川のぬし釣り3",
  "maker": "パック・イン・ソフト",
  "releaseDate": "1997/9/19",
  "price": "3600",
  "genre": "釣り・RPG",
  "icNo": "1"
 },
 {
  "id": "671",
  "ini": "こ",
  "name": "コナミGBコレクション vol.1",
  "maker": "コナミ",
  "releaseDate": "1997/9/25",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "672",
  "ini": "こ",
  "name": "合格ボーイシリーズ 「解体英熟語」完全準拠 Z会究極の英熟語1017",
  "maker": "イマジニア",
  "releaseDate": "1997/9/26",
  "price": "2000",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "673",
  "ini": "お",
  "name": "おやつクイズ もぐもぐQ THE QUIZ GAME",
  "maker": "スターフィッシュ",
  "releaseDate": "1997/9/26",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "674",
  "ini": "み",
  "name": "ミニ4ボーイⅡ ファイナル・エボリューション",
  "maker": "J・ウイング",
  "releaseDate": "1997/9/26",
  "price": "4800",
  "genre": "レース・シュミレーション",
  "icNo": "1"
 },
 {
  "id": "675",
  "ini": "こ",
  "name": "合格ボーイシリーズ 高校入試 歴史年代あんきポイント240",
  "maker": "イマジニア",
  "releaseDate": "1997/9/26",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "676",
  "ini": "け",
  "name": "ゲームボーイギャラリー2",
  "maker": "任天堂",
  "releaseDate": "1997/9/27",
  "price": "3000",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "677",
  "ini": "こ",
  "name": "合格ボーイシリーズ 高校入試 理科あんきポイント250",
  "maker": "イマジニア",
  "releaseDate": "1997/10/1",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "678",
  "ini": "あ",
  "name": "あにまるぶりーだー",
  "maker": "J・ウイング",
  "releaseDate": "1997/10/17",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "679",
  "ini": "け",
  "name": "ゲームで発見!!たまごっち2",
  "maker": "バンダイ",
  "releaseDate": "1997/10/17",
  "price": "4286",
  "genre": "育成・シミュレーション",
  "icNo": "2"
 },
 {
  "id": "680",
  "ini": "ふ",
  "name": "プリクラポケット 不完全女子高校生マニュアル",
  "maker": "アトラス",
  "releaseDate": "1997/10/17",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "681",
  "ini": "こ",
  "name": "合格ボーイシリーズ よく使われる 英検2級レベルの会話表現333",
  "maker": "イマジニア",
  "releaseDate": "1997/10/31",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "682",
  "ini": "こ",
  "name": "コレクションポケット",
  "maker": "ナグザット",
  "releaseDate": "1997/11/21",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "683",
  "ini": "あ",
  "name": "悪魔城ドラキュラ 漆黒たる前奏曲",
  "maker": "コナミ",
  "releaseDate": "1997/11/27",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "684",
  "ini": "こ",
  "name": "合格ボーイシリーズ 「解体英語構文」完全準拠 Z会究極の英語構文285",
  "maker": "イマジニア",
  "releaseDate": "1997/11/28",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "685",
  "ini": "と",
  "name": "トランプコレクション GB",
  "maker": "ボトムアップ",
  "releaseDate": "1997/11/28",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "686",
  "ini": "め",
  "name": "メダロット（カブトバージョン）",
  "maker": "イマジニア",
  "releaseDate": "1997/11/28",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "687",
  "ini": "め",
  "name": "メダロット（クワガタバージョン）",
  "maker": "イマジニア",
  "releaseDate": "1997/11/28",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "688",
  "ini": "ふ",
  "name": "プリクラポケット2 彼氏改造大作戦",
  "maker": "アトラス",
  "releaseDate": "1997/11/29",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "689",
  "ini": "か",
  "name": "がんばれゴエモン 黒船党の謎",
  "maker": "コナミ",
  "releaseDate": "1997/12/4",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "690",
  "ini": "さ",
  "name": "サンリオうらないパーティ",
  "maker": "イマジニア",
  "releaseDate": "1997/12/5",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "691",
  "ini": "こ",
  "name": "コナミGBコレクション vol.2",
  "maker": "コナミ",
  "releaseDate": "1997/12/11",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "692",
  "ini": "ほ",
  "name": "ポケットボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "1997/12/12",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "693",
  "ini": "ち",
  "name": "超魔神英雄伝ワタル まぜっこモンスター",
  "maker": "バンプレスト",
  "releaseDate": "1997/12/12",
  "price": "3800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "694",
  "ini": "か",
  "name": "カラムー町の大事件",
  "maker": "スターフィッシュ",
  "releaseDate": "1997/12/19",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "695",
  "ini": "す",
  "name": "スタースイープ",
  "maker": "アクセラ",
  "releaseDate": "1997/12/19",
  "price": "3800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "696",
  "ini": "は",
  "name": "バックス・バニー コレクション",
  "maker": "ケムコ",
  "releaseDate": "1997/12/19",
  "price": "3480",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "697",
  "ini": "ほ",
  "name": "牧場物語 GB",
  "maker": "パック・イン・ソフト",
  "releaseDate": "1997/12/19",
  "price": "3980",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "698",
  "ini": "ほ",
  "name": "ポケット漢字郎",
  "maker": "新学社",
  "releaseDate": "1998/1/10",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "699",
  "ini": "け",
  "name": "ゲームで発見!!たまごっち オスっちとメスっち",
  "maker": "バンダイ",
  "releaseDate": "1998/1/15",
  "price": "4286",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "700",
  "ini": "に",
  "name": "ニューチェスマスター",
  "maker": "アルトロン",
  "releaseDate": "1998/1/30",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "701",
  "ini": "こ",
  "name": "合格ボーイシリーズ 学研 小学校高学年レベル 入試に出た要点 ランク順 慣用句・ことわざ210",
  "maker": "イマジニア",
  "releaseDate": "1998/1/30",
  "price": "1990",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "702",
  "ini": "こ",
  "name": "合格ボーイシリーズ 学研 小学校高学年レベル 入試に出た要点 ランク順 四字熟語288",
  "maker": "イマジニア",
  "releaseDate": "1998/1/30",
  "price": "1990",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "703",
  "ini": "こ",
  "name": "コナミGBコレクション vol.3",
  "maker": "コナミ",
  "releaseDate": "1998/2/19",
  "price": "3980",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "704",
  "ini": "ね",
  "name": "NAVY BLUE 98",
  "maker": "ショウエイシステム",
  "releaseDate": "1998/2/20",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "705",
  "ini": "ほ",
  "name": "ポケットカメラ",
  "maker": "任天堂",
  "releaseDate": "1998/2/21",
  "price": "5775",
  "genre": "その他",
  "icNo": "17"
 },
 {
  "id": "706",
  "ini": "ね",
  "name": "ネクタリス GB",
  "maker": "ハドソン",
  "releaseDate": "1998/2/27",
  "price": "4200",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "707",
  "ini": "ほ",
  "name": "ポケットキョロちゃん",
  "maker": "トミー",
  "releaseDate": "1998/2/27",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "708",
  "ini": "た",
  "name": "大貝獣物語 ザ・ミラクル・オブ・ザ・ゾーン",
  "maker": "ハドソン",
  "releaseDate": "1998/3/5",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "709",
  "ini": "も",
  "name": "もんすたあ☆レース",
  "maker": "光栄",
  "releaseDate": "1998/3/6",
  "price": "3980",
  "genre": "RPG・レース",
  "icNo": "1"
 },
 {
  "id": "710",
  "ini": "い",
  "name": "頭文字D外伝",
  "maker": "講談社",
  "releaseDate": "1998/3/6",
  "price": "3980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "711",
  "ini": "は",
  "name": "パチンコCR大工の源さん GB",
  "maker": "日本テレネット",
  "releaseDate": "1998/3/13",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "712",
  "ini": "ほ",
  "name": "ポケットラブ2",
  "maker": "キッド",
  "releaseDate": "1998/3/13",
  "price": "4800",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "713",
  "ini": "こ",
  "name": "コナミGBコレクション vol.4",
  "maker": "コナミ",
  "releaseDate": "1998/3/19",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "714",
  "ini": "こ",
  "name": "合格ボーイシリーズ Z会 <例文で覚える>中学英単語1132",
  "maker": "イマジニア",
  "releaseDate": "1998/3/20",
  "price": "2000",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "715",
  "ini": "か",
  "name": "カンヅメモンスター",
  "maker": "アイマックス",
  "releaseDate": "1998/3/20",
  "price": "4500",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "716",
  "ini": "と",
  "name": "ドラえもんカート",
  "maker": "エポック社",
  "releaseDate": "1998/3/20",
  "price": "3980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "717",
  "ini": "め",
  "name": "メダロット パーツコレクション",
  "maker": "イマジニア",
  "releaseDate": "1998/3/20",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "718",
  "ini": "こ",
  "name": "合格ボーイシリーズ 山川 一問一答 世界史B用語問題集",
  "maker": "イマジニア",
  "releaseDate": "1998/3/20",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "719",
  "ini": "こ",
  "name": "ゴッドメディスン（復刻版）",
  "maker": "コナミ",
  "releaseDate": "1998/3/26",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "720",
  "ini": "は",
  "name": "パワプロ GB",
  "maker": "コナミ",
  "releaseDate": "1998/3/26",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "721",
  "ini": "ね",
  "name": "熱闘 リアルバウト餓狼伝説スペシャル",
  "maker": "タカラ",
  "releaseDate": "1998/3/27",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "722",
  "ini": "は",
  "name": "パズルボブル GB",
  "maker": "タイトー",
  "releaseDate": "1998/4/10",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "723",
  "ini": "こ",
  "name": "合格ボーイシリーズ 桐原書店 頻出英文法・語法問題1000",
  "maker": "イマジニア",
  "releaseDate": "1998/4/22",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "724",
  "ini": "こ",
  "name": "合格ボーイシリーズ 山川 一問一答 新課程 日本史B用語問題集",
  "maker": "イマジニア",
  "releaseDate": "1998/4/22",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "725",
  "ini": "ほ",
  "name": "ポケットバスフィッシング",
  "maker": "ボトムアップ",
  "releaseDate": "1998/4/24",
  "price": "3980",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "726",
  "ini": "せ",
  "name": "SELECTION Ⅰ&Ⅱ 選ばれし者&暗黒の封印",
  "maker": "ケムコ",
  "releaseDate": "1998/5/1",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "727",
  "ini": "あ",
  "name": "あにまるぶりーだー2",
  "maker": "J・ウイング",
  "releaseDate": "1998/5/15",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "728",
  "ini": "に",
  "name": "日本代表チーム 栄光のイレブン",
  "maker": "トミー",
  "releaseDate": "1998/5/22",
  "price": "3580",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "729",
  "ini": "め",
  "name": "メダロット パーツコレクション2",
  "maker": "イマジニア",
  "releaseDate": "1998/5/29",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "730",
  "ini": "こ",
  "name": "合格ボーイシリーズ 学研 小学校高学年レベル 入試に出た要点 ランク順 歴史512",
  "maker": "イマジニア",
  "releaseDate": "1998/5/29",
  "price": "1990",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "731",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ6 学習漢字マスター 1006",
  "maker": "小学館",
  "releaseDate": "1998/6/1",
  "price": "2850",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "732",
  "ini": "わ",
  "name": "ワールドサッカー GB",
  "maker": "コナミ",
  "releaseDate": "1998/6/4",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "733",
  "ini": "て",
  "name": "ディノブリーダー2",
  "maker": "J・ウイング",
  "releaseDate": "1998/6/5",
  "price": "4800",
  "genre": "育成・RPG",
  "icNo": "1"
 },
 {
  "id": "734",
  "ini": "み",
  "name": "ミニ四駆GB Let's&Go!! オールスターバトルMAX",
  "maker": "アスキー",
  "releaseDate": "1998/6/19",
  "price": "4800",
  "genre": "レース・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "735",
  "ini": "に",
  "name": "忍たま乱太郎GB 絵あわせチャレンジパズル",
  "maker": "カルチャーブレーン",
  "releaseDate": "1998/6/19",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "736",
  "ini": "こ",
  "name": "合格ボーイシリーズ シカクいアタマをマルくする。数字で遊ぼう算数編",
  "maker": "イマジニア",
  "releaseDate": "1998/6/26",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "737",
  "ini": "い",
  "name": "いつでも！にゃんとワンダフル",
  "maker": "バンプレスト",
  "releaseDate": "1998/6/26",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "738",
  "ini": "に",
  "name": "日刊べるとも倶楽部",
  "maker": "アイマックス",
  "releaseDate": "1998/6/26",
  "price": "4500",
  "genre": "恋愛・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "739",
  "ini": "し",
  "name": "Jリーグサポーターサッカー 日本代表フランスでがんばれ！",
  "maker": "J・ウイング",
  "releaseDate": "1998/6/26",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "740",
  "ini": "こ",
  "name": "高気圧ボーイ",
  "maker": "コナミ",
  "releaseDate": "1998/7/2",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "741",
  "ini": "こ",
  "name": "合格ボーイシリーズ 99年度版 英単語センター1500",
  "maker": "イマジニア",
  "releaseDate": "1998/7/10",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "742",
  "ini": "こ",
  "name": "GO！GO！ヒッチハイク",
  "maker": "J・ウイング",
  "releaseDate": "1998/7/10",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "743",
  "ini": "か",
  "name": "海のぬし釣り2",
  "maker": "パック・イン・ソフト",
  "releaseDate": "1998/7/10",
  "price": "3600",
  "genre": "釣り・RPG",
  "icNo": "1"
 },
 {
  "id": "744",
  "ini": "か",
  "name": "化石創世リボーン",
  "maker": "スターフィッシュ",
  "releaseDate": "1998/7/17",
  "price": "3980",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "745",
  "ini": "く",
  "name": "グランダー武蔵RV",
  "maker": "バンダイ",
  "releaseDate": "1998/7/24",
  "price": "5800",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "746",
  "ini": "と",
  "name": "東京ディズニーランド ファンタジーツアー",
  "maker": "トミー",
  "releaseDate": "1998/7/24",
  "price": "4200",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "747",
  "ini": "は",
  "name": "爆釣列伝 翔 ハイパーバスフィッシング",
  "maker": "スターフィッシュ",
  "releaseDate": "1998/7/24",
  "price": "3980",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "748",
  "ini": "き",
  "name": "魚群探知機 ポケットソナー",
  "maker": "バンダイ",
  "releaseDate": "1998/7/24",
  "price": "14800",
  "genre": "その他",
  "icNo": "16"
 },
 {
  "id": "749",
  "ini": "ふ",
  "name": "ブレインドレイン",
  "maker": "アクレイムジャパン",
  "releaseDate": "1998/7/31",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "750",
  "ini": "は",
  "name": "早押しクイズ 王座決定戦",
  "maker": "ジャレコ",
  "releaseDate": "1998/7/31",
  "price": "3980",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "751",
  "ini": "も",
  "name": "桃太郎電鉄jr. 全国ラーメンめぐりの旅",
  "maker": "ハドソン",
  "releaseDate": "1998/7/31",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "752",
  "ini": "し",
  "name": "上海 Pocket",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1998/8/6",
  "price": "3980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "753",
  "ini": "て",
  "name": "テュロック バイオノザウルスの戦い",
  "maker": "スターフィッシュ",
  "releaseDate": "1998/8/7",
  "price": "3980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "754",
  "ini": "ち",
  "name": "超魔神英雄伝ワタル まぜっこモンスター2",
  "maker": "バンプレスト",
  "releaseDate": "1998/8/7",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "755",
  "ini": "め",
  "name": "名探偵コナン 疑惑の豪華列車",
  "maker": "バンダイ",
  "releaseDate": "1998/8/7",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "756",
  "ini": "ほ",
  "name": "ポケットファミリーGB",
  "maker": "ハドソン",
  "releaseDate": "1998/8/9",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "757",
  "ini": "こ",
  "name": "昆虫博士",
  "maker": "J・ウイング",
  "releaseDate": "1998/8/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "758",
  "ini": "ほ",
  "name": "ポケット将棋",
  "maker": "ボトムアップ",
  "releaseDate": "1998/9/11",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "759",
  "ini": "も",
  "name": "モータルコンバット&モータルコンバット2",
  "maker": "アクレイムジャパン",
  "releaseDate": "1998/9/11",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "760",
  "ini": "つ",
  "name": "釣り先生",
  "maker": "J・ウイング",
  "releaseDate": "1998/9/11",
  "price": "4800",
  "genre": "釣り・RPG",
  "icNo": "1"
 },
 {
  "id": "761",
  "ini": "ほ",
  "name": "ポケットモンスター　ピカチュウ",
  "maker": "任天堂",
  "releaseDate": "1998/9/12",
  "price": "3000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "762",
  "ini": "ち",
  "name": "超速スピナー",
  "maker": "ハドソン",
  "releaseDate": "1998/9/18",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "763",
  "ini": "と",
  "name": "ドラゴンクエストモンスターズ　テリーのワンダーランド",
  "maker": "エニックス",
  "releaseDate": "1998/9/25",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "764",
  "ini": "ほ",
  "name": "ポケットゴルフ",
  "maker": "ボトムアップ",
  "releaseDate": "1998/9/25",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "765",
  "ini": "も",
  "name": "もんすたあ☆レース おかわり",
  "maker": "コーエー",
  "releaseDate": "1998/10/2",
  "price": "3980",
  "genre": "RPG・レース",
  "icNo": "1"
 },
 {
  "id": "766",
  "ini": "は",
  "name": "爆釣 リトリーブマスター",
  "maker": "コナミ",
  "releaseDate": "1998/10/15",
  "price": "3980",
  "genre": "釣り・RPG",
  "icNo": "1"
 },
 {
  "id": "767",
  "ini": "し",
  "name": "新競馬貴族 ポケットジョッキー",
  "maker": "キングレコード",
  "releaseDate": "1998/10/16",
  "price": "5800",
  "genre": "競馬予想・育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "768",
  "ini": "く",
  "name": "グローカルヘキサイト",
  "maker": "NECインターチャネル",
  "releaseDate": "1998/10/21",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "769",
  "ini": "て",
  "name": "テトリスデラックス",
  "maker": "任天堂",
  "releaseDate": "1998/10/21",
  "price": "3500",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "770",
  "ini": "わ",
  "name": "ワリオランド2 盗まれた財宝",
  "maker": "任天堂",
  "releaseDate": "1998/10/21",
  "price": "3500",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "771",
  "ini": "ほ",
  "name": "ポケットボウリング",
  "maker": "アテナ",
  "releaseDate": "1998/10/23",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "772",
  "ini": "ほ",
  "name": "ポケット電車",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1998/10/30",
  "price": "3980",
  "genre": "電車・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "773",
  "ini": "ほ",
  "name": "本格将棋 将棋王",
  "maker": "童",
  "releaseDate": "1998/11/13",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "774",
  "ini": "け",
  "name": "ゲームボーイウォーズ2",
  "maker": "ハドソン",
  "releaseDate": "1998/11/20",
  "price": "4200",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "775",
  "ini": "さ",
  "name": "サンリオタイムネット 過去編",
  "maker": "イマジニア",
  "releaseDate": "1998/11/27",
  "price": "3500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "776",
  "ini": "さ",
  "name": "サンリオタイムネット 未来編",
  "maker": "イマジニア",
  "releaseDate": "1998/11/27",
  "price": "3500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "777",
  "ini": "す",
  "name": "スーパーブラックバス ポケット3",
  "maker": "スターフィッシュ",
  "releaseDate": "1998/11/27",
  "price": "3980",
  "genre": "釣り",
  "icNo": "3"
 },
 {
  "id": "778",
  "ini": "ほ",
  "name": "ぽけっとぷよぷよSUN",
  "maker": "コンパイル",
  "releaseDate": "1998/11/27",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "779",
  "ini": "と",
  "name": "ドラえもんのGAMEBOYであそぼうよ デラックス10",
  "maker": "エポック社",
  "releaseDate": "1998/11/27",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "780",
  "ini": "け",
  "name": "激闘パワーモデラー",
  "maker": "カプコン",
  "releaseDate": "1998/11/27",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "781",
  "ini": "ろ",
  "name": "ロボットポンコッツ（星バージョン）",
  "maker": "ハドソン",
  "releaseDate": "1998/12/4",
  "price": "3980",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "782",
  "ini": "ろ",
  "name": "ロボットポンコッツ（太陽バージョン）",
  "maker": "ハドソン",
  "releaseDate": "1998/12/4",
  "price": "3980",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "783",
  "ini": "か",
  "name": "カラムー町は大さわぎ！ポリンキーズとおかしな仲間たち",
  "maker": "スターフィッシュ",
  "releaseDate": "1998/12/11",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "3"
 },
 {
  "id": "784",
  "ini": "け",
  "name": "ゲームボーイ モノポリー",
  "maker": "ハズブロージャパン",
  "releaseDate": "1998/12/11",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "785",
  "ini": "は",
  "name": "バーコード対戦 バーディガン",
  "maker": "タム",
  "releaseDate": "1998/12/11",
  "price": "5500",
  "genre": "育成・シュミレーション",
  "icNo": "3"
 },
 {
  "id": "786",
  "ini": "ろ",
  "name": "ロードス島戦記 英雄騎士伝 GB",
  "maker": "トミー",
  "releaseDate": "1998/12/11",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "787",
  "ini": "せ",
  "name": "ゼルダの伝説 夢をみる島DX",
  "maker": "任天堂",
  "releaseDate": "1998/12/12",
  "price": "3500",
  "genre": "アクション・RPG",
  "icNo": "3"
 },
 {
  "id": "788",
  "ini": "ふ",
  "name": "フェアリーキティの開運辞典 妖精の国の占い修行",
  "maker": "イマジニア",
  "releaseDate": "1998/12/12",
  "price": "3980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "789",
  "ini": "ゆ",
  "name": "遊☆戯☆王 デュエルモンスターズ",
  "maker": "コナミ",
  "releaseDate": "1998/12/17",
  "price": "4300",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "790",
  "ini": "ほ",
  "name": "ポケットカラー ブロック",
  "maker": "ボトムアップ",
  "releaseDate": "1998/12/18",
  "price": "3980",
  "genre": "ブロック崩し",
  "icNo": "3"
 },
 {
  "id": "791",
  "ini": "さ",
  "name": "ザ・首都高レーシング",
  "maker": "ポニーキャニオン",
  "releaseDate": "1998/12/18",
  "price": "3980",
  "genre": "レース",
  "icNo": "3"
 },
 {
  "id": "792",
  "ini": "ほ",
  "name": "ポケモンカード GB",
  "maker": "任天堂",
  "releaseDate": "1998/12/18",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "793",
  "ini": "ふ",
  "name": "プリクラポケット3 タレントデビュー大作戦",
  "maker": "アトラス",
  "releaseDate": "1998/12/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "794",
  "ini": "ま",
  "name": "麻雀クエスト",
  "maker": "J・ウイング",
  "releaseDate": "1998/12/23",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "795",
  "ini": "ほ",
  "name": "ボンバーマンクエスト",
  "maker": "ハドソン",
  "releaseDate": "1998/12/24",
  "price": "3800",
  "genre": "アクション・RPG",
  "icNo": "3"
 },
 {
  "id": "796",
  "ini": "こ",
  "name": "合格ボーイシリーズ 「読み解き古文単語」準拠 Z会<例文で覚える> 究極の古文単語 古文知識・文法基礎入り",
  "maker": "イマジニア",
  "releaseDate": "1998/12/25",
  "price": "2000",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "797",
  "ini": "こ",
  "name": "小島武夫・灘麻太郎の実践麻雀教室",
  "maker": "ギャップス",
  "releaseDate": "1998/12/25",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "798",
  "ini": "は",
  "name": "バーディガン ライトバージョン 限定スペシャルBOX!",
  "maker": "タムソフト",
  "releaseDate": "1999/1/1",
  "price": "",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "799",
  "ini": "か",
  "name": "がんばれゴエモン 天狗党の逆襲",
  "maker": "コナミ",
  "releaseDate": "1999/1/14",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "800",
  "ini": "は",
  "name": "Pachinko Data Card ちょ～あたる君",
  "maker": "BOSSコミュニケーションズ",
  "releaseDate": "1999/1/28",
  "price": "4980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "801",
  "ini": "ひ",
  "name": "Bビーダマン爆外伝 ビクトリーへのみち",
  "maker": "メディアファクトリー",
  "releaseDate": "1999/1/29",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "802",
  "ini": "は",
  "name": "バックス・バニー クレイジーキャッスル3",
  "maker": "ケムコ",
  "releaseDate": "1999/1/29",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "803",
  "ini": "と",
  "name": "ときめきメモリアルPOCKET カルチャー編 木漏れ日のメロディ",
  "maker": "コナミ",
  "releaseDate": "1999/2/11",
  "price": "4300",
  "genre": "恋愛・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "804",
  "ini": "と",
  "name": "ときめきメモリアルPOCKET スポーツ編 校庭のフォトグラフ",
  "maker": "コナミ",
  "releaseDate": "1999/2/11",
  "price": "4300",
  "genre": "恋愛・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "805",
  "ini": "か",
  "name": "化石創世リボーンⅡ モンスターディガー",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/2/12",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "806",
  "ini": "ほ",
  "name": "本格四人打麻雀 麻雀王",
  "maker": "童",
  "releaseDate": "1999/2/19",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "807",
  "ini": "は",
  "name": "ハムスターパラダイス",
  "maker": "アトラス",
  "releaseDate": "1999/2/26",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "808",
  "ini": "ほ",
  "name": "ポヨンのダンジョンルーム",
  "maker": "ハドソン",
  "releaseDate": "1999/2/26",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "809",
  "ini": "ろ",
  "name": "ROX 6=Six",
  "maker": "アルトロン",
  "releaseDate": "1999/3/5",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "810",
  "ini": "ひ",
  "name": "ビートマニア GB",
  "maker": "コナミ",
  "releaseDate": "1999/3/11",
  "price": "4300",
  "genre": "リズムゲーム",
  "icNo": "3"
 },
 {
  "id": "811",
  "ini": "お",
  "name": "おはスタ やまちゃん&レイモンド",
  "maker": "エポック社",
  "releaseDate": "1999/3/12",
  "price": "3800",
  "genre": "アクション・パズル",
  "icNo": "3"
 },
 {
  "id": "812",
  "ini": "と",
  "name": "ドラえもんカート2",
  "maker": "エポック社",
  "releaseDate": "1999/3/12",
  "price": "3980",
  "genre": "レース",
  "icNo": "3"
 },
 {
  "id": "813",
  "ini": "も",
  "name": "もんすたあ☆レース2",
  "maker": "コーエー",
  "releaseDate": "1999/3/12",
  "price": "3980",
  "genre": "RPG・レース",
  "icNo": "3"
 },
 {
  "id": "814",
  "ini": "お",
  "name": "王ドロボウJING エンジェル",
  "maker": "メサイヤ",
  "releaseDate": "1999/3/12",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "815",
  "ini": "お",
  "name": "王ドロボウJING デビル",
  "maker": "メサイヤ",
  "releaseDate": "1999/3/12",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "816",
  "ini": "こ",
  "name": "甲子園ポケット",
  "maker": "魔法",
  "releaseDate": "1999/3/12",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "817",
  "ini": "こ",
  "name": "合格ボーイシリーズ シカクいアタマをマルくする。算数バトル編",
  "maker": "イマジニア",
  "releaseDate": "1999/3/12",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "818",
  "ini": "ふ",
  "name": "プロ麻雀 極 GBⅡ",
  "maker": "アテナ",
  "releaseDate": "1999/3/19",
  "price": "4200",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "819",
  "ini": "け",
  "name": "決闘ビーストウォーズ ビースト戦士最強決定戦",
  "maker": "タカラ",
  "releaseDate": "1999/3/19",
  "price": "3680",
  "genre": "格闘・アクション",
  "icNo": "3"
 },
 {
  "id": "820",
  "ini": "め",
  "name": "女神転生外伝 ラストバイブル（カラー対応版）",
  "maker": "アトラス",
  "releaseDate": "1999/3/19",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "821",
  "ini": "し",
  "name": "将棋2",
  "maker": "ポニーキャニオン",
  "releaseDate": "1999/3/19",
  "price": "3480",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "822",
  "ini": "た",
  "name": "大貝獣物語 ザ・ミラクル・オブ・ザ・ゾーンⅡ",
  "maker": "ハドソン",
  "releaseDate": "1999/3/19",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "823",
  "ini": "く",
  "name": "QUI QUI",
  "maker": "魔法",
  "releaseDate": "1999/3/26",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "824",
  "ini": "は",
  "name": "バーガーバーガーポケット",
  "maker": "ギャップス",
  "releaseDate": "1999/3/26",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "825",
  "ini": "は",
  "name": "パワプロクンポケット",
  "maker": "コナミ",
  "releaseDate": "1999/4/1",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "826",
  "ini": "ほ",
  "name": "ポケット電車2",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1999/4/2",
  "price": "3980",
  "genre": "電車・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "827",
  "ini": "け",
  "name": "ゲームボーイギャラリー3",
  "maker": "任天堂",
  "releaseDate": "1999/4/8",
  "price": "3500",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "828",
  "ini": "か",
  "name": "加藤一二三 九段の 将棋教室",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/4/9",
  "price": "3200",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "829",
  "ini": "の",
  "name": "信長の野望 ゲームボーイ版2",
  "maker": "コーエー",
  "releaseDate": "1999/4/9",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "830",
  "ini": "ほ",
  "name": "ポケモンピンボール",
  "maker": "任天堂",
  "releaseDate": "1999/4/14",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "12"
 },
 {
  "id": "831",
  "ini": "ち",
  "name": "チェックメイト",
  "maker": "アルトロン",
  "releaseDate": "1999/4/16",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "832",
  "ini": "め",
  "name": "女神転生外伝 ラストバイブルⅡ（カラー対応版）",
  "maker": "アトラス",
  "releaseDate": "1999/4/16",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "833",
  "ini": "と",
  "name": "トップギア・ポケット",
  "maker": "ケムコ",
  "releaseDate": "1999/4/23",
  "price": "4500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "834",
  "ini": "こ",
  "name": "ゴルフ DE おはスタ",
  "maker": "エポック社",
  "releaseDate": "1999/4/23",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "835",
  "ini": "ふ",
  "name": "プチカラット",
  "maker": "タイトー",
  "releaseDate": "1999/4/23",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "836",
  "ini": "り",
  "name": "リアルプロ野球！セントラルリーグ編",
  "maker": "ナツメ",
  "releaseDate": "1999/4/23",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "837",
  "ini": "り",
  "name": "リアルプロ野球！パシフィックリーグ編",
  "maker": "ナツメ",
  "releaseDate": "1999/4/23",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "838",
  "ini": "し",
  "name": "人生ゲーム 友達たくさんつくろうよ！",
  "maker": "タカラ",
  "releaseDate": "1999/4/23",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "839",
  "ini": "て",
  "name": "ディノブリーダー3 ガイア復活",
  "maker": "J・ウイング",
  "releaseDate": "1999/4/28",
  "price": "4800",
  "genre": "育成・RPG",
  "icNo": "3"
 },
 {
  "id": "840",
  "ini": "は",
  "name": "ハローキティのマジカルミュージアム",
  "maker": "イマジニア",
  "releaseDate": "1999/4/28",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "841",
  "ini": "ほ",
  "name": "ポケットGIステイブル",
  "maker": "コナミ",
  "releaseDate": "1999/4/28",
  "price": "4500",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "842",
  "ini": "は",
  "name": "花さか天使テンテンくんのビートブレイカー",
  "maker": "コナミ",
  "releaseDate": "1999/4/28",
  "price": "4300",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "843",
  "ini": "は",
  "name": "パチパチパチス郎 ニューパルサー編",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/4/28",
  "price": "3980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "844",
  "ini": "え",
  "name": "SD飛龍の拳EX",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/4/30",
  "price": "3900",
  "genre": "格闘・アクション",
  "icNo": "3"
 },
 {
  "id": "845",
  "ini": "い",
  "name": "IT's a ワールドラリー",
  "maker": "コナミ",
  "releaseDate": "1999/5/13",
  "price": "4300",
  "genre": "レース",
  "icNo": "3"
 },
 {
  "id": "846",
  "ini": "か",
  "name": "カードキャプターさくら いつもさくらちゃんといっしょ",
  "maker": "エム・ティー・オー",
  "releaseDate": "1999/5/15",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "847",
  "ini": "か",
  "name": "カンヅメモンスターパフェ",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/5/27",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "848",
  "ini": "ひ",
  "name": "ピットフォール GB",
  "maker": "ポニーキャニオン",
  "releaseDate": "1999/5/28",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "849",
  "ini": "か",
  "name": "学級王ヤマザキ ゲームボーイ版",
  "maker": "コーエー",
  "releaseDate": "1999/5/29",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "850",
  "ini": "か",
  "name": "漢字BOY",
  "maker": "J・ウイング",
  "releaseDate": "1999/6/3",
  "price": "4800",
  "genre": "学習",
  "icNo": "3"
 },
 {
  "id": "851",
  "ini": "は",
  "name": "パチンコ CRモーレツ原始人T",
  "maker": "ヘクト",
  "releaseDate": "1999/6/4",
  "price": "3980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "852",
  "ini": "る",
  "name": "ルーカのパズルで大冒険！",
  "maker": "ヒューマン",
  "releaseDate": "1999/6/11",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "853",
  "ini": "ほ",
  "name": "ポケット花札",
  "maker": "ボトムアップ",
  "releaseDate": "1999/6/11",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "854",
  "ini": "さ",
  "name": "サバイバルキッズ 孤島の冒険者",
  "maker": "コナミ",
  "releaseDate": "1999/6/17",
  "price": "4300",
  "genre": "アクション・RPG",
  "icNo": "3"
 },
 {
  "id": "855",
  "ini": "あ",
  "name": "あにまるぶりーだー3",
  "maker": "J・ウイング",
  "releaseDate": "1999/6/24",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "856",
  "ini": "わ",
  "name": "ワールドサッカーGB2",
  "maker": "コナミ",
  "releaseDate": "1999/6/24",
  "price": "4300",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "857",
  "ini": "さ",
  "name": "坂田吾朗 九段の連珠教室",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/6/25",
  "price": "3200",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "858",
  "ini": "は",
  "name": "ハイパーオリンピックシリーズ　トラック&フィールドGB",
  "maker": "コナミ",
  "releaseDate": "1999/7/1",
  "price": "4300",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "859",
  "ini": "ゆ",
  "name": "遊☆戯☆王 デュエルモンスターズⅡ 闇界決闘記",
  "maker": "コナミ",
  "releaseDate": "1999/7/8",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "860",
  "ini": "ふ",
  "name": "プロ麻雀 兵 GB",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/7/9",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "861",
  "ini": "こ",
  "name": "ゴルフ王",
  "maker": "デジタルキッズ",
  "releaseDate": "1999/7/16",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "862",
  "ini": "か",
  "name": "川のぬし釣り4",
  "maker": "パック・イン・ソフト",
  "releaseDate": "1999/7/16",
  "price": "4200",
  "genre": "釣り・RPG",
  "icNo": "2"
 },
 {
  "id": "863",
  "ini": "こ",
  "name": "合格ボーイシリーズ シカクいアタマをマルくする。社会バトル編",
  "maker": "イマジニア",
  "releaseDate": "1999/7/16",
  "price": "2480",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "864",
  "ini": "は",
  "name": "ハローキティのビーズ工房",
  "maker": "イマジニア",
  "releaseDate": "1999/7/17",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "865",
  "ini": "す",
  "name": "スパイVSスパイ",
  "maker": "ケムコ",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "866",
  "ini": "ほ",
  "name": "ポケットルアーボーイ",
  "maker": "キングレコード",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "釣り・RPG",
  "icNo": "6"
 },
 {
  "id": "867",
  "ini": "し",
  "name": "次世代ベーゴマバトル ベイブレード",
  "maker": "ハドソン",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "868",
  "ini": "ふ",
  "name": "無頼戦士 カラー",
  "maker": "キッド",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "シューティング",
  "icNo": "6"
 },
 {
  "id": "869",
  "ini": "と",
  "name": "ドラえもん あるけあるけ ラビリンス",
  "maker": "エポック社",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "870",
  "ini": "め",
  "name": "メダロット2（カブトバージョン）",
  "maker": "イマジニア",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "871",
  "ini": "め",
  "name": "メダロット2（クワガタバージョン）",
  "maker": "イマジニア",
  "releaseDate": "1999/7/23",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "872",
  "ini": "こ",
  "name": "昆虫博士2",
  "maker": "J・ウイング",
  "releaseDate": "1999/7/23",
  "price": "4800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "873",
  "ini": "つ",
  "name": "釣り先生2",
  "maker": "J・ウイング",
  "releaseDate": "1999/7/23",
  "price": "4800",
  "genre": "釣り・RPG",
  "icNo": "3"
 },
 {
  "id": "874",
  "ini": "し",
  "name": "ジェムジェムモンスター",
  "maker": "キッド",
  "releaseDate": "1999/7/30",
  "price": "3980",
  "genre": "育成・RPG",
  "icNo": "3"
 },
 {
  "id": "875",
  "ini": "ち",
  "name": "チョロQ ハイパーカスタマブルGB",
  "maker": "タカラ",
  "releaseDate": "1999/7/30",
  "price": "3980",
  "genre": "アドベンチャー・レース",
  "icNo": "3"
 },
 {
  "id": "876",
  "ini": "さ",
  "name": "三国志 ゲームボーイ版2",
  "maker": "コーエー",
  "releaseDate": "1999/7/30",
  "price": "4800",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "877",
  "ini": "あ",
  "name": "アザーライフ アザードリームスGB",
  "maker": "コナミ",
  "releaseDate": "1999/8/5",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "878",
  "ini": "ほ",
  "name": "ポケットファミリーGB2",
  "maker": "ハドソン",
  "releaseDate": "1999/8/6",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "879",
  "ini": "ほ",
  "name": "牧場物語GB2",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "1999/8/6",
  "price": "3980",
  "genre": "経営・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "880",
  "ini": "ま",
  "name": "マリオゴルフ GB",
  "maker": "任天堂",
  "releaseDate": "1999/8/10",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "881",
  "ini": "し",
  "name": "Jリーグエキサイトステージ GB",
  "maker": "エポック社",
  "releaseDate": "1999/8/13",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "882",
  "ini": "け",
  "name": "Get'虫倶楽部 みんなの昆虫大図鑑",
  "maker": "ジャレコ",
  "releaseDate": "1999/8/13",
  "price": "4400",
  "genre": "その他",
  "icNo": "2"
 },
 {
  "id": "883",
  "ini": "し",
  "name": "シャドウゲイト リターン",
  "maker": "ケムコ",
  "releaseDate": "1999/8/13",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "884",
  "ini": "ふ",
  "name": "ぷよぷよ外伝 ぷよウォーズ",
  "maker": "コンパイル",
  "releaseDate": "1999/8/27",
  "price": "3980",
  "genre": "シミュレーション・RPG",
  "icNo": "3"
 },
 {
  "id": "885",
  "ini": "は",
  "name": "バックギャモン",
  "maker": "アルトロン",
  "releaseDate": "1999/8/27",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "886",
  "ini": "き",
  "name": "きせかえ物語",
  "maker": "パック・イン・ソフト",
  "releaseDate": "1999/9/3",
  "price": "3800",
  "genre": "育成・シュミレーション",
  "icNo": "3"
 },
 {
  "id": "887",
  "ini": "く",
  "name": "ぐるぐるガラクターズ",
  "maker": "アトラス",
  "releaseDate": "1999/9/10",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "888",
  "ini": "て",
  "name": "テュロック2  時空戦士",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/9/10",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "889",
  "ini": "か",
  "name": "かわいいペットショップ物語",
  "maker": "タイトー",
  "releaseDate": "1999/9/23",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "890",
  "ini": "と",
  "name": "ドラゴンクエストⅠ・Ⅱ",
  "maker": "エニックス",
  "releaseDate": "1999/9/23",
  "price": "4900",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "891",
  "ini": "こ",
  "name": "合格ボーイシリーズ シカクいアタマをマルくする。国語バトル編",
  "maker": "イマジニア",
  "releaseDate": "1999/9/24",
  "price": "2680",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "892",
  "ini": "ふ",
  "name": "フロントロウ",
  "maker": "キッド",
  "releaseDate": "1999/10/1",
  "price": "3980",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "893",
  "ini": "す",
  "name": "スーパーロボット大戦 リンクバトラー",
  "maker": "バンプレスト",
  "releaseDate": "1999/10/1",
  "price": "4500",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "894",
  "ini": "す",
  "name": "スーパーリアルフィッシング",
  "maker": "ボトムアップ",
  "releaseDate": "1999/10/8",
  "price": "4500",
  "genre": "釣り",
  "icNo": "11"
 },
 {
  "id": "895",
  "ini": "お",
  "name": "オセロミレニアム",
  "maker": "ツクダオリジナル",
  "releaseDate": "1999/10/8",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "896",
  "ini": "か",
  "name": "格闘料理伝説ビストロレシピ 激闘フードバトル編",
  "maker": "バンプレスト",
  "releaseDate": "1999/10/8",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "897",
  "ini": "ふ",
  "name": "V-RALLY チャンピオンシップエディション",
  "maker": "スパイク",
  "releaseDate": "1999/10/14",
  "price": "3980",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "898",
  "ini": "て",
  "name": "ディジャブⅠ&Ⅱ",
  "maker": "ケムコ",
  "releaseDate": "1999/10/15",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "899",
  "ini": "ほ",
  "name": "ポケットGT",
  "maker": "エム・ティー・オー",
  "releaseDate": "1999/10/15",
  "price": "3980",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "900",
  "ini": "し",
  "name": "シルバニアファミリー おとぎの国のペンダント",
  "maker": "エポック社",
  "releaseDate": "1999/10/15",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "901",
  "ini": "し",
  "name": "銃鋼戦記バレットバトラー",
  "maker": "コナミ",
  "releaseDate": "1999/10/21",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "902",
  "ini": "く",
  "name": "クイックスアドベンチャー",
  "maker": "タイトー",
  "releaseDate": "1999/10/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "903",
  "ini": "や",
  "name": "夜光虫 GB",
  "maker": "アテナ",
  "releaseDate": "1999/10/22",
  "price": "3800",
  "genre": "サウンドノベル",
  "icNo": "6"
 },
 {
  "id": "904",
  "ini": "あ",
  "name": "アクアライフ",
  "maker": "タム",
  "releaseDate": "1999/10/22",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "905",
  "ini": "う",
  "name": "ウィザードリィ エンパイア",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/10/29",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "906",
  "ini": "う",
  "name": "ウェットリス GB",
  "maker": "イマジニア",
  "releaseDate": "1999/10/29",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "907",
  "ini": "こ",
  "name": "ゴルフだいすき！",
  "maker": "キッド",
  "releaseDate": "1999/10/29",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "908",
  "ini": "は",
  "name": "ハムスター倶楽部",
  "maker": "ジョルダン",
  "releaseDate": "1999/10/29",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "909",
  "ini": "め",
  "name": "メダロット2 パーツコレクション",
  "maker": "イマジニア",
  "releaseDate": "1999/10/29",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "910",
  "ini": "こ",
  "name": "コラムスGB 手塚キャラクターズ",
  "maker": "メディアファクトリー",
  "releaseDate": "1999/11/5",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "911",
  "ini": "こ",
  "name": "合格ボーイシリーズ シカクいアタマをマルくする。理科バトル編",
  "maker": "イマジニア",
  "releaseDate": "1999/11/5",
  "price": "2680",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "912",
  "ini": "て",
  "name": "テトリスアドベンチャー すすめミッキーとなかまたち",
  "maker": "カプコン",
  "releaseDate": "1999/11/12",
  "price": "3800",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "913",
  "ini": "あ",
  "name": "R-TYPE DX",
  "maker": "アイレム",
  "releaseDate": "1999/11/12",
  "price": "3980",
  "genre": "シューティング",
  "icNo": "3"
 },
 {
  "id": "914",
  "ini": "ほ",
  "name": "ポケットモンスター 金",
  "maker": "任天堂",
  "releaseDate": "1999/11/21",
  "price": "3800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "915",
  "ini": "ほ",
  "name": "ポケットモンスター 銀",
  "maker": "任天堂",
  "releaseDate": "1999/11/21",
  "price": "3800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "916",
  "ini": "ひ",
  "name": "ビートマニアGB2 ガッチャミックス",
  "maker": "コナミ",
  "releaseDate": "1999/11/25",
  "price": "4300",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "917",
  "ini": "き",
  "name": "筋肉番付GB 挑戦者はキミだ！",
  "maker": "コナミ",
  "releaseDate": "1999/11/25",
  "price": "4500",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "918",
  "ini": "り",
  "name": "リトルマジック",
  "maker": "アルトロン",
  "releaseDate": "1999/11/26",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "6"
 },
 {
  "id": "919",
  "ini": "さ",
  "name": "ザ・グレイトバトル POCKET",
  "maker": "バンプレスト",
  "releaseDate": "1999/12/3",
  "price": "3980",
  "genre": "シュミレーション",
  "icNo": "3"
 },
 {
  "id": "920",
  "ini": "そ",
  "name": "それいけ！アンパンマン 不思議なニコニコアルバム",
  "maker": "タム",
  "releaseDate": "1999/12/3",
  "price": "4300",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "921",
  "ini": "く",
  "name": "グランデュエル 深きダンジョンの秘宝",
  "maker": "ボトムアップ",
  "releaseDate": "1999/12/10",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "922",
  "ini": "す",
  "name": "スーパーブラックバス リアル・ファイト",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/12/10",
  "price": "4500",
  "genre": "釣り",
  "icNo": "11"
 },
 {
  "id": "923",
  "ini": "て",
  "name": "電車でGO！",
  "maker": "サイバーフロント",
  "releaseDate": "1999/12/10",
  "price": "4200",
  "genre": "電車・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "924",
  "ini": "す",
  "name": "スーパーボンブリス DX",
  "maker": "BPS",
  "releaseDate": "1999/12/10",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "925",
  "ini": "み",
  "name": "みんなの将棋 初級編",
  "maker": "エム・ティー・オー",
  "releaseDate": "1999/12/10",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "926",
  "ini": "か",
  "name": "格闘料理伝説ビストロレシピ 決闘☆ビストガルム編",
  "maker": "バンプレスト",
  "releaseDate": "1999/12/10",
  "price": "3980",
  "genre": "育成・RPG",
  "icNo": "3"
 },
 {
  "id": "927",
  "ini": "か",
  "name": "がんばれゴエモン もののけ道中 飛び出せ鍋奉行！",
  "maker": "コナミ",
  "releaseDate": "1999/12/16",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "928",
  "ini": "と",
  "name": "トップギア・ポケット2",
  "maker": "ケムコ",
  "releaseDate": "1999/12/17",
  "price": "4200",
  "genre": "レース",
  "icNo": "11"
 },
 {
  "id": "929",
  "ini": "ほ",
  "name": "ボンバーマンMAX 闇の戦士",
  "maker": "ハドソン",
  "releaseDate": "1999/12/17",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "930",
  "ini": "ほ",
  "name": "ボンバーマンMAX 光の勇者",
  "maker": "ハドソン",
  "releaseDate": "1999/12/17",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "931",
  "ini": "す",
  "name": "スウィートアンジェ",
  "maker": "コーエー",
  "releaseDate": "1999/12/17",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "932",
  "ini": "ほ",
  "name": "ポケットカラー 麻雀",
  "maker": "ボトムアップ",
  "releaseDate": "1999/12/17",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "933",
  "ini": "し",
  "name": "女流雀士に挑戦GB 私達に挑戦してネ！",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/12/17",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "934",
  "ini": "は",
  "name": "パチンコ必勝ガイド データの王様",
  "maker": "BOSSコミュニケーションズ",
  "releaseDate": "1999/12/22",
  "price": "4980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "935",
  "ini": "ほ",
  "name": "ポケットカラー トランプ",
  "maker": "ボトムアップ",
  "releaseDate": "1999/12/22",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "936",
  "ini": "は",
  "name": "ハリウッドピンボール",
  "maker": "スターフィッシュ",
  "releaseDate": "1999/12/23",
  "price": "3980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "937",
  "ini": "す",
  "name": "スーパーチャイニーズファイターEX",
  "maker": "カルチャーブレーン",
  "releaseDate": "1999/12/24",
  "price": "3900",
  "genre": "格闘・アクション",
  "icNo": "6"
 },
 {
  "id": "938",
  "ini": "ほ",
  "name": "ポケットカラー ビリヤード",
  "maker": "ボトムアップ",
  "releaseDate": "1999/12/24",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "939",
  "ini": "き",
  "name": "機動戦艦ナデシコ ルリルリ麻雀",
  "maker": "キングレコード",
  "releaseDate": "1999/12/24",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "940",
  "ini": "お",
  "name": "踊る天才ペット！ Dancing Furby",
  "maker": "トミー",
  "releaseDate": "1999/12/24",
  "price": "3980",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "941",
  "ini": "も",
  "name": "モンスターファーム バトルカード GB",
  "maker": "テクモ",
  "releaseDate": "1999/12/24",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "942",
  "ini": "ろ",
  "name": "ロボットポンコッツ 月バージョン",
  "maker": "ハドソン",
  "releaseDate": "1999/12/24",
  "price": "3980",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "943",
  "ini": "は",
  "name": "爆走戦記メタルウォーカー GB 鋼鉄の友情",
  "maker": "カプコン",
  "releaseDate": "1999/12/24",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "944",
  "ini": "そ",
  "name": "倉庫番伝説 光と闇の国",
  "maker": "J・ウイング",
  "releaseDate": "1999/12/25",
  "price": "4300",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "945",
  "ini": "お",
  "name": "おわらいよゐこのげえむ道 オヤジ探して三丁目",
  "maker": "コナミ",
  "releaseDate": "1999/12/25",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "946",
  "ini": "た",
  "name": "ダフィー・ダック すべってころんで大金持ち",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "2000/1/1",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "947",
  "ini": "え",
  "name": "エリーのアトリエ GB ザールブルグの妖精錬金術師",
  "maker": "イマジニア",
  "releaseDate": "2000/1/8",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "948",
  "ini": "ま",
  "name": "マリーのアトリエ GB ザールブルグの妖精錬金術師",
  "maker": "イマジニア",
  "releaseDate": "2000/1/8",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "949",
  "ini": "し",
  "name": "ジャックの大冒険 大魔王の逆襲",
  "maker": "イマジニア",
  "releaseDate": "2000/1/15",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "950",
  "ini": "は",
  "name": "ハイパーオリンピック ウィンター2000",
  "maker": "コナミ",
  "releaseDate": "2000/1/27",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "951",
  "ini": "と",
  "name": "ドンキーコングGB ディンキーコング&ディクシーコング",
  "maker": "任天堂",
  "releaseDate": "2000/1/28",
  "price": "3800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "952",
  "ini": "な",
  "name": "なかよしペットシリーズ① かわいいハムスター",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/1/28",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "953",
  "ini": "ひ",
  "name": "Bビーダマン爆外伝V ファイナル・メガ・チューン",
  "maker": "メディアファクトリー",
  "releaseDate": "2000/2/4",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "954",
  "ini": "こ",
  "name": "語楽王 TANGO！",
  "maker": "J・ウイング",
  "releaseDate": "2000/2/11",
  "price": "3980",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "955",
  "ini": "ほ",
  "name": "ポケットビリヤード ファンク the 9ボール",
  "maker": "タム",
  "releaseDate": "2000/2/19",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "956",
  "ini": "ほ",
  "name": "本格対戦将棋 歩",
  "maker": "カルチャーブレーン",
  "releaseDate": "2000/2/18",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "957",
  "ini": "と",
  "name": "トレード&バトル カードヒーロー",
  "maker": "任天堂",
  "releaseDate": "2000/2/21",
  "price": "3800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "958",
  "ini": "え",
  "name": "F1 WORLD GRAND PRIX II for GAMEBOY COLOR",
  "maker": "ビデオシステム",
  "releaseDate": "2000/2/24",
  "price": "4300",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "959",
  "ini": "ふ",
  "name": "プロ麻雀 兵 GB2",
  "maker": "カルチャーブレーン",
  "releaseDate": "2000/2/24",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "960",
  "ini": "め",
  "name": "メタファイトEX",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "2000/2/24",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "961",
  "ini": "め",
  "name": "メタモード",
  "maker": "コーエー",
  "releaseDate": "2000/2/24",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "962",
  "ini": "ほ",
  "name": "本格花札 GB",
  "maker": "アルトロン",
  "releaseDate": "2000/2/24",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "963",
  "ini": "め",
  "name": "名探偵コナン からくり寺院殺人事件",
  "maker": "バンプレスト",
  "releaseDate": "2000/2/24",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "964",
  "ini": "す",
  "name": "スーパーマリオブラザーズ デラックス",
  "maker": "任天堂",
  "releaseDate": "2000/3/1",
  "price": "1000",
  "genre": "アクション",
  "icNo": "18"
 },
 {
  "id": "965",
  "ini": "ふ",
  "name": "プロ野球シミュレーション ポケットプロ野球",
  "maker": "エポック社",
  "releaseDate": "2000/3/10",
  "price": "3980",
  "genre": "野球・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "966",
  "ini": "と",
  "name": "ドラえもんメモリーズ のび太の想い出大冒険",
  "maker": "エポック社",
  "releaseDate": "2000/3/10",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "967",
  "ini": "め",
  "name": "メダロット カードロボトル（カブトバージョン）",
  "maker": "イマジニア",
  "releaseDate": "2000/3/10",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "968",
  "ini": "め",
  "name": "メダロット カードロボトル（クワガタバージョン）",
  "maker": "イマジニア",
  "releaseDate": "2000/3/10",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "969",
  "ini": "と",
  "name": "突撃！パッパラ隊",
  "maker": "J・ウイング",
  "releaseDate": "2000/3/8",
  "price": "4500",
  "genre": "ミニゲーム",
  "icNo": "3"
 },
 {
  "id": "970",
  "ini": "あ",
  "name": "RPGツクール GB",
  "maker": "アスキー",
  "releaseDate": "2000/3/17",
  "price": "4800",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "971",
  "ini": "は",
  "name": "ハムスターパラダイス2 ちゅー",
  "maker": "アトラス",
  "releaseDate": "2000/3/17",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "972",
  "ini": "ほ",
  "name": "ポケットプロレス パーフェクトレスラー",
  "maker": "J・ウイング",
  "releaseDate": "2000/3/17",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "973",
  "ini": "ま",
  "name": "マクロス7 銀河のハートを奮わせろ!!",
  "maker": "エポック社",
  "releaseDate": "2000/3/17",
  "price": "3980",
  "genre": "シューティング",
  "icNo": "6"
 },
 {
  "id": "974",
  "ini": "し",
  "name": "シルバニアメロディー 森の仲間と踊りましょ！",
  "maker": "エポック社",
  "releaseDate": "2000/3/17",
  "price": "3980",
  "genre": "リズムゲーム",
  "icNo": "3"
 },
 {
  "id": "975",
  "ini": "は",
  "name": "パズループ",
  "maker": "カプコン",
  "releaseDate": "2000/3/17",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "976",
  "ini": "こ",
  "name": "合格ボーイGOLD シカクいアタマをマルくする。国語 算数 理科 社会 常識の書",
  "maker": "イマジニア",
  "releaseDate": "2000/3/17",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "977",
  "ini": "こ",
  "name": "合格ボーイGOLD シカクいアタマをマルくする。国語 算数 理科 社会 難問の書",
  "maker": "イマジニア",
  "releaseDate": "2000/3/17",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "978",
  "ini": "わ",
  "name": "ワリオランド3 不思議なオルゴール",
  "maker": "任天堂",
  "releaseDate": "2000/3/21",
  "price": "3800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "979",
  "ini": "さ",
  "name": "サイボーグクロちゃん デビル復活!!",
  "maker": "コナミ",
  "releaseDate": "2000/3/23",
  "price": "4500",
  "genre": "アクション・シューティング",
  "icNo": "6"
 },
 {
  "id": "980",
  "ini": "て",
  "name": "Disney's TARZAN",
  "maker": "シスコンエンタテイメント",
  "releaseDate": "2000/3/24",
  "price": "4300",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "981",
  "ini": "れ",
  "name": "レイマン ミスター・ダークの罠",
  "maker": "ユービーアイソフト",
  "releaseDate": "2000/3/24",
  "price": "4200",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "982",
  "ini": "さ",
  "name": "さるパンチャー",
  "maker": "タイトー",
  "releaseDate": "2000/3/24",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "983",
  "ini": "し",
  "name": "じゃがいぬくん",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2000/3/24",
  "price": "3800",
  "genre": "リズムゲーム",
  "icNo": "3"
 },
 {
  "id": "984",
  "ini": "な",
  "name": "なかよしペットシリーズ② かわいいウサギ",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/3/24",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "985",
  "ini": "は",
  "name": "爆球連発!! スーパービーダマン 激誕！ライジングヴァルキリー!!",
  "maker": "タカラ",
  "releaseDate": "2000/3/24",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "986",
  "ini": "は",
  "name": "バトルフィッシャーズ",
  "maker": "コナミ",
  "releaseDate": "2000/3/30",
  "price": "4500",
  "genre": "釣り・テーブル",
  "icNo": "6"
 },
 {
  "id": "987",
  "ini": "ほ",
  "name": "ポップンミュージック GB",
  "maker": "コナミ",
  "releaseDate": "2000/3/30",
  "price": "4300",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "988",
  "ini": "は",
  "name": "パワクロクン ポケット2",
  "maker": "コナミ",
  "releaseDate": "2000/3/30",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "989",
  "ini": "と",
  "name": "トリックボーダー グランプリ",
  "maker": "アテナ",
  "releaseDate": "2000/3/31",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "990",
  "ini": "す",
  "name": "スノボーチャンピオン",
  "maker": "ボトムアップ",
  "releaseDate": "2000/3/31",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "991",
  "ini": "ひ",
  "name": "必殺パチンコBOY CRモンスターハウス",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "2000/3/31",
  "price": "3980",
  "genre": "パチンコ",
  "icNo": "6"
 },
 {
  "id": "992",
  "ini": "あ",
  "name": "アルルの冒険 まほうのジュエル",
  "maker": "コンパイル",
  "releaseDate": "2000/3/31",
  "price": "4200",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "993",
  "ini": "め",
  "name": "名探偵コナン 奇岩島秘宝伝説",
  "maker": "バンプレスト",
  "releaseDate": "2000/3/31",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "994",
  "ini": "ふ",
  "name": "VSレミングス",
  "maker": "J・ウイング",
  "releaseDate": "2000/4/7",
  "price": "4500",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "995",
  "ini": "ゆ",
  "name": "遊☆戯☆王 モンスターカプセルGB",
  "maker": "コナミ",
  "releaseDate": "2000/4/13",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "996",
  "ini": "せ",
  "name": "聖牌伝説",
  "maker": "ギャップス",
  "releaseDate": "2000/4/14",
  "price": "2800",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "997",
  "ini": "は",
  "name": "バーガーパラダイス インターナショナル",
  "maker": "ギャップス",
  "releaseDate": "2000/4/21",
  "price": "2980",
  "genre": "経営・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "998",
  "ini": "は",
  "name": "バックス・バニー クレイジーキャッスル4",
  "maker": "ケムコ",
  "releaseDate": "2000/4/21",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "999",
  "ini": "て",
  "name": "DXモノポリー GB",
  "maker": "タカラ",
  "releaseDate": "2000/4/21",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1000",
  "ini": "め",
  "name": "メタルギア Ghost Babel",
  "maker": "コナミ",
  "releaseDate": "2000/4/27",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1001",
  "ini": "と",
  "name": "ドラえもんのクイズボーイ",
  "maker": "小学館・エポック社",
  "releaseDate": "2000/4/28",
  "price": "3800",
  "genre": "学習・クイズ",
  "icNo": "6"
 },
 {
  "id": "1002",
  "ini": "ふ",
  "name": "フェレット物語",
  "maker": "カルチャーブレーン",
  "releaseDate": "2000/4/28",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1003",
  "ini": "て",
  "name": "ディノブリーダー4",
  "maker": "J・ウイング",
  "releaseDate": "2000/4/28",
  "price": "4800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1004",
  "ini": "は",
  "name": "パズルボブル4",
  "maker": "アルトロン",
  "releaseDate": "2000/4/28",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1005",
  "ini": "ろ",
  "name": "ロードランナー ドムドム団のやぼう",
  "maker": "エクシング・エンターテイメント",
  "releaseDate": "2000/4/28",
  "price": "3980",
  "genre": "パズル・アクション",
  "icNo": "3"
 },
 {
  "id": "1006",
  "ini": "か",
  "name": "漢字 de パズル",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/4/28",
  "price": "4280",
  "genre": "パズル・クイズ",
  "icNo": "3"
 },
 {
  "id": "1007",
  "ini": "け",
  "name": "競馬場へ行こう！ワイド",
  "maker": "ヘクト",
  "releaseDate": "2000/4/28",
  "price": "3980",
  "genre": "競馬予想",
  "icNo": "3"
 },
 {
  "id": "1008",
  "ini": "た",
  "name": "大工の源さん カチカチのトンカチがカチ",
  "maker": "バイオックス",
  "releaseDate": "2000/4/28",
  "price": "3980",
  "genre": "アクション・パズル",
  "icNo": "3"
 },
 {
  "id": "1009",
  "ini": "ま",
  "name": "麻雀女王",
  "maker": "童",
  "releaseDate": "2000/4/28",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1010",
  "ini": "け",
  "name": "ゲームコンビニ21",
  "maker": "スターフィッシュ",
  "releaseDate": "2000/5/19",
  "price": "3980",
  "genre": "ミニゲーム",
  "icNo": "3"
 },
 {
  "id": "1011",
  "ini": "た",
  "name": "タイトーメモリアル バブルボブル",
  "maker": "ジョルダン",
  "releaseDate": "2000/5/26",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "1012",
  "ini": "た",
  "name": "タイトーメモリアル CHASE H.Q.",
  "maker": "ジョルダン",
  "releaseDate": "2000/5/26",
  "price": "3980",
  "genre": "レース",
  "icNo": "3"
 },
 {
  "id": "1013",
  "ini": "た",
  "name": "大貝獣物語 ポヨンのダンジョンルーム2",
  "maker": "ハドソン",
  "releaseDate": "2000/6/2",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1014",
  "ini": "ひ",
  "name": "ビックリマン2000 チャージングカードGB",
  "maker": "イマジニア",
  "releaseDate": "2000/6/10",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1015",
  "ini": "は",
  "name": "ハンター×ハンター ハンターの系譜",
  "maker": "コナミ",
  "releaseDate": "2000/6/15",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1016",
  "ini": "み",
  "name": "ミスタードリラー GB版",
  "maker": "ナムコ",
  "releaseDate": "2000/6/29",
  "price": "3800",
  "genre": "パズル・アクション",
  "icNo": "6"
 },
 {
  "id": "1017",
  "ini": "む",
  "name": "ムーミンの大冒険",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "2000/6/30",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1018",
  "ini": "い",
  "name": "井出洋介の麻雀教室GB",
  "maker": "アテナ",
  "releaseDate": "2000/6/30",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1019",
  "ini": "お",
  "name": "おじゃる丸 満願神社は縁日でおじゃる！",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/6/30",
  "price": "4280",
  "genre": "その他",
  "icNo": "3"
 },
 {
  "id": "1020",
  "ini": "か",
  "name": "漢字BOY2",
  "maker": "J・ウイング",
  "releaseDate": "2000/6/30",
  "price": "4800",
  "genre": "学習",
  "icNo": "3"
 },
 {
  "id": "1021",
  "ini": "た",
  "name": "対戦詰将棋",
  "maker": "アテナ",
  "releaseDate": "2000/7/1",
  "price": "1000",
  "genre": "テーブル",
  "icNo": "18"
 },
 {
  "id": "1022",
  "ini": "わ",
  "name": "ワールドサッカーGB 2000",
  "maker": "コナミ",
  "releaseDate": "2000/7/6",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1023",
  "ini": "か",
  "name": "がんばれ！ニッポン！オリンピック2000",
  "maker": "コナミ",
  "releaseDate": "2000/7/13",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1024",
  "ini": "ゆ",
  "name": "遊☆戯☆王デュエルモンスターズⅢ 三聖戦神降臨",
  "maker": "コナミ",
  "releaseDate": "2000/7/13",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1025",
  "ini": "あ",
  "name": "あつめてあそぶ くまのプーさん もりのたからもの",
  "maker": "トミー",
  "releaseDate": "2000/7/7",
  "price": "3980",
  "genre": "アドベンチャー・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1026",
  "ini": "い",
  "name": "いつでもパチンコGB CRモンスターハウス",
  "maker": "タム",
  "releaseDate": "2000/7/14",
  "price": "3980",
  "genre": "パチンコ",
  "icNo": "6"
 },
 {
  "id": "1027",
  "ini": "お",
  "name": "おじゃる丸　月夜が池のたからもの",
  "maker": "サクセス",
  "releaseDate": "2000/7/14",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1028",
  "ini": "と",
  "name": "ドカボン?! ミレニアムクエスト",
  "maker": "アスミック・エースエンタテインメント",
  "releaseDate": "2000/7/14",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1029",
  "ini": "さ",
  "name": "サバイバルキッズ2 脱出!!双子島！",
  "maker": "コナミ",
  "releaseDate": "2000/7/19",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1030",
  "ini": "て",
  "name": "ディアダニエルのスウィートアドベンチャー キティちゃんをさがして",
  "maker": "イマジニア",
  "releaseDate": "2000/7/19",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "1031",
  "ini": "は",
  "name": "ハローキティのスウィートアドベンチャー ダニエル君にあいたい",
  "maker": "イマジニア",
  "releaseDate": "2000/7/19",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "1032",
  "ini": "は",
  "name": "爆走デコトラ伝説 GBスペシャル 男度胸の天下統一",
  "maker": "キッド",
  "releaseDate": "2000/7/21",
  "price": "3980",
  "genre": "レース・シュミレーション",
  "icNo": "6"
 },
 {
  "id": "1033",
  "ini": "て",
  "name": "データナビ プロ野球",
  "maker": "ナウプロダクション",
  "releaseDate": "2000/7/21",
  "price": "4300",
  "genre": "野球・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1034",
  "ini": "め",
  "name": "メダロット3 カブトバージョン",
  "maker": "イマジニア",
  "releaseDate": "2000/7/23",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1035",
  "ini": "め",
  "name": "メダロット3 クワガタバージョン",
  "maker": "イマジニア",
  "releaseDate": "2000/7/23",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1036",
  "ini": "さ",
  "name": "サクラ大戦GB 檄・花組入隊！",
  "maker": "メディアファクトリー",
  "releaseDate": "2000/7/28",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "1037",
  "ini": "か",
  "name": "華欄虎龍学園　花札・麻雀",
  "maker": "J・ウイング",
  "releaseDate": "2000/7/28",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1038",
  "ini": "は",
  "name": "バルーンファイトGB",
  "maker": "任天堂",
  "releaseDate": "2000/7/31",
  "price": "1000",
  "genre": "アクション",
  "icNo": "18"
 },
 {
  "id": "1039",
  "ini": "か",
  "name": "カラムー町は大さわぎ! おかわりっ!",
  "maker": "スターフィッシュ",
  "releaseDate": "2000/8/1",
  "price": "1500",
  "genre": "アクション",
  "icNo": "18"
 },
 {
  "id": "1040",
  "ini": "た",
  "name": "ダンスダンスレボリューション GB",
  "maker": "コナミ",
  "releaseDate": "2000/8/3",
  "price": "4800",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1041",
  "ini": "そ",
  "name": "ぞくぞくヒーローズ",
  "maker": "メディアファクトリー",
  "releaseDate": "2000/8/4",
  "price": "5980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1042",
  "ini": "ぬ",
  "name": "ぬし釣りアドベンチャー カイトの冒険",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2000/8/4",
  "price": "4200",
  "genre": "釣り・RPG",
  "icNo": "11"
 },
 {
  "id": "1043",
  "ini": "ま",
  "name": "マジカルチェイスGB 見習い魔法使い 賢者の谷へ",
  "maker": "マイクロキャビン",
  "releaseDate": "2000/8/4",
  "price": "3480",
  "genre": "シューティング",
  "icNo": "6"
 },
 {
  "id": "1044",
  "ini": "ら",
  "name": "ラブひなポケット",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2000/8/4",
  "price": "4200",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1045",
  "ini": "そ",
  "name": "ゾイド邪神復活！ジェノブレイカー編",
  "maker": "トミー",
  "releaseDate": "2000/8/4",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1046",
  "ini": "そ",
  "name": "ソウルゲッター 放課後冒険RPG",
  "maker": "マイクロキャビン",
  "releaseDate": "2000/8/4",
  "price": "3980",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1047",
  "ini": "た",
  "name": "ダンジョンセイバー",
  "maker": "J・ウイング",
  "releaseDate": "2000/8/4",
  "price": "4800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1048",
  "ini": "き",
  "name": "筋肉番付GB2　目指せ！マッスルチャンピオン",
  "maker": "コナミ",
  "releaseDate": "2000/8/10",
  "price": "4500",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "1049",
  "ini": "け",
  "name": "K.O. THE PRO BOXING",
  "maker": "アルトロン",
  "releaseDate": "2000/8/11",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1050",
  "ini": "と",
  "name": "トゥイーティ世界一周 80匹のネコをさがせ！",
  "maker": "ケムコ",
  "releaseDate": "2000/8/11",
  "price": "4200",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1051",
  "ini": "な",
  "name": "なかよしペットシリーズ③ かわいい仔犬",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1052",
  "ini": "は",
  "name": "パーフェクト チョロQ",
  "maker": "タカラ",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "レース・シュミレーション",
  "icNo": "6"
 },
 {
  "id": "1053",
  "ini": "へ",
  "name": "ベイブレード FIGHTING TOURNAMENT",
  "maker": "ハドソン",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1054",
  "ini": "た",
  "name": "谷村ひとし流パチンコ攻略大作戦 ドン・キホーテが行く",
  "maker": "アトラス",
  "releaseDate": "2000/8/11",
  "price": "4500",
  "genre": "パチンコ",
  "icNo": "3"
 },
 {
  "id": "1055",
  "ini": "か",
  "name": "海底伝説!! トレジャーワールド",
  "maker": "ダズ",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1056",
  "ini": "と",
  "name": "所さんの世田谷カントリークラブ",
  "maker": "ナツメ",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "1057",
  "ini": "し",
  "name": "実践役に立つ詰碁",
  "maker": "ポニーキャニオン",
  "releaseDate": "2000/8/11",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1058",
  "ini": "こ",
  "name": "コロコロカービィ",
  "maker": "任天堂",
  "releaseDate": "2000/8/23",
  "price": "4500",
  "genre": "アクション",
  "icNo": "7"
 },
 {
  "id": "1059",
  "ini": "ほ",
  "name": "ポップンミュージックGB アニメーションメロディ",
  "maker": "コナミ",
  "releaseDate": "2000/9/7",
  "price": "4500",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1060",
  "ini": "と",
  "name": "とっとこハム太郎 ともだち大作戦でちゅ",
  "maker": "任天堂",
  "releaseDate": "2000/9/8",
  "price": "3800",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1061",
  "ini": "ほ",
  "name": "ポケモンでパネポン",
  "maker": "任天堂",
  "releaseDate": "2000/9/21",
  "price": "3800",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1062",
  "ini": "ほ",
  "name": "ぼくのキャンプ場",
  "maker": "ナグザット",
  "releaseDate": "2000/9/22",
  "price": "3980",
  "genre": "経営・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1063",
  "ini": "ほ",
  "name": "ぽけっと ぷよぷよ～ん",
  "maker": "コンパイル",
  "releaseDate": "2000/9/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1064",
  "ini": "は",
  "name": "ハムスター倶楽部 あわせてチュー",
  "maker": "ジョルダン",
  "releaseDate": "2000/9/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1065",
  "ini": "ひ",
  "name": "ビートマニアGB ガッチャミックス2",
  "maker": "コナミ",
  "releaseDate": "2000/9/28",
  "price": "4500",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1066",
  "ini": "え",
  "name": "エレベーターアクション EX",
  "maker": "アルトロン",
  "releaseDate": "2000/9/29",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1067",
  "ini": "こ",
  "name": "ゴン太のおきらく大冒険",
  "maker": "レイアップ",
  "releaseDate": "2000/9/29",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1068",
  "ini": "そ",
  "name": "ソロモン",
  "maker": "テクモ",
  "releaseDate": "2000/9/29",
  "price": "4300",
  "genre": "アクション・パズル",
  "icNo": "6"
 },
 {
  "id": "1069",
  "ini": "し",
  "name": "新世紀エヴァンゲリオン 麻雀補完計画",
  "maker": "キングレコード",
  "releaseDate": "2000/9/29",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1070",
  "ini": "ほ",
  "name": "牧場物語GB3 ボーイ・ミーツ・ガール",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2000/9/29",
  "price": "4200",
  "genre": "経営・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1071",
  "ini": "す",
  "name": "スペースインベーダーX",
  "maker": "タイトー",
  "releaseDate": "2000/9/29",
  "price": "3980",
  "genre": "シューティング",
  "icNo": "3"
 },
 {
  "id": "1072",
  "ini": "は",
  "name": "パズルデショーブヨ！ウータマちゃん",
  "maker": "ナグザット",
  "releaseDate": "2000/9/28",
  "price": "3800",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1073",
  "ini": "え",
  "name": "F-1 WORLD GRAND PRIX",
  "maker": "ビデオシステム",
  "releaseDate": "2000/10/1",
  "price": "1000",
  "genre": "レース",
  "icNo": "18"
 },
 {
  "id": "1074",
  "ini": "か",
  "name": "カードキャプターさくら 友枝小学校大運動会",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/10/6",
  "price": "4280",
  "genre": "ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1075",
  "ini": "す",
  "name": "スーパードール・リカちゃん きせかえ大作戦",
  "maker": "ヴィアール・ワン",
  "releaseDate": "2000/10/6",
  "price": "4300",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1076",
  "ini": "さ",
  "name": "サイボーグクロちゃん2 ホワイトウッズの逆襲",
  "maker": "コナミ",
  "releaseDate": "2000/10/19",
  "price": "4500",
  "genre": "アクション・シューティング",
  "icNo": "6"
 },
 {
  "id": "1077",
  "ini": "ろ",
  "name": "ロックマンX サイバーミッション",
  "maker": "カプコン",
  "releaseDate": "2000/10/20",
  "price": "3980",
  "genre": "アクション",
  "icNo": "3"
 },
 {
  "id": "1078",
  "ini": "か",
  "name": "怪人ゾナー",
  "maker": "任天堂",
  "releaseDate": "2000/10/21",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1079",
  "ini": "し",
  "name": "JETでGO！",
  "maker": "アルトロン",
  "releaseDate": "2000/10/27",
  "price": "4500",
  "genre": "フライト・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1080",
  "ini": "こ",
  "name": "合格ボーイGOLD シカクいアタマをマルくする。立体 平面 図形の達人",
  "maker": "イマジニア",
  "releaseDate": "2000/10/27",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "1081",
  "ini": "ま",
  "name": "マリオテニスGB",
  "maker": "任天堂",
  "releaseDate": "2000/11/1",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1082",
  "ini": "ち",
  "name": "血と汗と涙の高校野球",
  "maker": "J・ウイング",
  "releaseDate": "2000/11/3",
  "price": "4800",
  "genre": "経営・シュミレーション",
  "icNo": "6"
 },
 {
  "id": "1083",
  "ini": "け",
  "name": "携帯電獣テレファング スピードバージョン",
  "maker": "スマイルソフト",
  "releaseDate": "2000/11/3",
  "price": "4700",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1084",
  "ini": "け",
  "name": "携帯電獣テレファング パワーバージョン",
  "maker": "スマイルソフト",
  "releaseDate": "2000/11/3",
  "price": "4700",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1085",
  "ini": "て",
  "name": "テイルズ オブ ファンタジア なりきりダンジョン",
  "maker": "ナムコ",
  "releaseDate": "2000/11/10",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1086",
  "ini": "た",
  "name": "Dance Dance Revolution GB2",
  "maker": "コナミ",
  "releaseDate": "2000/11/16",
  "price": "4800",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1087",
  "ini": "と",
  "name": "ドキドキ伝説 魔法陣グルグル",
  "maker": "エニックス",
  "releaseDate": "2000/11/17",
  "price": "5900",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1088",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 赤の書",
  "maker": "アトラス",
  "releaseDate": "2000/11/17",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1089",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 黒の書",
  "maker": "アトラス",
  "releaseDate": "2000/11/17",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1090",
  "ini": "か",
  "name": "かくれんぼバトル モンスタータクティクス",
  "maker": "任天堂",
  "releaseDate": "2000/11/21",
  "price": "3800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1091",
  "ini": "ほ",
  "name": "ポップンミュージックＧＢ ディズニーチューンズ",
  "maker": "コナミ",
  "releaseDate": "2000/11/22",
  "price": "4500",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1092",
  "ini": "く",
  "name": "グリンチ",
  "maker": "コナミ",
  "releaseDate": "2000/11/22",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1093",
  "ini": "え",
  "name": "エアフォース デルタ",
  "maker": "コナミ",
  "releaseDate": "2000/11/22",
  "price": "4300",
  "genre": "シューティング",
  "icNo": "6"
 },
 {
  "id": "1094",
  "ini": "そ",
  "name": "それいけ！アンパンマン 5つの塔の王様",
  "maker": "タム",
  "releaseDate": "2000/11/23",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1095",
  "ini": "こ",
  "name": "コマンドマスター",
  "maker": "エニックス",
  "releaseDate": "2000/11/23",
  "price": "5900",
  "genre": "その他",
  "icNo": "9"
 },
 {
  "id": "1096",
  "ini": "め",
  "name": "メダロット3 パーツコレクション Zからの超戦場",
  "maker": "イマジニア",
  "releaseDate": "2000/11/24",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1097",
  "ini": "せ",
  "name": "仙界異聞録 準提大戦 ＴＶアニメーション「仙界伝封神演義」より",
  "maker": "バンプレスト",
  "releaseDate": "2000/11/24",
  "price": "3980",
  "genre": "シミュレーション・RPG",
  "icNo": "3"
 },
 {
  "id": "1098",
  "ini": "は",
  "name": "ハムナプトラ  失われた砂漠の都",
  "maker": "コナミ",
  "releaseDate": "2000/11/30",
  "price": "4300",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1099",
  "ini": "と",
  "name": "ドナルドダック デイジーを救え！",
  "maker": "ユービーアイソフト",
  "releaseDate": "2000/12/1",
  "price": "4300",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1100",
  "ini": "す",
  "name": "スーパーミメルＧＢ ミメルベアのハッピーメールタウン",
  "maker": "トミー",
  "releaseDate": "2000/12/1",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1101",
  "ini": "し",
  "name": "GB ハロボッツ",
  "maker": "サンライズインタラクティブ",
  "releaseDate": "2000/12/1",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1102",
  "ini": "ろ",
  "name": "六門天外 モンコレナイト ＧＢ",
  "maker": "角川書店",
  "releaseDate": "2000/12/1",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1103",
  "ini": "ひ",
  "name": "Piaキャロットへようこそ!! 2.2",
  "maker": "NECインターチャネル",
  "releaseDate": "2000/12/2",
  "price": "5300",
  "genre": "恋愛・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1104",
  "ini": "ゆ",
  "name": "遊☆戯☆王 デュエルモンスターズ4 最強決闘者戦記 遊戯デッキ",
  "maker": "コナミ",
  "releaseDate": "2000/12/7",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1105",
  "ini": "ゆ",
  "name": "遊☆戯☆王 デュエルモンスターズ4 最強決闘者戦記 海馬デッキ",
  "maker": "コナミ",
  "releaseDate": "2000/12/7",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1106",
  "ini": "ゆ",
  "name": "遊☆戯☆王 デュエルモンスターズ4 最強決闘者戦記 城之内デッキ",
  "maker": "コナミ",
  "releaseDate": "2000/12/7",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1107",
  "ini": "た",
  "name": "だぁ！だぁ！だぁ！とつぜん☆カードでバトルで占いで?",
  "maker": "ビデオシステム",
  "releaseDate": "2000/12/8",
  "price": "4500",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1108",
  "ini": "て",
  "name": "電車でGO！2",
  "maker": "サイバーフロント",
  "releaseDate": "2000/12/8",
  "price": "5200",
  "genre": "電車・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1109",
  "ini": "と",
  "name": "ドラゴンクエストⅢ そして伝説へ…",
  "maker": "エニックス",
  "releaseDate": "2000/12/8",
  "price": "6400",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1110",
  "ini": "て",
  "name": "でじこの麻雀パーティ",
  "maker": "キングレコード",
  "releaseDate": "2000/12/8",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1111",
  "ini": "ほ",
  "name": "ポケットモンスター クリスタルバージョン",
  "maker": "任天堂",
  "releaseDate": "2000/12/14",
  "price": "3800",
  "genre": "RPG",
  "icNo": "8"
 },
 {
  "id": "1112",
  "ini": "な",
  "name": "なかよしクッキングシリーズ① おいしいケーキ屋さん",
  "maker": "エム・ティー・オー",
  "releaseDate": "2000/12/15",
  "price": "3980",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1113",
  "ini": "は",
  "name": "ハムスターパラダイス3 ちゅりー",
  "maker": "アトラス",
  "releaseDate": "2000/12/15",
  "price": "4300",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1114",
  "ini": "せ",
  "name": "攻めCOMダンジョン ドルルルアーガ",
  "maker": "ナムコ",
  "releaseDate": "2000/12/15",
  "price": "4500",
  "genre": "ダンジョン・RPG",
  "icNo": "3"
 },
 {
  "id": "1115",
  "ini": "は",
  "name": "ハムスター倶楽部2",
  "maker": "ジョルダン",
  "releaseDate": "2000/12/15",
  "price": "4300",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "1116",
  "ini": "き",
  "name": "金田一少年の事件簿 10年目の招待状",
  "maker": "バンプレスト",
  "releaseDate": "2000/12/16",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1117",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ 九九ゲーム",
  "maker": "小学館",
  "releaseDate": "2000/12/20",
  "price": "3980",
  "genre": "学習",
  "icNo": "6"
 },
 {
  "id": "1118",
  "ini": "か",
  "name": "がんばれゴエモン 星空士ダイナマイッツあらわる!!",
  "maker": "コナミ",
  "releaseDate": "2000/12/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1119",
  "ini": "は",
  "name": "パズルボブル ミレニアム",
  "maker": "アルトロン",
  "releaseDate": "2000/12/22",
  "price": "4200",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1120",
  "ini": "し",
  "name": "シルバニアファミリー2 色づく森のファンタジー",
  "maker": "エポック社",
  "releaseDate": "2000/12/22",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1121",
  "ini": "う",
  "name": "ウィザードリィ　エンパイア 復活の杖",
  "maker": "スターフィッシュ",
  "releaseDate": "2000/12/22",
  "price": "3980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1122",
  "ini": "く",
  "name": "グランディア パラレルトリッパーズ",
  "maker": "ハドソン",
  "releaseDate": "2000/12/22",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1123",
  "ini": "か",
  "name": "かわいいペットショップ物語2",
  "maker": "タイトー",
  "releaseDate": "2000/12/22",
  "price": "4200",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1124",
  "ini": "ひ",
  "name": "飛龍の拳 烈伝ＧＢ",
  "maker": "カルチャーブレーン",
  "releaseDate": "2000/12/22",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1125",
  "ini": "も",
  "name": "桃太郎伝説1から2",
  "maker": "ハドソン",
  "releaseDate": "2001/1/1",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1126",
  "ini": "あ",
  "name": "あにまるぶりーだー4",
  "maker": "J・ウイング",
  "releaseDate": "2001/1/1",
  "price": "4800",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1127",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ 学習漢字ゲーム",
  "maker": "小学館",
  "releaseDate": "2001/1/12",
  "price": "3980",
  "genre": "学習",
  "icNo": "6"
 },
 {
  "id": "1128",
  "ini": "と",
  "name": "ドンキーコング2001",
  "maker": "任天堂",
  "releaseDate": "2001/1/21",
  "price": "3800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1129",
  "ini": "ふ",
  "name": "ブレイブサーガ 新章アスタリア",
  "maker": "タカラ",
  "releaseDate": "2001/1/26",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1130",
  "ini": "ら",
  "name": "ラブひな ラブひなパーティー",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2001/1/26",
  "price": "4500",
  "genre": "恋愛・アドベンチャー・クイズ",
  "icNo": "6"
 },
 {
  "id": "1131",
  "ini": "さ",
  "name": "サムライキッド",
  "maker": "コーエー",
  "releaseDate": "2001/2/2",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1132",
  "ini": "お",
  "name": "おはスタ Dance Dance Revolution GB",
  "maker": "コナミ",
  "releaseDate": "2001/2/8",
  "price": "4800",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1133",
  "ini": "へ",
  "name": "へろへろくん",
  "maker": "イマジニア",
  "releaseDate": "2001/2/9",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1134",
  "ini": "し",
  "name": "勝負師伝説　哲也　新宿天運編",
  "maker": "アテナ",
  "releaseDate": "2001/2/9",
  "price": "4700",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1135",
  "ini": "ほ",
  "name": "ぽっぷんぽっぷ",
  "maker": "ジョルダン",
  "releaseDate": "2001/2/16",
  "price": "3800",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1136",
  "ini": "な",
  "name": "なかよしペットシリーズ④ かわいい仔猫",
  "maker": "エム・ティー・オー",
  "releaseDate": "2001/2/16",
  "price": "4200",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1137",
  "ini": "き",
  "name": "筋肉番付GB3 新世紀サバイバル列伝！",
  "maker": "コナミ",
  "releaseDate": "2001/2/22",
  "price": "4800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1138",
  "ini": "ふ",
  "name": "フロントライン THE NEXT MISSION",
  "maker": "アルトロン",
  "releaseDate": "2001/2/23",
  "price": "4200",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1139",
  "ini": "す",
  "name": "スーパーロボットピンボール",
  "maker": "メディアファクトリー",
  "releaseDate": "2001/2/23",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1140",
  "ini": "う",
  "name": "ウィザードリィ",
  "maker": "アスキー",
  "releaseDate": "2001/2/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1141",
  "ini": "う",
  "name": "ウィザードリィⅡ リルガミンの遺産",
  "maker": "アスキー",
  "releaseDate": "2001/2/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1142",
  "ini": "う",
  "name": "ウィザードリィⅢ ダイアモンドの騎士",
  "maker": "アスキー",
  "releaseDate": "2001/2/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1143",
  "ini": "ほ",
  "name": "ポケットキング",
  "maker": "ナムコ",
  "releaseDate": "2001/2/23",
  "price": "4500",
  "genre": "シミュレーション・RPG",
  "icNo": "3"
 },
 {
  "id": "1144",
  "ini": "わ",
  "name": "笑う犬の冒険 SILLY GO LUCKY ！",
  "maker": "カプコン",
  "releaseDate": "2001/2/23",
  "price": "4300",
  "genre": "ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1145",
  "ini": "せ",
  "name": "ゼルダの伝説 ふしぎの木の実 大地の章",
  "maker": "任天堂",
  "releaseDate": "2001/2/27",
  "price": "3800",
  "genre": "アクション・RPG",
  "icNo": "6"
 },
 {
  "id": "1146",
  "ini": "せ",
  "name": "ゼルダの伝説 ふしぎの木の実 時空の章",
  "maker": "任天堂",
  "releaseDate": "2001/2/27",
  "price": "3800",
  "genre": "アクション・RPG",
  "icNo": "6"
 },
 {
  "id": "1147",
  "ini": "ち",
  "name": "ちっちゃいエイリアン",
  "maker": "クリーチャーズ",
  "releaseDate": "2001/2/23",
  "price": "4800",
  "genre": "ミニゲーム",
  "icNo": "14"
 },
 {
  "id": "1148",
  "ini": "さ",
  "name": "ザ・ブラックオニキス",
  "maker": "タイトー",
  "releaseDate": "2001/3/2",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1149",
  "ini": "む",
  "name": "無敵王トライゼノン",
  "maker": "マーベラスエンターテイメント",
  "releaseDate": "2001/3/9",
  "price": "4300",
  "genre": "シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1150",
  "ini": "こ",
  "name": "コトバトル 天外の守人",
  "maker": "アルファドリーム",
  "releaseDate": "2001/3/9",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1151",
  "ini": "と",
  "name": "ドラゴンクエストモンスターズ2 マルタのふしぎな鍵 ルカの旅立ち",
  "maker": "エニックス",
  "releaseDate": "2001/3/9",
  "price": "6400",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1152",
  "ini": "う",
  "name": "ウッディウッドペッカーのゴー！ゴー！レーシング",
  "maker": "コナミ",
  "releaseDate": "2001/3/15",
  "price": "4500",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "1153",
  "ini": "た",
  "name": "Dance Dance Revolution GB3",
  "maker": "コナミ",
  "releaseDate": "2001/3/15",
  "price": "4800",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1154",
  "ini": "す",
  "name": "スペースネット コスモレッド",
  "maker": "イマジニア",
  "releaseDate": "2001/3/16",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1155",
  "ini": "す",
  "name": "スペースネット コスモブルー",
  "maker": "イマジニア",
  "releaseDate": "2001/3/16",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1156",
  "ini": "と",
  "name": "ドラえもん きみとペットの物語",
  "maker": "エポック社",
  "releaseDate": "2001/3/16",
  "price": "4300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1157",
  "ini": "め",
  "name": "メダロット4 カブトバージョン",
  "maker": "イマジニア",
  "releaseDate": "2001/3/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1158",
  "ini": "め",
  "name": "メダロット4 クワガタバージョン",
  "maker": "イマジニア",
  "releaseDate": "2001/3/23",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1159",
  "ini": "け",
  "name": "幻想魔伝 最遊記 砂漠の四神",
  "maker": "J・ウイング",
  "releaseDate": "2001/3/23",
  "price": "4980",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1160",
  "ini": "き",
  "name": "きせかえシリーズ② おしゃれ日記",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/3/23",
  "price": "4200",
  "genre": "恋愛・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1161",
  "ini": "に",
  "name": "忍たま乱太郎 忍術学園に入学しようの段",
  "maker": "アスク",
  "releaseDate": "2001/3/23",
  "price": "4500",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "1162",
  "ini": "ほ",
  "name": "ポケモンカード GB2 GR団参上！",
  "maker": "ポケモン",
  "releaseDate": "2001/3/28",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1163",
  "ini": "た",
  "name": "Dance Dance Revolution GB ディズニーミックス",
  "maker": "コナミ",
  "releaseDate": "2001/3/29",
  "price": "4800",
  "genre": "リズムゲーム",
  "icNo": "6"
 },
 {
  "id": "1164",
  "ini": "は",
  "name": "ハローキティとディアダニエルのドリームアドベンチャー",
  "maker": "イマジニア",
  "releaseDate": "2001/4/14",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1165",
  "ini": "す",
  "name": "ストリートファイター ALPHA",
  "maker": "カプコン",
  "releaseDate": "2001/3/30",
  "price": "4300",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1166",
  "ini": "あ",
  "name": "アニマスター GB",
  "maker": "メディアファクトリー",
  "releaseDate": "2001/3/30",
  "price": "3980",
  "genre": "レース・カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1167",
  "ini": "こ",
  "name": "合格ボーイGOLD シカクいアタマをマルくする。整数 小数 分数 単位 計算の達人",
  "maker": "イマジニア",
  "releaseDate": "2001/3/30",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "1168",
  "ini": "こ",
  "name": "合格ボーイGOLD シカクいアタマをマルくする。読み 書き 筆順 熟語 漢字の達人",
  "maker": "イマジニア",
  "releaseDate": "2001/3/30",
  "price": "3800",
  "genre": "学習",
  "icNo": "1"
 },
 {
  "id": "1169",
  "ini": "く",
  "name": "クロスハンター エックス・ハンターバージョン",
  "maker": "ゲームビレッジ",
  "releaseDate": "2001/4/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1170",
  "ini": "く",
  "name": "クロスハンター トレジャー・ハンターバージョン",
  "maker": "ゲームビレッジ",
  "releaseDate": "2001/4/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1171",
  "ini": "く",
  "name": "クロスハンター モンスター・ハンターバージョン",
  "maker": "ゲームビレッジ",
  "releaseDate": "2001/4/12",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1172",
  "ini": "は",
  "name": "ハンター×ハンター  禁断の秘宝",
  "maker": "コナミ",
  "releaseDate": "2001/4/12",
  "price": "4500",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1173",
  "ini": "と",
  "name": "ドラゴンクエストモンスターズ2 マルタのふしぎな鍵 イルの冒険",
  "maker": "エニックス",
  "releaseDate": "2001/4/12",
  "price": "6400",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1174",
  "ini": "お",
  "name": "おいでラスカル",
  "maker": "タム",
  "releaseDate": "2001/4/13",
  "price": "4500",
  "genre": "育成・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1175",
  "ini": "な",
  "name": "なかよしクッキングシリーズ② おいしいパン屋さん",
  "maker": "エム・ティー・オー",
  "releaseDate": "2001/4/20",
  "price": "4200",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1176",
  "ini": "と",
  "name": "とっとこハム太郎2 ハムちゃんず大集合でちゅ",
  "maker": "任天堂",
  "releaseDate": "2001/4/21",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1177",
  "ini": "く",
  "name": "ぐるぐるタウン はなまるくん",
  "maker": "J・ウイング",
  "releaseDate": "2001/4/27",
  "price": "4980",
  "genre": "クイズ・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1178",
  "ini": "し",
  "name": "雀級生 コスプレ☆パラダイス",
  "maker": "エルフ",
  "releaseDate": "2001/4/27",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1179",
  "ini": "え",
  "name": "X-MEN MUTANT ACADEMY",
  "maker": "サクセス",
  "releaseDate": "2001/4/27",
  "price": "3980",
  "genre": "格闘・アクション",
  "icNo": "6"
 },
 {
  "id": "1180",
  "ini": "て",
  "name": "DX人生ゲーム",
  "maker": "タカラ",
  "releaseDate": "2001/4/27",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1181",
  "ini": "す",
  "name": "スパイダーマン",
  "maker": "サクセス",
  "releaseDate": "2001/4/27",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1182",
  "ini": "な",
  "name": "なかよしペットシリーズ⑤ かわいいハムスター2",
  "maker": "エム・ティー・オー",
  "releaseDate": "2001/4/27",
  "price": "4200",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1183",
  "ini": "ふ",
  "name": "From TV animation ONE PIECE 夢のルフィ海賊団誕生！",
  "maker": "バンプレスト",
  "releaseDate": "2001/4/27",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1184",
  "ini": "も",
  "name": "モバイルゴルフ",
  "maker": "任天堂",
  "releaseDate": "2001/5/11",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1185",
  "ini": "た",
  "name": "大刀 GB",
  "maker": "ケムコ",
  "releaseDate": "2001/5/1",
  "price": "1000",
  "genre": "アクション・RPG",
  "icNo": "18"
 },
 {
  "id": "1186",
  "ini": "は",
  "name": "バットマン ビヨンド",
  "maker": "ケムコ",
  "releaseDate": "2001/5/1",
  "price": "1000",
  "genre": "アクション",
  "icNo": "18"
 },
 {
  "id": "1187",
  "ini": "て",
  "name": "DT Lords of Genomes",
  "maker": "メディアファクトリー",
  "releaseDate": "2001/5/25",
  "price": "4500",
  "genre": "カードゲーム",
  "icNo": "3"
 },
 {
  "id": "1188",
  "ini": "め",
  "name": "名探偵コナン 呪われた航路",
  "maker": "バンプレスト",
  "releaseDate": "2001/6/1",
  "price": "4500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1189",
  "ini": "そ",
  "name": "ZOIDS 白銀の獣機神ライガーゼロ",
  "maker": "トミー",
  "releaseDate": "2001/6/15",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1190",
  "ini": "す",
  "name": "スヌーピー テニス",
  "maker": "アンフォグラム ハドソン",
  "releaseDate": "2001/6/20",
  "price": "4300",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "1191",
  "ini": "す",
  "name": "スターオーシャン ブルースフィア",
  "maker": "エニックス",
  "releaseDate": "2001/6/28",
  "price": "5800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1192",
  "ini": "な",
  "name": "なかよしクッキングシリーズ③ 楽しいお弁当",
  "maker": "エム・ティー・オー",
  "releaseDate": "2001/6/29",
  "price": "4200",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1193",
  "ini": "か",
  "name": "ガイアマスター DUEL カードアタッカーズ",
  "maker": "カプコン",
  "releaseDate": "2001/6/29",
  "price": "4300",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1194",
  "ini": "こ",
  "name": "こぐるぐるぐる ぐるぐるとなかよし",
  "maker": "スティング",
  "releaseDate": "2001/7/1",
  "price": "1000",
  "genre": "アドベンチャー",
  "icNo": "18"
 },
 {
  "id": "1195",
  "ini": "な",
  "name": "波乗り野郎！",
  "maker": "ナツメ",
  "releaseDate": "2001/7/1",
  "price": "1000",
  "genre": "スポーツ",
  "icNo": "18"
 },
 {
  "id": "1196",
  "ini": "ね",
  "name": "ネットでゲット ミニゲーム＠100",
  "maker": "コナミ",
  "releaseDate": "2001/7/12",
  "price": "5800",
  "genre": "ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1197",
  "ini": "て",
  "name": "データナビプロ野球2",
  "maker": "ナウプロダクション",
  "releaseDate": "2001/7/13",
  "price": "4300",
  "genre": "野球・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1198",
  "ini": "つ",
  "name": "つりいこっ!!",
  "maker": "アスキー",
  "releaseDate": "2001/7/19",
  "price": "4500",
  "genre": "釣り",
  "icNo": "6"
 },
 {
  "id": "1199",
  "ini": "ふ",
  "name": "不思議のダンジョン 風来のシレンGB2 砂漠の魔城",
  "maker": "チュンソフト",
  "releaseDate": "2001/7/19",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1200",
  "ini": "ろ",
  "name": "ロックマンX2 ソウルイレイザー",
  "maker": "カプコン",
  "releaseDate": "2001/7/19",
  "price": "3980",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1201",
  "ini": "ま",
  "name": "マクドナルド物語 ほのぼの店長育成ゲーム",
  "maker": "TDKコア",
  "releaseDate": "2001/7/20",
  "price": "4500",
  "genre": "育成・シミュレーション・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1202",
  "ini": "し",
  "name": "Ｊリーグ エキサイトステージ タクティクス",
  "maker": "エポック社",
  "releaseDate": "2001/7/20",
  "price": "4300",
  "genre": "スポーツ・経営・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1203",
  "ini": "う",
  "name": "うちゅう人 田中太郎でRPGツクールGB2",
  "maker": "エンターブレイン",
  "releaseDate": "2001/7/20",
  "price": "5200",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1204",
  "ini": "ち",
  "name": "超GALS！寿蘭",
  "maker": "コナミ",
  "releaseDate": "2001/7/26",
  "price": "4800",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1205",
  "ini": "は",
  "name": "花より男子 Another Love Story",
  "maker": "TDKコア",
  "releaseDate": "2001/7/27",
  "price": "4500",
  "genre": "恋愛・アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1206",
  "ini": "し",
  "name": "真・女神転生 デビルチルドレン 白の章",
  "maker": "アトラス",
  "releaseDate": "2001/7/27",
  "price": "4500",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1207",
  "ini": "し",
  "name": "真・女神転生 トレーディングカード カードサマナー",
  "maker": "エンターブレイン",
  "releaseDate": "2001/7/27",
  "price": "5200",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1208",
  "ini": "は",
  "name": "爆転シュート ベイブレード",
  "maker": "ブロッコリー",
  "releaseDate": "2001/7/27",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1209",
  "ini": "こ",
  "name": "昆虫博士3",
  "maker": "J・ウイング",
  "releaseDate": "2001/7/27",
  "price": "4980",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1210",
  "ini": "み",
  "name": "水木しげるの新・妖怪伝",
  "maker": "プライムシステム",
  "releaseDate": "2001/7/27",
  "price": "4800",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1211",
  "ini": "ろ",
  "name": "Loppi パズルマガジン かんがえるパズル創刊号",
  "maker": "サクセス",
  "releaseDate": "2001/8/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1212",
  "ini": "に",
  "name": "ニセモン パズルdaモン！フェロモン救出大作戦！",
  "maker": "プライムシステム",
  "releaseDate": "2001/8/10",
  "price": "4800",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1213",
  "ini": "は",
  "name": "バッドばつ丸 ロボバトル",
  "maker": "イマジニア",
  "releaseDate": "2001/8/10",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1214",
  "ini": "ち",
  "name": "ちびまる子ちゃん ご町内 みんなでゲームだよ！",
  "maker": "エポック社",
  "releaseDate": "2001/8/10",
  "price": "4300",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1215",
  "ini": "ほ",
  "name": "ポケットクッキング",
  "maker": "J・ウイング",
  "releaseDate": "2001/8/24",
  "price": "4800",
  "genre": "料理・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1216",
  "ini": "け",
  "name": "ゲームボーイウォーズ3",
  "maker": "ハドソン",
  "releaseDate": "2001/8/30",
  "price": "3800",
  "genre": "シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1217",
  "ini": "ろ",
  "name": "Loppi パズルマガジン ひらめくパズル創刊号",
  "maker": "サクセス",
  "releaseDate": "2001/9/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1218",
  "ini": "え",
  "name": "エストポリス伝記 よみがえる伝説",
  "maker": "タイトー",
  "releaseDate": "2001/9/7",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1219",
  "ini": "せ",
  "name": "全日本少年サッカー大会 めざせ日本一！",
  "maker": "サクセス",
  "releaseDate": "2001/9/7",
  "price": "4800",
  "genre": "スポーツ・育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1220",
  "ini": "ま",
  "name": "マリオファミリー(ジャガーミシン刺しゅう専用ソフト)",
  "maker": "ジャガーインターナショナルコーポレーション",
  "releaseDate": "2001/9/10",
  "price": "6800",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1221",
  "ini": "は",
  "name": "ハムスター倶楽部 おしえまチュー",
  "maker": "ジョルダン",
  "releaseDate": "2001/9/21",
  "price": "4300",
  "genre": "クイズ・ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1222",
  "ini": "は",
  "name": "ハムスターパラダイス4",
  "maker": "アトラス",
  "releaseDate": "2001/9/28",
  "price": "4500",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1223",
  "ini": "き",
  "name": "きかんしゃトーマス ソドーとうのなかまたち",
  "maker": "タム",
  "releaseDate": "2001/10/12",
  "price": "4500",
  "genre": "その他",
  "icNo": "6"
 },
 {
  "id": "1224",
  "ini": "ろ",
  "name": "Loppi パズルマガジン かんがえるパズル第2号",
  "maker": "サクセス",
  "releaseDate": "2001/10/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1225",
  "ini": "は",
  "name": "激走ダンガンレーサー 音速バスターDANGAN弾",
  "maker": "イマジニア",
  "releaseDate": "2001/10/12",
  "price": "4600",
  "genre": "レース・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1226",
  "ini": "と",
  "name": "DOKI×DOKIさせて!!",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/10/26",
  "price": "4200",
  "genre": "恋愛・アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1227",
  "ini": "き",
  "name": "仰天人間バトシーラー ドクトル・ガイの野望",
  "maker": "コナミ",
  "releaseDate": "2001/11/1",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1228",
  "ini": "ろ",
  "name": "Loppi パズルマガジン ひらめくパズル第2号",
  "maker": "サクセス",
  "releaseDate": "2001/11/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1229",
  "ini": "ね",
  "name": "ネットワーク冒険記 バグサイト アルファ",
  "maker": "スマイルソフト",
  "releaseDate": "2001/11/2",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1230",
  "ini": "ね",
  "name": "ネットワーク冒険記 バグサイト ベータ",
  "maker": "スマイルソフト",
  "releaseDate": "2001/11/2",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1231",
  "ini": "な",
  "name": "なかよしクッキングシリーズ④ たのしいデザート",
  "maker": "エム・ティー・オー",
  "releaseDate": "2001/11/16",
  "price": "4200",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1232",
  "ini": "ち",
  "name": "チキチキマシン猛レース",
  "maker": "シスコンエンタテイメント",
  "releaseDate": "2001/11/22",
  "price": "3600",
  "genre": "レース",
  "icNo": "6"
 },
 {
  "id": "1233",
  "ini": "は",
  "name": "ハリー・ポッターと賢者の石",
  "maker": "エレクトロニック・アーツ・スクウェア",
  "releaseDate": "2001/12/1",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1234",
  "ini": "ろ",
  "name": "Loppi パズルマガジン かんがえるパズル第3号",
  "maker": "サクセス",
  "releaseDate": "2001/12/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1235",
  "ini": "さ",
  "name": "サクラ大戦GB2 サンダーボルト作戦",
  "maker": "セガ",
  "releaseDate": "2001/12/6",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1236",
  "ini": "み",
  "name": "ミニー&フレンズ 夢の国をさがして",
  "maker": "ハドソン",
  "releaseDate": "2001/12/13",
  "price": "4800",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1237",
  "ini": "め",
  "name": "メダロット5 すすたけ村の転校生 カブト",
  "maker": "イマジニア",
  "releaseDate": "2001/12/14",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1238",
  "ini": "め",
  "name": "メダロット5 すすたけ村の転校生 クワガタ",
  "maker": "イマジニア",
  "releaseDate": "2001/12/14",
  "price": "4800",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1239",
  "ini": "る",
  "name": "ルーニー・テューンズ コレクター マーシャンクエスト！",
  "maker": "シスコンエンタテイメント",
  "releaseDate": "2001/12/14",
  "price": "3700",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1240",
  "ini": "わ",
  "name": "わたしのキッチン",
  "maker": "キラット",
  "releaseDate": "2001/12/21",
  "price": "3980",
  "genre": "料理・・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1241",
  "ini": "し",
  "name": "シャーマンキング 超・占事略決 ふんばり編",
  "maker": "キングレコード",
  "releaseDate": "2001/12/21",
  "price": "3980",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1242",
  "ini": "し",
  "name": "シャーマンキング 超・占事略決 メラメラ編",
  "maker": "キングレコード",
  "releaseDate": "2001/12/21",
  "price": "3980",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1243",
  "ini": "き",
  "name": "きせかえハムスター",
  "maker": "ビクターインタラクティブソフトウェア",
  "releaseDate": "2001/12/21",
  "price": "4200",
  "genre": "テーブル",
  "icNo": "6"
 },
 {
  "id": "1244",
  "ini": "し",
  "name": "シルバニアファミリー3 星ふる夜のすなどけい",
  "maker": "エポック社",
  "releaseDate": "2001/12/21",
  "price": "4300",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1245",
  "ini": "し",
  "name": "将棋3",
  "maker": "ポニーキャニオン",
  "releaseDate": "2001/12/24",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1246",
  "ini": "ろ",
  "name": "Loppi パズルマガジン ひらめくパズル第3号",
  "maker": "サクセス",
  "releaseDate": "2002/1/1",
  "price": "600",
  "genre": "パズル",
  "icNo": "18"
 },
 {
  "id": "1247",
  "ini": "ち",
  "name": "超GALS！寿蘭2 ミラクル→ゲッティング",
  "maker": "コナミ",
  "releaseDate": "2002/2/7",
  "price": "4800",
  "genre": "パズル",
  "icNo": "6"
 },
 {
  "id": "1248",
  "ini": "と",
  "name": "Dr.リンにきいてみて！恋のリン風水",
  "maker": "ハドソン",
  "releaseDate": "2002/2/21",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1249",
  "ini": "は",
  "name": "ハローキティのハッピーハウス",
  "maker": "エム・ティー・オー",
  "releaseDate": "2002/3/2",
  "price": "4500",
  "genre": "ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1250",
  "ini": "も",
  "name": "モン☆スタートラベラー",
  "maker": "タイトー",
  "releaseDate": "2002/3/8",
  "price": "4300",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1251",
  "ini": "あ",
  "name": "あざらし戦隊イナズマ どきどき大作戦?",
  "maker": "オメガ・プロジェクト",
  "releaseDate": "2002/3/29",
  "price": "3980",
  "genre": "ミニゲーム",
  "icNo": "6"
 },
 {
  "id": "1252",
  "ini": "は",
  "name": "BIOHAZARD GAIDEN",
  "maker": "カプコン",
  "releaseDate": "2002/3/29",
  "price": "4200",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "1253",
  "ini": "な",
  "name": "なかよしクッキングシリーズ⑤ こむぎちゃんのケーキをつくろう！",
  "maker": "エム・ティー・オー",
  "releaseDate": "2002/4/5",
  "price": "4200",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1254",
  "ini": "ほ",
  "name": "冒険！ドンドコ島",
  "maker": "グローバル・A・エンタテインメント",
  "releaseDate": "2002/4/18",
  "price": "4300",
  "genre": "育成・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1255",
  "ini": "わ",
  "name": "わたしのレストラン",
  "maker": "キラット",
  "releaseDate": "2002/4/26",
  "price": "3980",
  "genre": "料理・シミュレーション",
  "icNo": "6"
 },
 {
  "id": "1256",
  "ini": "ふ",
  "name": "From TV animation ONE PIECE 幻のグランドライン冒険記！",
  "maker": "バンプレスト",
  "releaseDate": "2002/6/28",
  "price": "4500",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1257",
  "ini": "こ",
  "name": "昆虫ファイターズ",
  "maker": "デジタルキッズ",
  "releaseDate": "2002/7/26",
  "price": "4200",
  "genre": "RPG",
  "icNo": "6"
 },
 {
  "id": "1258",
  "ini": "と",
  "name": "ドラゴンボールZ 伝説の超戦士たち",
  "maker": "バンプレスト",
  "releaseDate": "2002/8/9",
  "price": "4500",
  "genre": "カードゲーム",
  "icNo": "6"
 },
 {
  "id": "1259",
  "ini": "は",
  "name": "ハムスター物語GB  マジハームの魔法少女",
  "maker": "カルチャーブレーン",
  "releaseDate": "2002/8/9",
  "price": "3980",
  "genre": "アドベンチャー",
  "icNo": "6"
 },
 {
  "id": "1260",
  "ini": "と",
  "name": "ドラえもんのクイズボーイ2",
  "maker": "小学館・エポック社",
  "releaseDate": "2002/10/4",
  "price": "3980",
  "genre": "学習・クイズ",
  "icNo": "6"
 },
 {
  "id": "1261",
  "ini": "か",
  "name": "漢字BOY3",
  "maker": "J・ウイング",
  "releaseDate": "2003/6/5",
  "price": "4980",
  "genre": "学習",
  "icNo": "6"
 },
 {
  "id": "1262",
  "ini": "と",
  "name": "ドラえもんのスタディボーイ かんじよみかきマスター",
  "maker": "小学館・エポック社",
  "releaseDate": "2003/7/18",
  "price": "3980",
  "genre": "学習",
  "icNo": "6"
 },
 {
  "id": "1263",
  "ini": "も",
  "name": "モバイルトレーナー（モバイルアダプタGB専用）",
  "maker": "任天堂",
  "releaseDate": "2001/1/27",
  "price": "5800",
  "genre": "その他",
  "icNo": "6"
 }
];